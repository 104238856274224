.bg-paragraph-component {
    position: relative;
    margin: 25px 0;
    color: $black;

    @include media('>=tablet') {
        margin: 75px 32px;
    }

    @include media('>=desktop') {
        margin: 100px 32px;
    }
}

.bg-paragraph-image-wrapper {
    overflow: hidden;

    &.image-left,
    &.image-right {
        @include media('>=tablet') {
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
        }
    }
}

.bg-paragraph-link {
    @include media('>=tablet') {
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
}

.bg-paragraph-img {
    .image-right & {
        @include media('>=tablet') {
            width: auto;
            height: 100%;
            float: right;
        }

        @include media('>=desktop') {
            width: 100%;
            height: auto;
            float: none;
        }
    }

    .image-left & {
        @include media('>=tablet') {
            width: auto;
            height: 100%;
            float: left;
        }

        @include media('>=desktop') {
            width: 100%;
            height: auto;
            float: none;
        }
    }
}

.bg-paragraph-text-wrapper {
    &.position-left,
    &.position-right {
        @include media('>=desktop') {
            padding: 70px 0;
        }
    }
}

.bg-paragraph-text {
    background-color: $white;
    font-size: 14px;
    line-height: 20px;
    padding: 30px 20px 0;


    @include media('>=tablet') {
        position: relative;
        z-index: 2;
        padding: 30px 0 0;
    }

    &.position-left {
        @include media('>=tablet') {
            padding: 20px 50px 20px 30px;
            margin: 0 50% 0 0;
        }

        @include media('>=desktop') {
            padding: 50px 50px 50px 0;
            margin: 0 calc(50% - 20px) 0 0;
        }
    }

    &.position-right {
        @include media('>=tablet') {
            padding: 20px 30px 20px 50px;
            margin: 0 0 0 50%;
        }

        @include media('>=desktop') {
            padding: 50px 0 50px 50px;
            margin: 0 0 0 calc(50% - 20px);
        }
    }

    &.position-bottom {
        @include media('>=tablet') {
            padding: 50px;
        }

        @include media('>=desktop') {
            position: relative;
            max-width: 640px;
            z-index: 1;
            margin: -40px auto 0;
        }

        @include media('>=desktopXL') {
            max-width: 920px;
        }
    }

    h2 {
        margin: 0 0 20px;
    }

    p {
        margin: 0;
    }
}

// included title-richtext component override
.bg-paragraph-text-region {
    .title-richtext-component {
        margin: 0;
        max-width: none;
    }
}
