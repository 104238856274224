.family-gender-refinements-title {
    font: vw(28px) $MontserratBold;
    padding-top: vw(50px);
    text-transform: uppercase;
    color: $blue;
    display: inline-block;

    @include media('>=tablet') {
        font-size: 28px;
    }

    @include media('>=desktop') {
        font-size: 11px;
        line-height: 70px;
        margin-right: 40px;
        padding-top: 0;
    }
}

.search-refinement-gender-dropdown {
    .family-gender-refinements & {
        padding: 0 vw(20px);

        @include media('>=desktop') {
            padding: 0;
        }
    }

    .family-gender-title {
        width: 100%;

        @include media('>=desktop') {
            width: auto;
        }
    }
}

.family-gender-refinements {
    flex-direction: column;
    padding: 0;

    @include media('>=desktop') {
        flex-direction: row;
        justify-content: center;
        margin-top: 20px;
    }
}

.family-gender-title-material {
    @include media('>=desktop') {
        display: inline;

        &::after {
            content: ' -';
        }
    }
}
