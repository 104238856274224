.text-image-component {
    margin: 45px 0;
    color: $black;

    @include media('>=tablet') {
        display: flex;
        flex-wrap: nowrap;
        margin: 75px 32px;
    }

    @include media('>=desktop') {
        margin: 100px auto;
        max-width: 780px;
    }
}

.text-image-col-link {
    display: block;
}

.text-image-col-text {
    @extend %body1;
    max-width: 230px;
    padding: 30px 20px 0;

    @include media('>=tablet') {
        max-width: 300px;
    }

    &.position-left {
        @include media('>=tablet') {
            padding: 50px 70px 50px 0;
        }
    }

    &.position-right {
        @include media('>=tablet') {
            padding: 50px 0 50px 70px;
        }
    }

    h2 {
        margin: 0 0 20px;
    }

    p {
        margin: 0;
    }
}

.text-image-col-img {
    @include media('>=tablet') {
        flex-grow: 1;
    }
}
