// =================================
//    Social Login:
// =================================

// Social Login: Common styles
.social-login {
    .social-login-btn {
        text-transform: none;
        text-align: left;
        border: 2px solid $blue;
        position: relative;
        padding-left: 50px;
        height: 42px;
        line-height: 42px;
    }

    .social-login-btn::before {
        content: '';
        position: absolute;
        top: 7px;
        left: 15px;
    }

    .social-login-google-btn::before {
        @include mobile-first-icon('google');
    }

    .social-login-facebook-btn::before {
        @include mobile-first-icon('facebook');
    }

    .social-login-linkedin-btn::before {
        @include mobile-first-icon('linkedin');
    }
}

.social-login-title {
    font-size: vw(28px);
    text-align: center;
    margin: 0 0 20px;

    @include media('>=desktop') {
        font-size: 14px;
    }
}

.social-login-forms {
    margin: 0 auto;
    max-width: 80%;

    @include media('>=desktop') {
        max-width: none;
    }
}

.social-login-form-li {
    margin: 0 0 15px;
}

// Social Login: Login Form on Login Page
.myaccount-login-col {
    .social-login {
        margin: 20px 0 0;

        @include media('>=desktop') {
            margin: 20px 0;
        }
    }
}

// Social Login: Login Form on Checkout Login Page
.checkoutlogin {
    .social-login {
        margin: 20px 0 0;
    }
}

// Social Login: Registration Form
.social-login-registration {
    order: -1;

    @include media('>=desktop') {
        order: 0;
    }

    .social-login-title {
        display: none;

        @include media('>=desktop') {
            display: block;
        }
    }

    .social-login-form-li:last-child {
        margin: 0;
    }
}
