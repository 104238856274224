// Do not edit this file, everything will be removed after running gulp tasks

$sprite: (
      'close_x': (
        offset-x: 0px,
        offset-y: -62px,
        width: 20px,
        height: 20px,
        total-width: 174px,
        total-height: 1955px,
        image: '../../images_active/sprites.png'
      ),
      'complementary-gift-box': (
        offset-x: 0px,
        offset-y: -1656px,
        width: 95px,
        height: 105px,
        total-width: 174px,
        total-height: 1955px,
        image: '../../images_active/sprites.png'
      ),
      'express_delivery': (
        offset-x: 0px,
        offset-y: -465px,
        width: 67px,
        height: 34px,
        total-width: 174px,
        total-height: 1955px,
        image: '../../images_active/sprites.png'
      ),
      'facebook': (
        offset-x: 0px,
        offset-y: -143px,
        width: 24px,
        height: 24px,
        total-width: 174px,
        total-height: 1955px,
        image: '../../images_active/sprites.png'
      ),
      'family_all': (
        offset-x: 0px,
        offset-y: -777px,
        width: 50px,
        height: 50px,
        total-width: 174px,
        total-height: 1955px,
        image: '../../images_active/sprites.png'
      ),
      'family_all_hover': (
        offset-x: 0px,
        offset-y: -637px,
        width: 50px,
        height: 50px,
        total-width: 174px,
        total-height: 1955px,
        image: '../../images_active/sprites.png'
      ),
      'family_fatherson': (
        offset-x: 0px,
        offset-y: -707px,
        width: 50px,
        height: 50px,
        total-width: 174px,
        total-height: 1955px,
        image: '../../images_active/sprites.png'
      ),
      'family_fatherson_hover': (
        offset-x: 0px,
        offset-y: -847px,
        width: 50px,
        height: 50px,
        total-width: 174px,
        total-height: 1955px,
        image: '../../images_active/sprites.png'
      ),
      'family_motherdaughter': (
        offset-x: 0px,
        offset-y: -917px,
        width: 50px,
        height: 50px,
        total-width: 174px,
        total-height: 1955px,
        image: '../../images_active/sprites.png'
      ),
      'family_motherdaughter_hover': (
        offset-x: 0px,
        offset-y: -987px,
        width: 50px,
        height: 50px,
        total-width: 174px,
        total-height: 1955px,
        image: '../../images_active/sprites.png'
      ),
      'get_direction': (
        offset-x: 0px,
        offset-y: -319px,
        width: 28px,
        height: 28px,
        total-width: 174px,
        total-height: 1955px,
        image: '../../images_active/sprites.png'
      ),
      'get_direction_hover': (
        offset-x: 0px,
        offset-y: -367px,
        width: 28px,
        height: 28px,
        total-width: 174px,
        total-height: 1955px,
        image: '../../images_active/sprites.png'
      ),
      'gift': (
        offset-x: 0px,
        offset-y: -519px,
        width: 37px,
        height: 37px,
        total-width: 174px,
        total-height: 1955px,
        image: '../../images_active/sprites.png'
      ),
      'gift_box': (
        offset-x: 0px,
        offset-y: -1133px,
        width: 41px,
        height: 62px,
        total-width: 174px,
        total-height: 1955px,
        image: '../../images_active/sprites.png'
      ),
      'google': (
        offset-x: 0px,
        offset-y: -275px,
        width: 24px,
        height: 24px,
        total-width: 174px,
        total-height: 1955px,
        image: '../../images_active/sprites.png'
      ),
      'linkedin': (
        offset-x: 0px,
        offset-y: -231px,
        width: 24px,
        height: 24px,
        total-width: 174px,
        total-height: 1955px,
        image: '../../images_active/sprites.png'
      ),
      'pay_pal': (
        offset-x: 0px,
        offset-y: -102px,
        width: 87px,
        height: 21px,
        total-width: 174px,
        total-height: 1955px,
        image: '../../images_active/sprites.png'
      ),
      'pdp_zoom': (
        offset-x: 0px,
        offset-y: -415px,
        width: 30px,
        height: 30px,
        total-width: 174px,
        total-height: 1955px,
        image: '../../images_active/sprites.png'
      ),
      'return-policy': (
        offset-x: 0px,
        offset-y: -1467px,
        width: 64px,
        height: 64px,
        total-width: 174px,
        total-height: 1955px,
        image: '../../images_active/sprites.png'
      ),
      'secured_payment': (
        offset-x: 0px,
        offset-y: -1057px,
        width: 61px,
        height: 56px,
        total-width: 174px,
        total-height: 1955px,
        image: '../../images_active/sprites.png'
      ),
      'send-us-an-email': (
        offset-x: 0px,
        offset-y: -1215px,
        width: 64px,
        height: 64px,
        total-width: 174px,
        total-height: 1955px,
        image: '../../images_active/sprites.png'
      ),
      'shipping-details': (
        offset-x: 0px,
        offset-y: -1299px,
        width: 64px,
        height: 64px,
        total-width: 174px,
        total-height: 1955px,
        image: '../../images_active/sprites.png'
      ),
      'tapzoom': (
        offset-x: 0px,
        offset-y: -1551px,
        width: 85px,
        height: 85px,
        total-width: 174px,
        total-height: 1955px,
        image: '../../images_active/sprites.png'
      ),
      'tapzoom_big': (
        offset-x: 0px,
        offset-y: -1781px,
        width: 174px,
        height: 174px,
        total-width: 174px,
        total-height: 1955px,
        image: '../../images_active/sprites.png'
      ),
      'track-my-order': (
        offset-x: 0px,
        offset-y: -1383px,
        width: 64px,
        height: 64px,
        total-width: 174px,
        total-height: 1955px,
        image: '../../images_active/sprites.png'
      ),
      'turtle_hover': (
        offset-x: 0px,
        offset-y: -576px,
        width: 41px,
        height: 41px,
        total-width: 174px,
        total-height: 1955px,
        image: '../../images_active/sprites.png'
      ),
      'turtle_loader': (
        offset-x: 0px,
        offset-y: -187px,
        width: 24px,
        height: 24px,
        total-width: 174px,
        total-height: 1955px,
        image: '../../images_active/sprites.png'
      ),
      'wishlist_like_icon': (
        offset-x: 0px,
        offset-y: -31px,
        width: 13px,
        height: 11px,
        total-width: 174px,
        total-height: 1955px,
        image: '../../images_active/sprites.png'
      ),
      'wishlist_like_icon_hover': (
        offset-x: 0px,
        offset-y: 0px,
        width: 13px,
        height: 11px,
        total-width: 174px,
        total-height: 1955px,
        image: '../../images_active/sprites.png'
      ),
      'close_x_2x': (
        offset-x: 0px,
        offset-y: -124px,
        width: 40px,
        height: 40px,
        total-width: 348px,
        total-height: 3910px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'complementary-gift-box_2x': (
        offset-x: 0px,
        offset-y: -3312px,
        width: 190px,
        height: 210px,
        total-width: 348px,
        total-height: 3910px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'express_delivery_2x': (
        offset-x: 0px,
        offset-y: -930px,
        width: 134px,
        height: 68px,
        total-width: 348px,
        total-height: 3910px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'facebook_2x': (
        offset-x: 0px,
        offset-y: -286px,
        width: 48px,
        height: 48px,
        total-width: 348px,
        total-height: 3910px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'family_all_2x': (
        offset-x: 0px,
        offset-y: -1554px,
        width: 100px,
        height: 100px,
        total-width: 348px,
        total-height: 3910px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'family_all_hover_2x': (
        offset-x: 0px,
        offset-y: -1274px,
        width: 100px,
        height: 100px,
        total-width: 348px,
        total-height: 3910px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'family_fatherson_2x': (
        offset-x: 0px,
        offset-y: -1414px,
        width: 100px,
        height: 100px,
        total-width: 348px,
        total-height: 3910px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'family_fatherson_hover_2x': (
        offset-x: 0px,
        offset-y: -1694px,
        width: 100px,
        height: 100px,
        total-width: 348px,
        total-height: 3910px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'family_motherdaughter_2x': (
        offset-x: 0px,
        offset-y: -1834px,
        width: 100px,
        height: 100px,
        total-width: 348px,
        total-height: 3910px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'family_motherdaughter_hover_2x': (
        offset-x: 0px,
        offset-y: -1974px,
        width: 100px,
        height: 100px,
        total-width: 348px,
        total-height: 3910px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'get_direction_2x': (
        offset-x: 0px,
        offset-y: -638px,
        width: 56px,
        height: 56px,
        total-width: 348px,
        total-height: 3910px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'get_direction_hover_2x': (
        offset-x: 0px,
        offset-y: -734px,
        width: 56px,
        height: 56px,
        total-width: 348px,
        total-height: 3910px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'gift_2x': (
        offset-x: 0px,
        offset-y: -1038px,
        width: 74px,
        height: 74px,
        total-width: 348px,
        total-height: 3910px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'gift_box_2x': (
        offset-x: 0px,
        offset-y: -2266px,
        width: 82px,
        height: 124px,
        total-width: 348px,
        total-height: 3910px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'google_2x': (
        offset-x: 0px,
        offset-y: -550px,
        width: 48px,
        height: 48px,
        total-width: 348px,
        total-height: 3910px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'linkedin_2x': (
        offset-x: 0px,
        offset-y: -462px,
        width: 48px,
        height: 48px,
        total-width: 348px,
        total-height: 3910px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'pay_pal_2x': (
        offset-x: 0px,
        offset-y: -204px,
        width: 174px,
        height: 42px,
        total-width: 348px,
        total-height: 3910px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'pdp_zoom_2x': (
        offset-x: 0px,
        offset-y: -830px,
        width: 60px,
        height: 60px,
        total-width: 348px,
        total-height: 3910px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'return-policy_2x': (
        offset-x: 0px,
        offset-y: -2934px,
        width: 128px,
        height: 128px,
        total-width: 348px,
        total-height: 3910px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'secured_payment_2x': (
        offset-x: 0px,
        offset-y: -2114px,
        width: 122px,
        height: 112px,
        total-width: 348px,
        total-height: 3910px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'send-us-an-email_2x': (
        offset-x: 0px,
        offset-y: -2430px,
        width: 128px,
        height: 128px,
        total-width: 348px,
        total-height: 3910px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'shipping-details_2x': (
        offset-x: 0px,
        offset-y: -2598px,
        width: 128px,
        height: 128px,
        total-width: 348px,
        total-height: 3910px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'tapzoom_2x': (
        offset-x: 0px,
        offset-y: -3102px,
        width: 170px,
        height: 170px,
        total-width: 348px,
        total-height: 3910px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'tapzoom_big_2x': (
        offset-x: 0px,
        offset-y: -3562px,
        width: 348px,
        height: 348px,
        total-width: 348px,
        total-height: 3910px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'track-my-order_2x': (
        offset-x: 0px,
        offset-y: -2766px,
        width: 128px,
        height: 128px,
        total-width: 348px,
        total-height: 3910px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'turtle_hover_2x': (
        offset-x: 0px,
        offset-y: -1152px,
        width: 82px,
        height: 82px,
        total-width: 348px,
        total-height: 3910px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'turtle_loader_2x': (
        offset-x: 0px,
        offset-y: -374px,
        width: 48px,
        height: 48px,
        total-width: 348px,
        total-height: 3910px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'wishlist_like_icon_2x': (
        offset-x: 0px,
        offset-y: -62px,
        width: 26px,
        height: 22px,
        total-width: 348px,
        total-height: 3910px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'wishlist_like_icon_hover_2x': (
        offset-x: 0px,
        offset-y: 0px,
        width: 26px,
        height: 22px,
        total-width: 348px,
        total-height: 3910px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
);




@mixin sprite-size($image) {
  background-size: map-get(map-get($sprite, $image), 'total-width') map-get(map-get($sprite, $image), 'total-height');
}

@mixin sprite-width($image) {
  width: map-get(map-get($sprite, $image), 'width');
}

@mixin sprite-height($image) {
  height: map-get(map-get($sprite, $image), 'height');
}

@mixin sprite-position($image) {
  background-position: map-get(map-get($sprite, $image), 'offset-x') map-get(map-get($sprite, $image), 'offset-y');
}

@mixin sprite-image($image) {
  background-image: url(map-get(map-get($sprite, $image), 'image'));
}

// useful functions
@function get-sprite-width($image) {
  @return map-get(map-get($sprite, $image), 'width');
}

@function get-sprite-height($image) {
  @return map-get(map-get($sprite, $image), 'height');
}

// Generate certain image sprite
// Arguments
// - $image : sprite image name
// - $showWidth : adds width option to sprite.
// - $showHeight : adds height option to sprite.
@mixin sprite($image, $showWidth: true, $showHeight : true) {
  @include sprite-image($image);
  @include sprite-position($image);
  background-repeat: no-repeat;

  @if $showWidth {
    @include sprite-width($image);
  }

  @if $showHeight {
    @include sprite-height($image);
  }

}

// Useful to generate all sprites - Optimized for vertical aligned sprites
// Arguments
// - $showWidth : adds width option to sprite.
// - $showHeight : adds height option to sprite.
@mixin sprites( $showWidth : false, $showHeight : true ) {
  @each $icon-name, $icon-properties in $sprite {
    .#{$icon-name} { @include sprite( $icon-name, $showWidth, $showHeight ); }
  }
}



// Generate all sprites by default
// Add `$generate-all-sprites : false;` into your settings to skip this

$generate-all-sprites : false !default;
@if $generate-all-sprites {
  @include sprites;
}
