// CONTEXT CHOOSER FORM PAGE

.contextchooser-wrapper {
    margin: 70px 0;

    .contextChooserDialog & {
        margin: 18px 0 0;
    }
}

.contextchooser-title {
    width: 100%;
    text-align: center;
    font-size: vw(48px);

    .contextChooserDialog & {
        text-align: left;
    }

    @include media('>=tablet') {
        font-size: vw(38px);
    }

    @include media('>=desktop') {
        font-size: 24px;
    }
}

.contextchooser-description {
    margin: 20px 0 30px;
}

.contextchooser-subtitle {
    margin: 0 0 10px;
    font-size: vw(32px);

    @include media('>=tablet') {
        font-size: vw(24px);
    }

    @include media('>=desktop') {
        font-size: 16px;
    }
}

.contextchooser-form {
    margin: 20px 0;
    justify-content: center;
    font-size: vw(28px);
    width: 100%;

    @include media('>=tablet') {
        font-size: vw(20px);
    }

    @include media('>=desktop') {
        font-size: 14px;
    }
}

.contextchooser-form-label {
    margin: 0 0 20px;
}

.contextchooser-submitButton-wrapper {
    margin: 10px 0 40px;
}

.contextchooser-shippingdetails {
    margin: 20px 0;
}

.contextchooser-successMessage,
.contextchooser-willRedirectMessage {
    margin: 20px 0 0;
    text-align: center;
}
