// =================================
//    Gift certificate
// =================================
$giftcert-bg-color: $black;
$giftcert-balance-grey-color: #1f1f1f;

// Gift certificate landing page: common
.giftcertpurchase-btn.gl_btn {
    @extend %button1;
    color: $white;
    border-color: $white;
    letter-spacing: normal;

    &.gl_btn-white {
        color: $black;
        border-color: $black;
    }
}

// trick for proper anchor offset on scroll to the section
.giftcert-anchor {
    display: block;
    padding-top: 120px;
    margin-top: -120px;
}

// Gift certificate warning
.giftcert-warning {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    @extend %body1;
    color: $light-red2;
    padding: 30px 50px;
}

.giftcert-warning-icon {
    padding: 0 20px 0 0;

    .moneysvg {
        width: 52px;
        height: 52px;
    }

    .st0 {
        fill: $light-red2;
    }
}

.giftcert-warning-text1 {
    font-weight: bold;
}

.giftcert-warning-link {
    color: $light-red2;

    &:hover {
        color: inherit;
    }
}

// Gift certificate details
.giftcertpurchase-section {
    position: relative;
    background-color: $giftcert-bg-color;
    color: $white;
}

.giftcertpurchase-content {
    padding: 30px 30px 50px;

    @include media('>=tablet') {
        padding: 30px 50px 50px;
    }

    @include media('>=desktop') {
        padding: 0;
    }
}

.giftcertpurchase-left {
    @include media('>=desktop') {
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        padding: 65px 32px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
    }
}

.giftcertpurchase-right {
    @include media('>=desktop') {
        position: absolute;
        width: 40%;
        right: 0;
        bottom: 0;
        padding: 0 32px 65px 0;
    }

    @include media('>=desktopLarge') {
        width: 35%;
    }

    .is-giftcert-supported & {
        @include media('>=desktop') {
            padding: 0 32px 35px 0;
        }
    }
}

.giftcertpurchase-title {
    color: $white;
    margin: 0 0 20px;

    @include media('>=desktop') {
        max-width: 450px;
        margin: 100px 0 30px;
    }

    @include media('>=desktopLarge') {
        margin: 120px 0 30px;
    }
}

.giftcertpurchase-text {
    @extend %body1;
    color: $white;
    opacity: 0.8;
    margin: 0 0 50px;

    @include media('>=desktop') {
        max-width: 500px;
        margin: 0;
    }
}

.giftcertpurchase-buygiftcard-link {
    @extend %fakeButton;
    display: block;
    margin: 0 0 20px;
    max-width: 400px;
    color: $white;
    border-color: $white;

    @include media('>=desktop') {
        max-width: 300px;
        margin: 0;
    }
}

.giftcertpurchase-changecurrency-link {
    @extend %fakeButton;
    display: block;
    margin: 0 0 20px;
    max-width: 400px;
    padding: 10px;
    color: $white;
    border-color: $white;

    @include media('>=desktop') {
        margin: 0;
    }
}

.giftcertpurchase-faq-link {
    @extend %body2;
    display: block;
    color: $white;
}

.giftcertpurchase-checkbalance-link {
    @extend %discoveryCTA;
    color: $white;
    font-weight: normal;
    margin: 0 0 12px;
}

// Gift certificate balancedetails
.giftcert-balancedetails-section {
    text-align: center;
    color: $white;
    background-color: $giftcert-bg-color;
    padding: 40px 20px;

    .giftcert-not-supported & {
        background-color: $dark-grey6;
    }

    @include media('>=tablet') {
        padding: 60px 40px;
    }

    @include media('>=desktop') {
        padding: 80px 40px;
    }
}

.giftcert-balancedetails-title {
    color: $white;
    margin: 0 0 24px;
}

.giftcert-balancedetails-text {
    @extend %body1;
    color: $white;
    max-width: 640px;
    margin: 0 auto 60px;
}

.giftcert-balancedetails-form {
    max-width: 335px;
    margin: 0 auto;

    .giftcert-balancedetails-input {
        border-color: $giftcert-balance-grey-color;
        background-color: $giftcert-balance-grey-color;

        &.input-text {
            color: $white;
        }
    }

    .gl_floating-label {
        background-color: $giftcert-balance-grey-color;
        color: $white;
    }

    .gl_has-success {
        .giftcert-balancedetails-input.input-text {
            color: $white;
        }
    }
}

// special display with backend error
.giftcert-balancedetails-form.has-backend-error {
    .giftcert-balancedetails-field {
        padding: 0;
    }

    .giftcert-balancedetails-input {
        border-color: $light-red2;
        color: $light-red2;
    }

    .giftcert-balancedetails-remove {
        color: $light-red2;
    }
}

.giftcert-balancedetails-fields {
    position: relative;
}

.giftcert-balancedetails-field.gl_has-success {
    .gl_input-success::before {
        content: none;
    }

    .gl_input-success::after {
        content: none;
    }
}

.giftcert-balancedetails-remove {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: 44px;
    padding: 0 12px;
    cursor: pointer;
    font-size: 12px;
    line-height: 16px;
    color: $white;
    text-decoration: underline;
}

.giftcert-balancedetails-error {
    display: none;
    background-color: $light-red2;
    color: $white;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    padding: 13px 5px;
    margin: 0 0 20px;
}

.giftcert-balancedetails-success {
    display: none;
    padding: 0 0 20px;

    .balance-value {
        font-weight: bold;
    }
}

.giftcert-balancedetails-changecurrency-link {
    max-width: 400px;
    margin: 0 auto;
}

// Gift certificate form
.giftcert-purchase {
    text-align: center;
    padding: 20px;
    color: $black;

    @include media('>=tablet') {
        padding: 0 40px;
    }

    @include media('>=desktop') {
        padding: 50px 57px 0;
    }
}

.giftcert-purchase-form {
    @include media('>=tablet') {
        background-color: $white;
        max-width: 800px;
        margin: 0 auto;
        padding: 40px;
    }

    @include media('>=desktop') {
        padding: 50px 80px;
    }

    .giftcert-calendar {
        .giftcert-alt-input,
        .flatpickr-month,
        .flatpickr-innerContainer {
            @include media('>=tablet') {
                max-width: 350px;
                margin: 0 auto;
            }
        }

        // hide altInput
        .giftcert-alt-input {
            display: none;
        }

        .flatpickr-months .flatpickr-next-month.flatpickr-next-month {
            @include media('>=tablet') {
                right: calc((100% - 350px) / 2);
            }
        }

        .flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
            @include media('>=tablet') {
                left: calc((100% - 350px) / 2);
            }
        }
    }
}

.giftcert-purchase-title {
    margin: 0 0 50px;

    @include media('>=tablet') {
        margin: 0 0 80px;
    }
}

.giftcert-purchase-legend {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;
    font-family: $Univers;
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 20px;
}

.giftcert-purchase-legend-num {
    padding: 0 5px 0 0;
}

.giftcert-purchase-legend-text::before {
    content: "\2022";
    display: inline-block;
    margin-right: 8px;
    font-size: 16px;
    line-height: 20px;
}

.giftcert-purchase-details-legend {
    margin: 0 0 15px;
}

.giftcert-purchase-desc {
    @extend %ui3;
    max-width: 300px;
    margin: 0 auto 15px;

    @include media('>=tablet') {
        max-width: 500px;
    }
}

.giftcert-purchase-row {
    @include media('>=tablet') {
        display: flex;
        flex-flow: row wrap;
        margin: 0 -8px;
    }

    .gl_form-group {
        @include media('>=tablet') {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 8px 30px;
        }
    }

    .gl_error-message {
        @include media('>=tablet') {
            padding: 0 8px;
        }
    }
}

.giftcert-purchase-amountfields {
    padding: 0 0 20px;
    border-bottom: 1px solid $light-grey;
    margin: 0 0 50px;

    .custom_form-group {
        padding: 0 0 15px;
    }

    .giftcert-purchase-freeamount {
        padding: 0 0 5px;
    }

    // deviation on fixed amount
    .gl_has-success .gl_input-success-container {
        display: none;
    }

    .gl_has-success .filled .gl_input-success-container {
        display: flex;
    }
}

.giftcert-purchase-amount-error {
    flex: 0 0 100%;
    max-width: 100%;
}

.giftcert-purchase-day-checkbox {
    text-align: left;
}

.giftcert-selected-date {
    @extend %ui3;
    padding: 0 0 15px;
}

.giftcert-selected-date-value {
    @extend %ui3-bold;
}

.giftcert-purchase-error {
    display: none;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    padding: 0 0 20px;
}

// Gift certificate warning
.giftcert-faq {
    margin: 0 15px;

    @include media('>=desktop') {
        max-width: 642px;
        margin: 0 auto;
    }
}
