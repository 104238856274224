// =================================
// SONIA RYKIEL GRAPHICAL CHART
// =================================

// updated variables
// =================================
//    Breakpoints
// =================================
$phone: 375px;

// =================================
//    Colors
// =================================
$grey2: #ccc;
$dark-grey2: #5f5f5f;
$dark-grey3: #969696;
$dark-grey4: #323232;
$dark-grey5: #232323;
$dark-grey6: #383838;
$dark-grey7: #747070;
$dark-grey8: #757575;
$dark-grey9: #767676;
$light-grey2: rgba(150, 150, 150, 0.2);
$light-grey3: #888888;
$white-grey2: #f8f8f8;
$transparent-white2: rgba(255, 255, 255, 0.2);
$add-to-cart-disabled: #000;
$light-border: rgba(95, 95, 95, 0.5);
$light-brown: #f8f8f8;

// =================================
//    Fonts
// =================================
$Saira: SairaVariable, Arial, sans-serif;
$Univers: UniversLTStd, Arial, sans-serif;


// deleted variables (commented, just the classname for trace)

// =================================
// VILEBREQUIN GRAPHICAL CHART
// =================================
// =================================
//    Sprite suffix
// =================================
$sprite-suffix: '_2x';

// =================================
//    Breakpoints
// =================================
$tablet: 768px;
$desktop: 1024px;
$desktopMedium: 1200px;
$desktopLarge: 1358px;
$desktopXL: 1920px;

// =================================
//    Colors
// =================================
$blue: #0c315d;
$blue-hover: #1b65b1;
$blue-light: #e2eaf3;
$blue-medium: #06f;
$antique-blue: #56779e;
$outline-color: #000;
$white: #fff;
$black: #000;
$grey: #99abc1;
$light-grey: #e3e3e3;
$white-grey: #f6f6f6;
$blue-grey: #ced5de;
$dark-grey: #a9a9a9;
$grey-bg: #f3f3f3;
$grey-shadow: #e5e5e5;
$medium-grey: #999999;
$red: #e50404;
$light-red: #ff302f;
$light-red2: #f94c43;
$beige: #f5f5dc;
$brown: #a52a2a;
$green: #89ec66;
$orange: #ffa500;
$pink: #ff1493;
$purple: #800080;
$yellow: #fed645;
$yellow-light: #ff0;
$giftblock-grey: #ededed;
$store-dialog-border: #c4ccd5;
$promotion-grey: #707070;
$pdplook-bg: rgba(243, 243, 243, 0.3);
$scrollbar-thumb-color: rgba(112, 112, 112, 0.5);
$tooltip-shadow: rgba(0, 0, 0, 0.75);
$backtotop-circle-shadow: rgba(0, 0, 0, 0.16);
$transparent-white: rgba(255, 255, 255, 0.85);
$light-beige: #f5efe2;
$transparent-black: rgba(0, 0, 0, 0.35);
$light-pink: #fce7e7;
$ecogreen: #3bad35;
$ecofriendly-color1: #6fa941;
$ecofriendly-color2: #252a41;
$azure-radiance: #0075ff;
$royal-blue: #1e79e8;
$footer-item-link: rgba($blue, 0.2);
$box-shadow: rgba(0, 0, 0, 0.35);
$box-shadow2: rgba(0, 0, 0, 0.1);
$mobile-pdp-estim: #005ce0;

// Multi color swatch
$m-blue: #0c315d;
$m-yellow: #ff0;
$m-green: #0c0;
$m-red: #f00;

// =================================
//    Fonts
// =================================
$MontserratBold: Arial, sans-serif; // TODO REMOVE
$TimesNewRoman: 'Times New Roman', Arial, sans-serif;
$Arial: Arial, sans-serif;
$Mono: 'Roboto Mono', monospace;

