.pd-video-component {
    margin: 60px 20px;

    @include media('>=tablet') {
        margin: 110px 32px;
    }

    @include media('>=desktop') {
        margin: 110px 32px 110px auto;
        max-width: 636px;
    }

    @include media('>=desktopLarge') {
        max-width: 968px;
    }

    @include media('>=desktopXL') {
        max-width: 1389px;
    }
}
