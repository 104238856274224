.store-availability-wrapper {
    position: relative;
    z-index: 1000;
    background-color: $white;
}

.store-availability-product-select {
    position: relative;
    z-index: 1000;
    padding: 0;

    @include media('>=desktop') {
        box-shadow: 0 3px 5px 0 $grey-shadow;
        border-top: 1px solid $light-grey;
    }

    .swatch-list.product-variation-list {
        display: none;

        @include media('>=desktop') {
            display: block;
        }
    }
}

.not-found-variation-breadcrumb {
    font: italic vw(36px) $TimesNewRoman;
    line-height: vw(36px);
    color: $blue;
    width: auto;
    text-align: center;
    padding: 0 5%;
    margin-top: vw(60px);

    @include media('>=tablet') {
        line-height: 36px;
        font-size: 36px;
        padding: 0 10%;
        margin-top: 30px;
    }

    @include media('>=desktop') {
        line-height: 75px;
        font-size: 16px;
        text-align: left;
        white-space: nowrap;
        margin-top: 0;
        padding: 0;
    }

    @include media('>=desktopLarge') {
        font-size: 18px;
    }
}

.not-found-variation-input {
    width: 100%;
    text-align: left;

    &.gl_form-group {
        .gl_error-message {
            margin: 0;
        }
    }
}

.not-found-variation-form {
    justify-content: center;
    flex-wrap: wrap;

    @include media('>=desktop') {
        flex-wrap: nowrap;
    }
}

.no-stores-with-product {
    text-align: center;
    margin-top: vw(60px);
    font: vw(32px) $MontserratBold;
    padding: 0 10%;

    @include media('>=tablet') {
        margin-top: 30px;
        font-size: 32px;
    }

    @include media('>=desktop') {
        margin-top: 0;
        padding: 0;
        font-size: 18px;
    }
}

.product-in-store-lazy-image {
    width: 50%;

    @include media('>=desktop') {
        width: 60px;
        height: 60px;
        margin-left: 15px;
    }
}

.store-availability-mail-container {
    @include media('>=desktop') {
        border-bottom: 1px solid $light-grey;
    }
}

.store-availability-products-wrapper {
    padding: 0;

    .store-availability-wrapper & {
        flex-direction: column;
    }
}

.store-availability-product-data {
    flex-direction: column;
    justify-content: center;
    width: 50%;
    padding-left: 10px;

    @include media('>=desktop') {
        flex-direction: row;
        width: auto;
        padding-left: 0;
    }
}

.store-availability-price {
    text-align: center;
}

.store-availability-pattern {
    width: 100%;
    justify-content: center;
    margin-top: vw(35px);

    @include media('>=tablet') {
        margin-top: 35px;
    }
}

.store-availability-product-price {
    margin-top: 5px;
}


.store-availability-pattern-image {
    width: vw(236px);
    height: vw(14px);

    @include media('>=tablet') {
        width: 236px;
        height: 14px;
    }
}

.store-availability-product-text {
    text-align: center;
    font: vw(22px) $MontserratBold;
    color: $blue;
    text-transform: capitalize;

    @include media('>=tablet') {
        font-size: 22px;
    }
}
