.pd-asset-title {
    text-align: left;
    margin: 45px 20px;

    @include media('>=tablet') {
        margin: 45px auto;
        max-width: 585px;
    }

    @include media('>=desktop') {
        max-width: 640px;
    }

    @include media('>=desktopXL') {
        max-width: 920px;
    }
}
