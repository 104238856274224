.image-carousel-text {
    @include media('>=desktop') {
        display: flex;
    }

    &.image-carousel-text-bottom {
        margin-bottom: 65px;

        @include media('>=tablet') {
            margin-bottom: 110px;
        }

        @include media('>=desktop') {
            margin-bottom: 135px;
        }
    }
}

.image-carousel-text-col {
    @include media('>=desktop') {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

.image-carousel-text-col-link {
    display: block;
}

.image-carousel-text-carousel-wrapper {
    position: relative;
}

.image-carousel-text-carousel {
    @include media('>=desktop') {
        height: 100%;
    }

    .slick-list,
    .slick-track {
        height: inherit;
    }

    .slick-arrow {
        width: 25px;
        height: 25px;
        padding: 5px;
        position: absolute;
        top: 50%;
        z-index: 1;
    }

    .slick-prev {
        left: 5px;

        @include media('>=tablet') {
            left: 10px;
        }
    }

    .slick-next {
        right: 5px;

        @include media('>=tablet') {
            right: 10px;
        }
    }
}

.image-carousel-text-arrows-wrapper {
    @include media('>=desktop') {
        @include custom-arrows;
        position: absolute;
        bottom: 0;
        right: 20px;
        height: 50px;
    }
}
