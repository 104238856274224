.about-us-block {
    margin: 40px 0;

    @include media('>=tablet') {
        margin: 75px 32px;
    }

    @include media('>=desktop') {
        max-width: 830px;
        margin: 100px auto;
    }
}

.about-us-block-title {
    @extend %h2;
    margin: 0 20px 20px;

    @include media('>=tablet') {
        margin: 0 0 20px;
    }
}

.about-us-block-desc {
    @extend %body1;
    margin: 0 20px;

    @include media('>=tablet') {
        margin: 0;
    }
}

.about-us-block-title-link {
    text-decoration: none;
}

.about-us-block-image-wrapper {
    display: block;
    margin: 10px 0 15px;

    @include media('>=tablet') {
        margin: 10px 0 20px;
    }

    @include media('>=desktop') {
        margin: 10px 0 30px;
    }
}

.about-us-block-icon {
    width: 50px;
    margin: 15px auto;
}

.about-us-button-link.gl_btn {
    display: block;
    @extend %buttonViewMore;
    margin: 0 20px 30px;
    width: calc(100% - 40px);

    @include media('>=tablet') {
        width: 300px;
        margin: 0 0 30px;
    }
}
