// =================================
//    Header
// =================================
// =================================
// SONIA RYKIEL GRAPHICAL CHART
// =================================

// updated classes

// =================================
// White text on black background alternative header
// =================================
.alternative-header {
    .main-navigation {
        background: $black;

        .header-search-mobile {
            background: transparent;
            z-index: 1;

            .header-search-input {
                border-color: $white;
                background: transparent;
            }

            .input-text:focus ~ .gl_floating-label,
            .filled .gl_floating-label {
                background: $black;
            }

            .gl_floating-label {
                background: transparent;
                color: $white;
            }

            .search-suggestion-submit {
                background-color: transparent;
            }
        }
    }

    .navigation {
        .header-desktop-top {
            .header-desktop-top-item {
                color: $white;

                &:hover {
                    color: $medium-grey;
                }

                .header-ship-to-text {
                    color: $white;

                    &:hover {
                        color: $medium-grey;
                    }
                }

                .header-ship-to-link {
                    border-color: $transparent-white2;

                    &:hover {
                        color: $medium-grey;
                    }
                }
            }

            .header-desktop-top-right {
                .header-wishlist-link {
                    color: $white;

                    &:hover {
                        color: $medium-grey;
                    }
                }

                .user-info-desktop {
                    border-color: $transparent-white2;
                }

                .header-account-title-text {
                    color: $white;

                    &:hover {
                        color: $medium-grey;
                    }
                }

                .addtowishsvg {
                    .st0 {
                        stroke: $white;
                    }
                }

                .loginpicturesvg {
                    .st0 {
                        fill: $white;
                    }
                }
            }

            .header-account-logout {
                color: $white;

                &:hover {
                    color: $medium-grey;
                }
            }

            .svgdownarrow {
                .st0 {
                    stroke: $white;
                }
            }
        }

        .header-top {
            .header-search-wrapper {
                .header-search-input {
                    background: transparent;
                    color: $white;
                    border-color: $white;
                }

                .gl_floating-label {
                    background: transparent;
                    color: $white;
                }

                .input-text:focus ~ .gl_floating-label,
                &.filled .gl_floating-label {
                    background: $black;
                }

                .svgmagnifyingglass {
                    .st0 {
                        fill: $white;
                    }
                }
            }

            .minicart-wrapper {
                .basketsvg-sr {
                    .st0 {
                        fill: $white;
                    }
                }

                .basketfullsvg-sr {
                    .st0 {
                        fill: $white;
                    }

                    .st1 {
                        fill: $black;
                    }

                    .st2 {
                        stroke: $white;
                    }
                }
            }

            .addtowishsvg {
                .st0 {
                    stroke: $white;
                }
            }

            .svgmagnifyingglass {
                .st0 {
                    fill: $white;
                }
            }

            .menu-toggle {
                border-top-color: $white;
                border-bottom-color: $white;

                &:before {
                    background-color: $white;
                }
            }
        }

        .header-menu-item {
            .gl_wave-button {
                color: $white;

                &:hover {
                    color: $medium-grey;
                }
            }
        }
    }
}

// =================================
// Black default Header
// =================================
.header-top {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $light-grey;
    padding: 16px 20px 24px 20px;

    .header-left {
        justify-content: flex-start;
        align-items: center;
        padding: 0;
        flex-basis: 80%;
        max-width: 80%;
    }

    .header-right {
        display: flex;
        flex-grow: 1;
        padding-right: 0;
        padding-left: 0;
        float: right;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        height: 100%;

        .reduced-header & {
            display: none;
        }

        @include media('>=desktopLarge') {
            height: auto;
            padding: 21px 0 20px;

            .page-scrolled & {
                /* height: 100%; */
                z-index: 20000;
            }
        }
    }

    .reduced-header & {
        padding: 0;
        justify-content: center;
        border-width: 0 0 1px;

        @include media('<tablet') {
            padding: 16px 20px;
        }
    }

    @include media('>=tablet') {
        padding: 16px 36px 24px 32px;
        border: none;

        .reduced-header & {
            height: 65px;
        }
    }

    @include media('>=desktopLarge') {
        flex-wrap: wrap;
        padding: 0 31px 0 32px;
        /* height: 80px; */

        .reduced-header & {
            .page-scrolled & {
                display: none;
            }
        }
    }
}

.header-wishlist-link {
    @extend %ui3;
    display: flex;
    padding: 0 8px;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    text-decoration: none;

    @include media('>=desktopLarge') {
        padding: 0 24px;
    }

    .wishlist-icon-container {
        line-height: 100%;
        margin: 0 10px 0 -5px;
    }
}

.header-search-wrapper {
    .header-search-input {
        padding: 0 40px 0 16px;
        border-color: $dark-grey3;

        @include media('>=desktopLarge') {
            border-top-color: $white;
            border-left-color: $white;
            border-right-color: $white;
        }

        &:focus {
            @include media('>=desktopLarge') {
                border-top-color: $dark-grey3;
                border-left-color: $dark-grey3;
                border-right-color: $dark-grey3;
            }
        }

        @include media('>=desktopLarge') {
            width: 188px;
            height: 40px;
        }
    }

    .gl_floating-label {
        @extend %ui3;
        left: 16px;
    }

    @include media('>=desktop') {
        width: 100%;

        .magnifying-glass-container {
            width: 16px;
            height: 16px;
        }
    }
}

.mini-cart-name-link {
    @extend %h4;
    text-decoration: none;

    .name-title {
        @extend %h4;
        display: inline-block;
    }
}

.header-menu-link-wrapper {
    height: 100%;
    position: relative;
}

.header-menu-link {
    @extend %h5;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    text-align: center;
    text-decoration: none;
    height: 100%;
    padding: 0 9px;

    .svgwave-container {
        display: none;
    }

    .gl_wave-button {
        padding: 32.5px 0;
        text-transform: none;
        font-weight: normal;
    }

    &:hover {
        color: $medium-grey;
    }
}

.minicart-wrapper {
    width: 40px;
    height: 100%;
    float: right;

    @include media('>=desktopLarge') {
        border: 0;
        width: auto;
        margin-left: 37px;
    }
}

.mini-cart-container {
    margin: 0 auto;
}

.mini-cart-total {
    position: relative;
    z-index: 10000;
    margin: 0 auto;
    align-items: center;
    height: 100%;

    .minicart-empty & {
        display: none;
    }

    .mini-cart-full {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        display: flex; // needed
    }
}

.mini-cart-link {
    //display: block;
    display: flex;
    position: relative;

    .st1 {
        fill: $white;
    }

    .st2 {
        stroke: $white;
    }
}

.header-wishlist-link-mobile {
    display: flex;
    align-items: center;
}

.search-wrapper-mobile {
    width: 40px;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.menu-toggle-wrapper {
    height: 100%;
    padding-left: 12px;
    float: right;
    align-items: center;
    justify-content: center;
}

.menu-toggle {
    width: 20px;
    height: 15px;
    border-top: 2px solid $black;
    border-bottom: 2px solid $black;
    cursor: pointer;

    &::before {
        content: '';
        align-items: stretch;
        align-content: stretch;
        flex-flow: column nowrap;
        justify-content: center;
        display: flex;
        width: 100%;
        height: 2px;
        background-color: $black;
    }

    // no action to fix conflict with long click on close menu button
    &.disabled {
        pointer-events: none;
    }
}

.header-wishlist-mobile {
    width: 40px;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.navigation-desktop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 81px;

    @include media('>=desktopXL') {
        align-items: first baseline;
    }

    .reduced-header & {
        justify-content: center;
    }
}

.header-desktop-menu {
    height: 100%;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    flex-grow: 1;
    margin-left: 50px;

    .reduced-header & {
        display: none;
    }
}

.header-search {
    width: 188px;
    height: 40px;
}

.header-menu-item {
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .header-menu-section {
        &.visible {
            display: flex;
        }
    }
}

.header-desktop-top-item {
    position: relative;
}

.primary-logo-image {
    width: auto;
    max-width: 100%;
}

.header-ship-to-text {
    @extend %ui3;
    display: block;
    float: left;

    &:hover {
        color: $medium-grey;
    }
}

.header-dropdown {
    @extend %ui3;
    text-transform: none;
    cursor: pointer;
    z-index: 21000;

    &:not(.no-dropdown) {
        position: relative;
    }

    &.hover {
        color: $dark-grey3;

        .header-dropdown-list {
            display: block;
            z-index: 20000;
        }

        .header-help-dropdown {
            display: none;
        }

        .header-dropdown-status-arrow {
            &::before {
                top: 50%;
                margin-top: -3px;
                @include triangle-up($color: $black);
            }
        }
    }

    // locale dropdown
    &.header-locale.hover {
        border-color: $white;
    }
}

// EWR20-19 Header - Message(s) rows implementation (front-end)
// Top header banner specific styles
.header-banner-top {
    background-color: $black;
    color: $white;
    z-index: 20000;

    .banner-close-btn {
        .ui-button-icon::before {
            color: $white;
        }
    }
}

.header-messages-banner {
    padding: 5px 32px 2px 15px;
}

.header-banner-message {
    @extend %ui4;
    color: $white;
    margin: 0;
    text-align: center;
}

// EW-105 - Multiple header banners: common styles
.header-banner {
    position: relative;

    .banner-close-btn {
        height: 100%;
        padding: 11px;
    }

    .ui-dialog-titlebar-close .ui-button-icon::before {
        @extend %button1;
    }
}

.header-ship-to-image {
    width: auto;
    vertical-align: middle;

    @include media('>=desktopLarge') {
        margin: 0 6px;
        float: left;
        width: 18px;
        height: 18px;
        position: relative;
    }
}

.header-ship-to-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    position: relative;
    padding-right: 16px;
    border-right: 1px solid $light-grey2;

    .svgdownarrow {
        transform: rotate(-90deg);
    }
}

.header-desktop-top {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: -1px;  // trick to squash borders
    padding: 19px 33px 0 31px;

    .reduced-header & {
        display: none;
    }

    .page-scrolled & {
        display: none;
    }
}

.header-desktop-top-left {
    flex-shrink: 0;
}

.header-desktop-top-right {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
}

.user-account {
    display: flex;
    height: 100%;
    align-items: center;
    flex-wrap: nowrap;

    &:hover {
        .header-account-title-link {
            color: $medium-grey;
        }

        .login-picture-container .st0 {
            fill: $medium-grey;
        }
    }

    .header-dropdown-status-arrow {
        margin: 0 0 0 10px;
    }
}

.header-account-logout {
    margin: 0 0 0 10px;
    @extend %ui3;

    &:hover {
        color: $black;
    }
}

.header-dropdown-list {
    @extend %h5;
    display: none;
    position: absolute;
    background-color: $white;
    top: 100%;
    right: 0;
    width: 168px;
    min-width: 100%;
    border: 1px solid $white-grey;
    box-sizing: content-box;
    box-shadow: 8px 8px 10px $box-shadow2;
    padding: 16px 19px;

    li:last-child {
        .header-desktop-dropdown-item {
            border: none;
        }
    }

    @include media('>=desktopXL') {
        font-size: 12px;
    }
}

.header-desktop-dropdown-item {
    display: inline-block;
    padding: 17px 5px 15px;
    width: 100%;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    border-bottom: 1px solid $light-grey;

    &:hover {
        .header-dropdown-status-arrow {
            &::before {
                border-top-color: $light-grey2;
            }
        }
    }

    .header-locale-select-link {
        height: 42px;
    }
}

.header-locale-select {
    left: 20px;
}

/* Minicart Section */
.mini-cart-content {
    display: none;

    @include media('>=desktopLarge') {
        position: absolute;
        right: -5000px;
        top: 51.5px;
        width: 486px;
        transition: 0.4s;
        opacity: 0;
        display: block;

        &.active {
            right: -284px;
            opacity: 1;
        }
    }
}

.mini-cart-content-inner {
    padding: 44px 0 28px 0;
    position: relative;
    right: 253px;
    background-color: $white;
    border: 1px solid $white-grey;
    box-shadow: 8px 8px 10px $box-shadow2;
    max-height: 616px;

    .volume-promo-msg {
        margin: 40px 0 30px;
    }
}

.mini-cart-products {
    margin: 0 32px;
    max-height: 290px;
    overflow-y: auto;
}

.mini-cart-product {
    border-bottom: 1px solid $light-grey;
    padding-bottom: 18px;
    align-items: center;

    &:not(:last-child) {
        margin-bottom: 26px;
    }
}

.product-variation-attribute,
.lineitem-data,
.mini-cart-qty {
    @extend %ui1;
    color: $dark-grey2;
    padding-bottom: 4px;
}

.mini-cart-bold {
    @extend %ui3;
}

.minicart-product-model {
    margin: 10px 0;

    &.mini-cart-bold {
        @extend %ui4;
    }
}

.lineitem-data {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    .item-number {
        flex-grow: 1;
    }

    .item-as-gift {
        padding: 0 0 0 10px;
        text-transform: none;
    }

    .giftbox-icon-container {
        padding: 0 2px 0 10px;
    }

    .giftsvg {
        width: 15px;
        height: 15px;

        .st0 {
            fill: $black;
        }
    }
}

// Minicart gift certification
.mini-cart-giftcert {
    color: $black;
}

.mini-cart-giftcert-image {
    width: 75px;
    height: 100px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
    background: $light-brown;

    @include media ('>=tablet') {
        width: 65px;
    }
}

.mini-cart-giftcert-name {
    @extend %h4;
    margin: 0 0 10px;
}

.mini-cart-giftcert-price {
    text-transform: uppercase;

    .price-title {
        display: none;
    }

    .price-value {
        @extend %h6;
        color: $dark-grey2;
    }
}

.mini-cart-giftcert-dl {
    @extend %ui1;
    color: $dark-grey2;
    margin: 0 0 10px;
}

/* Minicart product info (default styles for minilineitems.isml) */
.mini-cart-image {
    width: 75px;
    flex-shrink: 0;
    flex-grow: 0;
    align-items: stretch;
    justify-content: center;
    background-color: $light-brown;

    @include media ('>=tablet') {
        width: 65px;
    }
}

.minicart-product-info {
    margin: 0 0 0 12px;
    align-items: stretch;
}

.mini-cart-price {
    margin-top: 11px;
    display: block;

    .price-title {
        display: none;
    }
}

.mini-cart-button {
    @include media('>=desktop') {
        display: block;
        width: 100%;
        height: 42px;
        line-height: 42px;
        text-align: center;
        padding: 0 15px;
    }

    &.mini-cart-continue-shopping {
        @extend %ui1;
        color: $black;
        font-weight: 400;
        border: none;
        text-transform: none;
        text-decoration: underline;
        line-height: 20px;
        height: auto;
        padding-top: 24px;

        &:hover {
            background-color: $white;
            color: $medium-grey;
        }
    }
}

.mini-cart-button-wrapper-sr {
    @include media('>=desktop') {
        flex: 0 0 200px;
        flex-grow: 1;
        padding: 0 32px;
    }
}

.mini-cart-totals {
    text-align: left;
}

.mini-cart-subtotals {
    display: flex;
    justify-content: space-between;
    margin: 32px 32px 0;
}

.mini-cart-subtotals-label {
    @extend %ui1;
    color: $dark-grey5;
}

.mini-cart-attributes {
    margin-top: 10px;
}

.mini-cart-promotions {
    @extend %ui3;
    color: $black;
    margin: 0 32px;
}

.minicart-buttons {
    justify-content: center;
    margin-top: 25px;
}

.minicart-alert-dialog,
.minicart-cross-sell-alert-dialog,
.minicart-giftcert-alert-dialog {
    text-align: center;
    background-color: $white;
    border: 1px solid $light-grey;
    padding: 0;
}

.mini-cart-container-alert {
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .cart-alert-title {
        @extend %h2;
    }

    .minicart-alert-close {
        @extend %button1;
        text-transform: none;
        height: 48px;
        margin: 23px 0 16px;
        color: $white;

        @include media('>=tablet') {
            margin: 16px 0 8px;
        }
    }

    .minicart-alert-cart-link {
        @extend %button1;
        text-transform: none;
        height: 40px;
        line-height: 40px;

        &:hover {
            background: $white;
            color: $black;
        }
    }

    .mini-cart-alert-product {
        margin: 0 0 20px;
    }

    .mini-cart-alert-product-image {
        margin: 35px auto 25px;
        width: 150px;
        height: 225px;
    }

    .mini-cart-alert-giftcert-image {
        display: flex;
        align-items: center;
        background: $light-brown;
    }

    .mini-cart-alert-product-name,
    .mini-cart-alert-giftcert-name {
        @extend %h4;
    }

    .mini-cart-alert-product-info,
    .mini-cart-alert-giftcert-price,
    .mini-cart-alert-giftcert-date {
        @extend %body1;
    }

    .mini-cart-alert-giftcert-email {
        font-size: 10px;
        margin: 6px 0 0;
    }

    .mini-cart-btn {
        margin: 20px auto;
        padding: 0;
        width: 100%;

        @include media('>=tablet') {
            width: 90%;
        }
    }

    .volume-promo-msg {
        font-size: 14px;
    }
}

/* Header menu section */
.header-menu-section {
    display: none;
    left: 0;
    top: 100%;
    z-index: 10000;
    width: 100%;
    background-color: $white;
    position: absolute;
    border-top: 1px solid $light-grey;
    border-bottom: 1px solid $light-grey;
    cursor: default;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    flex-flow: row nowrap;
    padding: 44px 20px;

    @include media('>=desktopLarge') {
        padding-left: 160px;
    }
}

.header-menu-title_link {
    display: block;
    text-decoration: none;

    &:hover {
        .header-menu-title {
            color: $medium-grey;
        }
    }
}

.header-menu-title {
    @extend %h3;
    margin: -4px 0 0;
    padding: 0;
    text-transform: uppercase;
}

.header-menu-view-all {
    @extend %ui1;
    margin-top: 13px;
    display: inline-block;
    text-decoration: underline;

    .header-menu-sub-category & {
        margin-top: 25px;
    }
}

// Minicart product info (default styles for minilineitems.isml)
.minicart-lazy-image {
    max-width: 100%;
    margin: 0 auto;
    display: block;
    width: auto;
    align-self: center;
}

.header-menu-description {
    padding-bottom: 21px;
}

.header-menu-sub-description,
.header-menu-description {
    color: $black;
}

.header-menu-sub-link {
    display: inline-block;
    text-decoration: none;

    &:hover {
        .header-menu-sub-title {
            color: $medium-grey;
        }
    }
}

.header-menu-sub-title {
    @extend %h5;
}

.mini-cart-attributes {
    margin-top: 5px;
}

.product-availability-preorder {
    @extend %ui1;
    text-align: left;
    margin: 10px 0 5px;

    .product-availability-preorder-from {
        text-transform: lowercase;
    }

    .product-availability-preorder-date {
        font-weight: bold;
    }
}

.header-menu-child-category {
    @include media('>=desktopLarge') {
        display: block;
        text-decoration: none;
        text-transform: none;
        margin-bottom: 12px;
    }

    a {
        @extend %ui1;
        text-decoration: none;
        color: $dark-grey2;

        &:hover {
            color: $medium-grey;
        }

        span:hover {
            color: $medium-grey !important;
        }
    }
}

/* Mobile Menu */
.header-mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: scroll;
    background-color: $black;
    visibility: hidden;
    width: 100%;
    transition: 0.3s;
    z-index: 20000;

    .menu-active & {
        visibility: visible;
    }
}

.mobile-menu-panel {
    justify-content: flex-end;
    align-items: center;
    height: 64px;
    background: $black;
    margin-bottom: 38px;
}

.mobile-menu-section {
    position: fixed;
    right: -100%;
    top: 0;
    width: 100%;
    height: 100%;
    border: 0;
    transition: right 0.5s cubic-bezier(0, 1, 0.5, 1);
    background: $black;

    &.mobile-menu-subsection {
        background: $black;
    }
}

.mobile-menu-title {
    @extend %h4;
    color: $white;
    text-decoration: none;
    text-align: center;
    flex-grow: 1;
}

.mobile-menu-h2 {
    @extend %h4;
    color: $white;
}

.mobile-menu-close {
    height: 100%;
    display: flex;
    justify-content: center;
    align-self: stretch;
    margin: 0 23px 0 15px;

    .closesvg {
        width: 14px;
    }

    .st0 {
        fill: $white;
    }

    @include media('>=tablet') {
        margin-right: 48px;
    }

    @include media('>=desktop') {
        margin-right: 56px;
    }
}

.mobile-header-menu-item {
    display: block;
    width: 100%;
    //background: $black;
    text-decoration: none;
    cursor: pointer;

    &.mobile-menu-primary-item {
        text-transform: uppercase;

        .mobile-header-menu-item-title {
            justify-content: space-between;
        }
    }

    &.mobile-menu-secondary-item {
        text-transform: none;

        .mobile-menu-section .mobile-header-menu-item-title {
            justify-content: space-between;
        }

        .mobile-header-menu-item-title {
            @extend %h6;
            color: $white;
            text-transform: none;
            padding-left: 0;
            margin: 0 23px 0 20px;

            .header-menu-icon-mobile-container .svgchevron {
                margin-right: 0;
            }

            @include media('>=tablet') {
                margin: 0 108px 0 92px;
            }

            @include media('>=desktop') {
                margin: 0 126px 0 112px;
            }
        }
    }

    .mobile-header-menu-item-title {
        @extend %h3;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 6px 0 6px 20px;


        @include media('>=tablet') {
            padding: 10px 0 10px 92px;
        }

        @include media('>=desktop') {
            padding: 8px 0 8px 112px;
        }
    }

    // Special context and lang case
    .header-title-mobile {
        flex-wrap: wrap;
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
    }

    .header-subtitle-mobile {
        @extend %ui1;
        color: $white;
        padding-right: 6px;
        font-weight: normal;
    }

    .current-language-name {
        @extend %ui1;
        color: $white;
        text-transform: capitalize;
    }

    .header-ship-data {
        @extend %ui1;
        color: $white;
    }

    .header-ship-to-country {
        text-transform: capitalize;
    }

    .header-ship-to-currency {
        text-transform: uppercase;
    }

    &.mobile-header-menu-view-all {
        border: none;

        .view-all-link {
            @extend %button1;
            font-weight: 500;
            color: $white;
            display: block;
            border: 1px solid $white;
            text-align: center;
            padding: 9px 15px;
            margin: 44px 20px;

            @include media('>=tablet') {
                margin: 46px 105px 0 92px;
            }

            @include media('>=desktop') {
                margin: 46px 98px 0 85px;
            }
        }
    }

    a {
        text-decoration: none;
        display: block;
    }

    &.mobile-menu-item-lvl-2,
    &.mobile-menu-item-lvl-3 {
        text-transform: capitalize;
    }
}

.header-menu-icon-mobile-container {
    height: 16px;
    text-align: center;

    svg {
        width: 16px;
        height: 16px;
    }

    .svgchevron {
        width: 12px;
        height: 12px;
        margin: 0 23px;

        .st0 {
            fill: $white;
        }

        @include media('>=tablet') {
            margin-right: 108px;
        }

        @include media('>=desktop') {
            margin-right: 126px;
        }
    }

    .lifebuoysvg {
        .st0 {
            fill: $white;
        }
    }

    .loginpicturesvg,
    .maplocationsvg {
        .st0 {
            fill: $white;
        }

        .st1 {
            stroke: $white;
        }
    }

    .addtowishsvg,
    .loginpicturesvg,
    .maplocationsvg {
        margin-right: 20px;
    }

    .maplocationsvg {
        .st1 {
            stroke: $white;
        }

        .st2 {
            fill: $white;
        }
    }

    .addtowishsvg {
        .st0 {
            stroke: $white;
        }
    }
}

.header-menu-icon-mobile-container.ufd-bike-mobile-container {
    .bikesvg {
        width: 14px;
        height: 18px;
    }

    .st0 {
        fill: $white;
    }
}

.mobile-menu-ufd-bike-wrapper {
    width: 20px;
    height: 20px;
    border: 2px solid $light-grey;
    border-radius: 50%;
}

.mobile-menu-back-btn {
    display: flex;
    padding: 0 15px;
    height: 100%;

    .svgchevron {
        transform: rotate(180deg);
        width: 16px;
        height: 16px;
        align-self: center;

        .st0 {
            fill: $white;
        }
    }
}

/* Accesibility */
.header-skip-to-main-link {
    @extend %ui1;
    display: block;
    padding: 10px 20px;
    text-transform: uppercase;
    position: absolute;
    top: -9999px;
    left: -9999px;
    background: $white;
    border: 1px solid $light-grey;

    &:focus,
    &:active {
        top: 0;
        left: 0;
        z-index: 999999; // above the sticky panel, above the mobile menu, above the mobile CLP filter
    }
}

.header-help {
    display: none;
}

.header-ship-to-unsupported {
    @extend %ui1;
    text-transform: uppercase;
    height: 100%;
    display: inline-block;
    float: left;
    padding-right: vw(5px);

    @include media('>=desktop') {
        padding-left: 5px;
        padding-right: 5px;
    }
}

.header-stores-link {
    @extend %ui1;
    display: flex;
    height: 100%;
    align-items: center;
    flex-wrap: nowrap;
    padding: 0 8px;
    text-decoration: none;
    text-transform: uppercase;
    border-right: 1px solid $light-grey;

    @include media('>=desktopMedium') {
        padding: 0 23px;
    }

    &:hover {
        .header-stores-desktop-icon-container .st0 {
            fill: $blue-hover;
        }
    }
}

.complementary-gift-box-text {
    @extend %ui1;
    margin-top: 5px;
}

.header-menu-blog-category {
    @extend %ui2;

    @include media('>=desktop') {
        float: left;
        clear: left;
        text-transform: none;
        text-decoration: none;
        margin-bottom: 12px;
    }
}

.mobile-header-menu-ufd-code {
    @extend %ui1;
}

.header-menu-ufd-code {
    @extend %ui1;
}

.cookie-button {
    max-width: 200px;
    margin-top: 10px;

    +.cookie-button {
        margin-left: 5px;
    }
}

.mini-cart-button-wrapper {
    .gl_ajax-loader-icon {
        top: 35%;
    }
}

.header-menu-main {
    //flex: is used with flex properties from other header
    //elements to get 14 based fluid grid
    flex: 12;

    .header-menu-content {
        padding: 50px 8.3% 0;
        border-left: 1px solid $light-grey;

        .header-menu-kids & {
            height: 0;
        }

        .menu-bread-crumb,
        .header-menu-sub-title {
            position: relative;
            z-index: 1;
        }
    }
}


// ECM20-127 - Cookie Banner - CTA click mandatory
.cookie-dialog-content {
    @extend %body1;
    text-align: center;

    .content-asset {
        margin: 0 0 30px;

        @include media('>=desktop') {
            margin: 0 0 40px;
        }
    }

    .cookie-buttons {
        justify-content: center;
    }

    .cookie-button {
        margin-top: 0;
    }

    .cookie-refuse {
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
    }
}

// new classes
.svgmagnifyingglass {
    width: 16px;
    height: 16px;

    .st0 {
        fill: $black;
    }
}

.mini-cart-subtotals-value
.price-display-component {
    @extend %ui2;
    color: $dark-grey5;
}

.mini-cart-price {
    .price-display-component {
        @extend %h6;
        color: $dark-grey2;
    }

    // Specific display on discount price in minicart & on checkout
    /*
    .promotional-price {
        .price-display-component {
            color: $light-red2;
        }
    }
    */
}

.mini-cart-tax-message {
    @extend %ui3;
    color: $light-grey3;
    margin: 0 32px;
}

.mini-cart-container-alert-image-block {
    width: 100%;
    background: $white-grey2;
    padding: 21px 0;

    @include media('>=tablet') {
        padding: 21px 77px;
    }
}

.mini-cart-container-alert-info-block {
    padding: 20px 20px 32px;

    @include media('>=tablet') {
        padding: 35px 77px 40px;
    }
}

.mobile-menu-subsection {
    .mobile-menu-panel {
        border-bottom: 1px solid $dark-grey6;
    }
}

.locale-selector-wrapper {
    margin-top: 50px;

    @include media('>=tablet') {
        margin-top: 322px;
    }

    @include media('>=desktop') {
        margin-top: 40px;
    }
}

.ship-to-wrapper {
    margin-bottom: 10px;

    @include media('>=tablet') {
        margin-bottom: 130px;
    }

    @include media('>=desktop') {
        margin-bottom: 45px;
    }
}

.ship-to-wrapper,
.locale-selector-wrapper {
    .mobile-header-menu-item-title {
        border-top: 1px solid $dark-grey6;
        height: auto;
        padding: 19px 0 17px;
    }
}

.user-info-desktop {
    border-left: 1px solid $light-grey;
}

.gl_close-button span {
    color: $black;
    font-weight: bold;
}

.header-search-mobile {
    width: 100%;
    height: auto;
    padding: 15px;
    background: $white;
    display: none;
    position: absolute;
    z-index: 9;

    .header-search-input {
        height: 42px;
        line-height: 42px;
        background: $white;
    }

    .header-search-wrapper {
        margin: 0;
    }

    .search-suggestion-submit {
        width: 42px;
        background-color: $black;
    }

    .magnifying-glass-container {
        width: 42px;
        height: 42px;

        .svgmagnifyingglass {
            width: 20px;
            height: 20px;
            position: relative;
        }

        .st0 {
            stroke: $white;
            fill: $white;
        }
    }

    .reduced-header & {
        display: none;
    }

    @include media('>=tablet') {
        padding: 15px 36px 15px 32px;
    }
}

#search-suggestions {
    width: 100%;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: $white;
    padding: 12px;
    z-index: 99;
    border-style: solid;
    border-color: $light-grey;
    border-width: 0 0 1px 0;

    @include media('>=tablet', '<desktopLarge') {
        min-width: 20%;
        width: auto;
        top: calc(100% - 16px);
        right: 35px;
        border-width: 0 1px 1px 1px;
    }

    @include media('>=desktopLarge') {
        width: auto;
        min-width: 20vw;
        top: 62px;
        border-width: 1px;
    }
}

.suggestion-title {
    @extend %h5;
    text-transform: uppercase;
    margin-bottom: 12px;
}

.suggestion-link {
    @extend %link1;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    display: block;

    @include media('>=tablet') {
        max-width: 200px;
    }
}

.ufd-bike-container {
    width: 40px;
    height: 18px;
    position: relative;

    .header-ufd-link & {
        margin: 0 10px 0 0;
    }

    .bikesvg {
        position: absolute;
        top: 0;
        left: 0;
    }

    .st0 {
        fill: $black;
    }
}

.header-ufd-link {
    @extend %ui1;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding: 0 8px;
    text-decoration: none;
    text-transform: uppercase;
    border-right: 1px solid $light-grey;
    height: 42px;

    @include media('>=desktopMedium') {
        padding: 0 23px;
    }

    &:hover {
        .ufd-bike-container .st0 {
            fill: $medium-grey;
        }
    }
}

// deleted classes (commented, just the classname for trace)
/*
// ECM21-48: Specific menu display if menu items > 7
.header-desktop-menu-en {
    .header-menu-item.header-specific-menu-item {
        @include media('>=desktopMedium') {
            min-width: 70px;
        }

        @include media('>=desktopLarge') {
            min-width: 120px !important;
        }

        @include media('>=1500px') {
            min-width: 135px !important;
        }

        .header-menu-link {
            padding: 5px 5px 0;

            @include media('>=desktopMedium') {
                padding: 5px 10px 0;
            }
        }
    }
}

.header-desktop-menu-es {
    .header-menu-item.header-specific-menu-item {
        @include media('>=desktopMedium') {
            min-width: 60px !important;
        }

        @include media('>=desktopLarge') {
            min-width: 120px !important;
        }

        .gl_wave-button {
            font-size: 10px;

            @include media('>=1500px') {
                font-size: 12px;
            }
        }
    }
}

.header-desktop-menu-fr {
    .header-menu-item.header-specific-menu-item {
        @include media('>=desktopMedium') {
            min-width: 80px;
        }

        @include media('>=desktopLarge') {
            min-width: 120px !important;
        }

        @include media('>=1500px') {
            min-width: 135px !important;
        }

        .gl_wave-button {
            font-size: 10px;

            @include media('>=1500px') {
                font-size: 12px;
            }
        }
    }
}

.header-desktop-menu-de {
    .header-menu-item.header-specific-menu-item {
        @include media('>=desktopMedium') {
            min-width: 80px;
        }

        @include media('>=desktopLarge') {
            min-width: 120px !important;
        }

        @include media('>=1500px') {
            min-width: 135px !important;
        }

        .gl_wave-button {
            font-size: 10px;

            @include media('>=desktopLarge') {
                font-size: 12px;
            }
        }
    }
}

.header-desktop-menu-zh {
    .header-menu-item.header-specific-menu-item {
        @include media('>=desktopLarge') {
            min-width: 120px !important;
        }

        @include media('>=1500px') {
            min-width: 135px !important;
        }
    }
}

.header-desktop-menu-it {
    .header-menu-item.header-specific-menu-item {
        @include media('>=desktopLarge') {
            min-width: 120px !important;
        }

        @include media('>=1500px') {
            min-width: 135px !important;
        }

        .gl_wave-button {
            font-size: 10px;

            @include media('>=1500px') {
                font-size: 12px;
            }
        }
    }
}
*/

/*
.minicart-buttons-with-paypal {
    margin-bottom: -25px;
}
*/

/*
.header-banner-second {
    background-color: $dark-grey2;
    color: $black;

    .banner-close-btn {
        .ui-button-icon::before {
            color: $black;
        }
    }
}
*/

/*
.header-help-title {
    display: flex;
    height: 100%;
    align-items: center;
    flex-wrap: nowrap;
    padding: 0 8px;
    text-decoration: none;
    font: 10px / 13px $Arial;
    color: $blue;
    text-transform: uppercase;
    border-right: 1px solid $light-grey;

    @include media('>=desktopMedium') {
        padding: 0 23px;
    }

    &:hover {
        .header-stores-desktop-icon-container .st0 {
            fill: $blue-hover;
        }
    }
}

.header-help-panel {
    padding: vw(100px) 0 vw(50px);
    background-color: $white;
    cursor: default;
    border-bottom: 1px solid $light-grey;

    @include media('>=tablet') {
        padding: 40px 0;
    }

    @include media('>=desktop') {
        padding: 60px 0;
        width: 377px;
        border-bottom: 0;
    }

    .svgwave-container {
        text-align: center;
    }

    .svgwave {
        transform: scale(0.5);
    }

    .svgwavepath {
        stroke: $blue;
    }
}

.header-dropdown-panel-container {
    .header-help.hover &,
    .header-help:hover & {
        background-color: $white;
        height: 1px;
    }
}

.header-help-dropdown {
    @include media('>=desktop') {
        position: absolute;
        display: none;
        right: 0;
        top: 42px;
        z-index: 50000;
        border-left: 1px solid $light-grey;
        border-right: 1px solid $light-grey;
        border-bottom: 1px solid $light-grey;
    }
}
*/

/* .mini-cart-price {
    margin-top: 6px;
    display: block;
} */

/*
.mobile-menu-initial-close {
    border-left: 1px solid $light-grey;
}
*/

/*
.mobile-menu-item-lvl-1.mobile-menu-secondary-item & {
    width:22px;
    margin-right: 8px;
}
*/
/*
.help-content-wrapper {
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
}

.help-content {
    margin: 30px 0 0 0;
    flex-flow: column;

    @include media('>=desktop') {
        margin-top: 40px;
    }
}

.help-title {
    text-align: center;
    font: vw(28px) $MontserratBold;
    line-height: vw(28px);

    @include media('>=tablet') {
        font-size: 20px;
        line-height: 20px;
    }

    @include media('>=desktop') {
        font-size: 14px;
        line-height: 14px;
    }
}

.help-wave-separator {
    display: block;
    margin-top: 30px;

    @include media('>=desktop') {
        margin-top: 20px;
    }
}

.help-message {
    padding-left: vw(35px);

    @include media('>=desktop') {
        padding-left: 30px;
    }

    @include media('>=desktop') {
        padding-left: 15px;
    }
}

.help-item {
    margin-bottom: vw(60px);
    text-transform: uppercase;
    color: $blue;
    align-items: center;
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: auto;

    @include media('>=tablet') {
        margin-bottom: 40px;
    }

    @include media('>=desktop') {
        margin-bottom: 30px;
    }
}

.help-icon {
    max-width: 64px;
    max-height: 64px;
}

.help-message-title {
    font: 14px $MontserratBold;
    line-height: 14px;
    display: block;
    white-space: nowrap;

    @include media('>=tablet') {
        font-size: 18px;
        line-height: 18px;
    }

    @include media('>=desktop') {
        font-size: 11px;
        line-height: 11px;
    }
}

.help-message-text {
    font: 12px $Arial;
    line-height: 12px;
    margin-top: 8px;
    display: block;

    @include media('>=tablet') {
        font-size: 16px;
        line-height: 16px;
    }

    @include media('>=desktop') {
        font-size: 10px;
        line-height: 10px;
    }
}

.help-cta-container {
    justify-content: center;
    padding: 0 vw(145px);

    @include media('>=desktop') {
        padding: 0;
    }
}

.help-cta {
    width: 100%;

    @include media('>=desktop') {
        width: 226px;
    }
}

.gl_btn-blue
.gl_button
*/

// =================================
// VILEBREQUIN GRAPHICAL CHART
// =================================
// EADA-27 - Skip to Main Content link
.main-content-start {
    position: absolute;
    top: -150px; // hack: negative top to prevent visual scroll and fix content overlap by sticky header
    left: 0;
}

.main-navigation {
    position: relative;
    background-color: $white;

    .menu-active & {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }

    @include media('>=desktopLarge') {
        position: static;
    }
}

.navigation {
    z-index: 20000;
    position: relative;
}

// Sticky header
.page-scrolled {
    .header-sticky-panel {
        position: fixed;
        width: 100%;
        z-index: 25000;
        top: 0;
    }
}

// Hide banner on mobile menu activation
.menu-active {
    .header-banner {
        display: none;
    }
}

.header-top-container {
    height: 100%;
}

.primary-logo-mobile {
    height: 100%;
    flex-direction: column;
    justify-content: center;
    padding-right: 0;
    padding-left: 0;
    padding: 0 10px 0 0;

    .reduced-header & {
        flex-basis: auto;
    }
}

.primary-logo-desktop {
    padding-left: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .page-scrolled & {
        padding-left: 20px;
    }

    .reduced-header & {
        position: relative;
        padding: 0;
    }
}

.primary-logo-desktop-link {
    .reduced-header & {
        display: block;
        text-align: center;
    }
}

.primary-logo-desktop-link {
    display: inline-block;
}

.primary-logo-link {
    display: inline-block;
    width: 90px;
}

.sticky-logo-image {
    display: none;
    width: 54px;
    height: 54px;
}

.mini-cart {
    height: 100%;
    position: relative;
    z-index: 5000;
    align-items: center;

    @include media('>=desktop') {
        z-index: 20000;
    }

    .bonus-discount-container {
        display: none;
    }
}

// Mobile Added to Cart Alert
#js-miniCartAlert,
#js-miniCartAlert_crossSell,
.giftcert-dialog-alert {
    display: none;

    .minicart-alert-dialog &,
    .minicart-giftcert-alert-dialog & {
        display: inline-block;
    }
}

.ship-to-link-wrapper {
    height: 100%;
    flex-direction: column;
    justify-content: center;
}

.ship-to-text-more {
    left: auto;
    right: 20px;
}

.header-desktop-top-list {
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
}

.header-locale {
    display: flex;
}

.header-locale-select-link,
.header-current-locale {
    display: flex;
    align-items: center;
    padding: 0 20px;
    position: relative;
    min-width: 88px;

    .header-dropdown-status-arrow {
        margin: 0 0 0 10px;
    }
}

.header-locale-select-link {
    &.header-desktop-dropdown-item {
        height: 41px;
        line-height: 41px;
        padding: 0 20px;
    }
}

.header-locale-select {
    &.header-dropdown-list {
        box-sizing: border-box;
    }
}

.header-dropdown-status-arrow {
    width: 8px;
    height: 4px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        margin: -2px 0 0;

        @include triangle-up();
    }
}

.header-account-title-link {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.header-account-title-text {
    display: inline-block;
}

.header-icon {
    top: 12px;
}

.login-picture-container {
    display: block;
    position: relative;
    width: 14px;
    height: 14px;
    top: -2px;

    .header-menu-icon-mobile-container & {
        top: -2px;
    }

    .header-account-title-link & {
        margin: 0 17px 0 25px;
    }
}

.lifebuoy-container {
    display: block;
    position: relative;
    width: 14px;
    height: 14px;
    margin: 0 10px 0 0;

    .header-menu-icon-mobile-container & {
        top: -2px;
    }

    .st0 {
        fill: $blue;
    }
}

.track-order-icon {
    @include mobile-first-icon('track-my-order');
}

.return-policy-icon {
    @include mobile-first-icon('return-policy');
}

.shipping-details-icon {
    @include mobile-first-icon('shipping-details');
}

.mail-icon {
    @include mobile-first-icon('send-us-an-email');
}

.minicart-close {
    position: absolute;
    right: 20px;
    top: 20px;
}

.mini-cart-option {
    margin-top: 6px;
    text-transform: none;
    font-style: italic;
}

.minicart-pay-pal-img {
    top: 9px;
    float: right;
}

.minicart-pay-pal {
    display: inline-block;
    top: 4px;
}

.complementary-box {
    margin-top: 20px;
    text-align: center;
    text-transform: uppercase;
}

.minicart-complementary-gift-box {
    margin: 0 auto;
    position: relative;
    z-index: 5000;
}

.complementary-gift-box-title {
    margin-top: -15px;
    position: relative;
    z-index: 10000;
}

.minicart-complementary-giftbox-image {
    width: auto;
}

.header-menu-left-title {
    display: flex;
    flex-direction: column;
    max-width: 270px;
    padding: 0 6px;
    flex: 0 1 170px;

    @include media('>=desktopLarge') {
        padding: 0 10px 0 6px;
    }
}

.header-menu-middle-section {
    padding-top: 20px;
    justify-content: space-around;
    padding: 50px 8.3% 50px 0;

    .menu-gifts & {
        padding-right: 0;
        border-right: 0;
    }
}

.menu-bread-crumb {
    display: inline-block;
    text-transform: none;
    margin: 20px 0 30px 0;
}

.header-menu-sub-ghost {
    flex: 1 1 50%;
}

.header-gift-wrapper-outer {
    float: left;
    width: 50%;
    height: vw(160px);
    max-height: 282px;
    min-height: 245px;

    &:first-child {
        padding-right: 10px;
    }
}

.header-gift-wrapper {
    height: 100%;
}

.header-gift-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.header-gift-wrapper-split {
    height: 50%;

    &:first-child {
        padding-bottom: 10px;
    }
}

.header-gifts-image {
    height: 100%;
}

.header-gift-text-wrapper {
    position: absolute;
    text-align: center;
    display: table;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.header-gift-text {
    @include media('>=desktop') {
        display: table-cell;
        vertical-align: middle;
    }
}

.header-gift-text-line {
    width: 100%;
    text-transform: uppercase;

    .header-gift-wrapper-single & {
        font-size: 24px;
        line-height: 24px;
    }

    @include media('>=desktop') {
        color: $white;
        font-size: 18px;
        line-height: 18px;
        display: inline-block;
    }
}

.header-menu-magazine-title {
    margin: 11px 0;
    color: $blue;
    font: 12px $MontserratBold;
    text-transform: uppercase;

    a {
        text-decoration: none;
    }
}

.filter-active {
    overflow: hidden;
}

// Mobile nav menu sliders via css
.menu-active {
    // no scroll and actions
    overflow: hidden;
    position: fixed;
    pointer-events: none;
}

.mobile-menu-section.show {
    right: 0;
    bottom: 0;
    overflow: scroll;
    z-index: 20001;

    // unblock visible menu section
    pointer-events: auto;
}

.mobile-menu-section.in {
    // block all elements in mobile menu to avoid click on non-visible menu section
    pointer-events: none;
}

.mobile-menu-logo-link {
    display: block;
    margin: 0 15px;
    max-width: 80px;
}

.mobile-menu-primary-item + .mobile-menu-secondary-item {
    margin-top: 100px;

    @include media('>=tablet') {
        margin-top: 84px;
    }

    @include media('>=desktop') {
        margin-top: 48px;
    }
}

.ui-accordion-header-icon {
    .user-info.gl_mobile-only &::before,
    .header-menu-help-thumb &::before {
        display: none;
    }

    a {
        text-decoration: none;
        display: block;

    }

    &.mobile-menu-item-lvl-2,
    &.mobile-menu-item-lvl-3 {
        text-transform: capitalize;
    }
}

// Map icon on desktop header
.map-location-container {
    width: 16px;
    height: 16px;
    position: relative;

    .svgmaplocation {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 0;
        left: 0;
    }

    .st0 {
        fill: $blue;
    }

    .st1 {
        fill: $white;
    }
}

// Search Suggestions
.suggestion-wrapper {
    @include media('>=tablet') {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-content: center;
        align-items: flex-start;
    }
}

.suggestion-column {
    @include media('<tablet') {
        & + & {
            margin-top: 20px;
        }
    }

    @include media('>=tablet') {
        padding: 0 12px 0 0;
        max-width: 212px;

        & + & {
            padding: 0 0 0 12px;
        }
    }
}

.suggestion-combined + .suggestion-pages {
    margin-top: 20px;
}

.suggestion-item {
    padding: 4px 0;
}

.map-location-container {
    width: 16px;
    height: 16px;
    position: relative;

    .header-desktop-top-item & {
        position: absolute;
        top: 12px;
        left: 21px;
    }

    .svgmaplocation {
        position: absolute;
        top: 0;
        left: 0;
    }

    .st0 {
        fill: $blue;
    }

    .st1 {
        fill: $white;
    }
}

.chevron-container {
    width: 16px;
    height: 16px;
    position: relative;
    top: -1px;

    .svgchevron {
        position: absolute;
        top: 0;
        left: 0;
    }

    .st0 {
        stroke: $blue;
    }
}

.search-suggestions-close {
    position: absolute;
    top: 20px;
    right: 20px;
}

.search-suggestion-wrapper {
    width: 100%;
    position: relative;
}

.search-suggestions-left {
    border-right: 1px solid $light-grey;
    padding: 50px 0;

    .search-suggestions-no-products & {
        border-right: 0;
        justify-content: center;
    }
}

.search-bread-crumb-wrapper {
    .search-suggestions-no-products & {
        text-align: center;
        margin-bottom: 5px;
    }
}

.search-suggestions-right {
    padding: 50px 0 20px;
}

.product-suggestion-wrapper {
    padding: 0;
    margin-bottom: 30px;

    .search-suggestions-no-hints & {
        &:nth-child(4n) {
            flex-basis: 12.5%;
            max-width: 12.5%;

            .product-suggestion {
                width: 120%;
            }
        }
    }

    .search-suggestions-lazy-image {
        display: block;
        width: auto;
        margin: 0 auto;
    }
}

.search-suggestion-header {
    @include media('>=desktop') {
        font: 14px / 14px $MontserratBold;
        text-transform: uppercase;
        margin-bottom: 20px;
        padding-right: 20px;
    }

    .search-suggestions-no-products & {
        padding-right: 0;
    }

    .search-suggestions-no-hints & {
        text-align: center;
        padding-right: 0;
    }
}

.product-suggestions {
    .search-suggestions-no-hints & {
        justify-content: center;
        flex-wrap: wrap;
    }
}

.hitgroup {
    margin-bottom: 50px;

    .search-suggestions-left.col-md-12 & {
        &:not(:first-child) {
            margin-left: 50px;
        }
    }
}

.parent-category {
    color: $blue;
    font-size: 12px;
}

.search-suggestion-bread-crumb {
    &:first-child {
        margin-top: 19px;
    }
}

.search-product-details {
    text-align: center;
    padding-top: 15px;
}

.search-product-link {
    text-decoration: none;
}

.search-product-name,
.search-product-price {
    font: 11px $MontserratBold;
    text-transform: uppercase;
}

.search-product-subtext,
.search-product-swatch-count {
    font: 10px $MontserratBold;
}

.search-product-model {
    text-transform: capitalize;
}

.search-product-price {
    margin-top: 12px;
}

// GDPR-22 - GDPR - Consent alert when privacy policy changes
.cookie-update-banner {
    text-align: center;
    padding: 5px;
    font-size: 12px;
    min-height: 110px;
    border: 2px solid $blue;
    background-color: $white;
    z-index: 20000;
    position: relative;

    .cookie-update-content {
        margin: 0 auto;
        width: 95%;

        @include media('>=tablet') {
            width: 85%;
        }
    }

    .content-asset {
        margin: 0 15px;
    }

    .page-scrolled & {
        display: none;
    }

    @include media('>=tablet') {
        min-height: 90px;
    }

    @include media('>=desktop') {
        min-height: 70px;
    }
}

.cookie-close {
    position: absolute;
    right: 3px;
    top: 0;
    padding: 5px 10px 10px;
}

// GDPR-23 - GDPR - Privacy policy, cookie policy, terms and conditions
.asset-date {
    text-align: center;
    font: italic normal 14px / 14px $TimesNewRoman;
    padding: 20px 0;
}

// EW-169 - Header revision
.header-stores {
    &:hover {
        .map-location-container .st0 {
            fill: $blue-hover;
        }
    }
}

.header-stores-desktop-icon-container {
    margin: 0 10px 0 0;
    width: 16px;
    height: 16px;

    .svgmaplocation {
        width: 16px;
        height: 16px;
    }

    .st0 {
        fill: $blue;
    }

    .st1 {
        fill: $white;
    }
}

.header-stores-icon-container {
    position: absolute;
    top: 11px;
    left: 23px;
}

// EW-125 - CRO - Magazine navigation panel
.stories-wrapper {
    padding-top: 45px;
    padding-bottom: 30px;

    .blog-tile-title {
        margin-top: 10px;
        padding: 0;
    }
}

.stories-nopadding.col-md8 {
    padding: 0;
}

.stories-categories {
    flex-flow: row wrap;
}

.stories-category {
    flex: 0 0 50%;
    padding: 0 10px;

    &.header-menu-sub-category {
        padding-bottom: 30px;
    }
}

.menu-image {
    max-height: 237px;
    width: auto;
    max-width: 100%;
}

.header-menu-sub-description {
    margin: 0;
}

.header-menu-sub-category {
    padding: 0 6px 6px;
    min-width: 158px;
    max-width: 180px;
    flex: 1 1 170px;

    &.content {
        width: 170px;
        max-width: 170px;
        padding: 0 6px;
        display: flex;
        flex-direction: column;

        .header-menu-sub-link.content {
            line-height: 16px;
        }
    }
}

// margin-bottom for search bar
.login-header-mobile {
    width: 50px;
    height: 100%;
    align-items: center;
    justify-content: center;
    border-left: 1px solid $light-grey;

    .loginmobilesvg {
        width: 30px;
        height: 30px;

        .st0 {
            fill: $blue;
        }

        .st1 {
            fill: none;
            stroke: $blue;
        }
    }

    .login-icon-link {
        height: 30px;
    }
}

// SR-211 remove the search bar on mobile: deviation on SR
/*
.homepage {
    .search-wrapper-mobile {
        display: none;
    }

    .header-search-mobile {
        display: block;
    }
}
*/

.header-search-mobile {
    display: none;
}

.header-menu-sub-image {
    margin-top: 4px;
}

// Trick to aligment icon & images in content panel
.header-menu-icon-wrapper {
    margin-top: 4px;
    width: 100px;
}

.header-menu-sublist {
    height: 237px; // image height
    margin-top: auto;
}

.header-menu-content-image {
    margin-top: auto;
}

.header-menu-aria-trigger {
    position: absolute;
    top: -9999px;
    right: -9999px;
    width: 16px;
    height: 12px;

    &:focus {
        top: 5px;
        right: -10px;
    }

    .header-dropdown-status-arrow {
        height: 4px;
        width: 8px;
        position: static;
        display: block;
    }
}

.header-wishlist {
    flex-grow: 1;
    text-align: right;
}
