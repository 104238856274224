.richtext-component {
    @extend %body1;
    margin: 45px 20px 60px;

    @include media('>=tablet') {
        margin: 90px auto 120px;
        max-width: 585px;
    }

    @include media('>=desktop') {
        margin: 100px auto 100px;
        max-width: 640px;
    }

    @include media('>=desktopXL') {
        max-width: 920px;
    }
}
