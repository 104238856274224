.pd-accountcreation {
    margin: 45px 25px 20px;

    @include media('>=tablet') {
        margin: 70px auto;
    }

    @include media('>=desktop') {
        margin: 100px auto;
        max-width: 710px;
    }
}

.pd-accountcreation-title {
    text-align: center;
    margin: 0 0 30px;
}

.pd-accountcreation-form {
    .pd-accountcreation-optional {
        display: none;
    }
}
