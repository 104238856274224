.manifesto {
    padding: 85px 0 65px;
}

.manifesto-title {
    margin: 0 20px 20px;

    @include media('>=tablet') {
        margin-left: 32px;
        margin-right: 32px;
    }
}

.manifesto-description {
    @extend %body1;
    margin: 38px 0 24px;

    @include media('>=desktop') {
        margin-top: 0;
    }
}

.manifesto-carousel-component {
    padding: 0 0 0 14px;

    @include media('>=tablet') {
        padding: 0 0 0 32px;
        margin: 0;
        display: flex;
        justify-content: flex-start;
    }

    @include media('>=desktop') {
        margin: 0;
    }

    .manifesto-carousel-component-tile {
        padding: 0 6px;

        @include media('>=tablet') {
            padding: 0 7px 0 0;
        }

        @include media('>=desktop') {
            padding: 0 12px 0 0;
        }
    }
}

.manifesto-link {
    @extend %discoveryCTA;
    margin: 0 0 19px;
}

.manifesto-content {
    @include media('>=desktop') {
        display: flex;
    }
}

.manifesto-carousel {
    @include media('>=desktop') {
        width: 55%;
        flex-shrink: 0;
        flex-grow: 0;
    }
}

.manifesto-text {
    padding: 0 20px;

    @include media('>=tablet') {
        padding: 0 32px;
    }

    @include media('>=desktop') {
        margin: 0;
        padding: 15% 8vw 0;
    }

    @include media('>=desktopLarge') {
        padding-top: 295px;
    }

    @include media('>=desktopXL') {
        padding: 295px 12vw 0;
    }
}
