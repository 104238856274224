// Styles for the promotional Gift page
.giftpage-tabs-mobile-wrapper {
    border-top: 1px solid $light-grey;
    border-bottom: 1px solid $light-grey;
}

.gift-page-mobile-filters {
    display: none;
}

.giftpage-header-content-wrapper.row {
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    text-align: center;

    @include media('>=desktop') {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.giftpage-tabs-header-wrapper {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: center;

    @include media('>=desktop') {
        padding: 50px 0;
    }
}

.giftpage-tabs-header-title {
    font-size: vw(60px);
    padding: vw(31px) 0 0;

    @include media('>=tablet') {
        font-size: vw(38px);
        padding: 7px 0 0;
    }

    @include media('>=desktop') {
        font-size: 24px;
        padding: 0;
    }
}

.giftpage-tabs-header-separator {
    margin: 20px 0;
}

.giftpage-tabs-header-subtitle {
    font-size: vw(24px);
    color: $blue;
    margin: 0;

    @include media('>=desktop') {
        font-size: 12px;
    }
}

.giftpage-refinements {
    position: relative;
}

.giftpage-tabs-menu {
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: center;
    border: solid $blue-light;
    border-width: 1px 0;

    .family-gender-refinements & {
        position: relative;

        @include media('>=desktop') {
            .search-result-options {
                position: absolute;
                right: 0;
                padding: 10px;
                top: 0;
            }
        }
    }
}

.giftpage-tabs-menu-list {
    text-align: center;
    width: 100%;

    @include media('>=desktop') {
        width: auto;
    }
}

.giftpage-tabs-link {
    padding: 20px 15px 15px;
    text-decoration: none;

    @include media('>=480px') {
        padding: 20px 20px 15px;
    }
}

.giftpage-tabs-menuitem {
    display: inline-block;
}

.giftpage-result-content {
    .category-subset-row {
        padding: 0 3px;

        @include media('>=tablet') {
            padding: 0 5px;
        }
    
        @include media('>=desktop') {
            padding: 0 10px;
        }

        @include media('>=desktopLarge') {
            padding: 0;
        }
    }

    .grid-tile {
        padding: 0 3px;

        @include media('>=tablet') {
            padding: 0 10px;
        }
    }
}
