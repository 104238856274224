// =================================
//    PDP
// =================================
// =================================
// SONIA RYKIEL GRAPHICAL CHART
// =================================

// updated classes

.add-to-cart.disabled,
.product-ufd-button.disabled,
.product-ufd-button[disabled] {
    background: $add-to-cart-disabled;

    &:hover {
        background: $add-to-cart-disabled !important;
    }
}

// promotion on PDP desktop / mobile
.product-detail-price-desktop,
.product-detail-mobile-promotion {
    .promotion {
        display: flex;
        flex-wrap: nowrap;
        margin: 7px 0 0;
        @extend %h6;
        color: $dark-grey2;
    }

    .promotion-title {
        margin: 0 4px 0 0;
    }

    .promotion-callout {
        font-weight: bold;
    }
}

.productset-product-detail-container {
    .pdp-preorder-msg {
        margin: 10px 0;
    }
}

.product-name-container {
    width: 100%;
    padding: 10px 20px;
    background: $white;

    @include media('>=tablet') {
        padding: 20px 32px;
    }
}

.product-name-container,
.product-detail-container,
.product-set-details,
.quickview-pdp-header {
    .product-detail-name {
        @extend %ui1;
        text-transform: none;

        @include media('>=desktop') {
            text-align: left;
            margin: 0 0 10px;
        }


        @include media('>=desktopLarge') {
            text-align: left;
            margin: 0 0 12px;
        }

        .cross-sell-pdp & {
            padding: 5px 20px 0;

            @include media('>=480px') {
                padding-top: 15px;
            }
        }

        &.fakeH1 {
            @extend %h3;
            margin: 0 0 12px;
        }
    }
}

// price on PDP desktop
.product-detail-price-desktop {
    margin: 0 0 18px;

    .price-value-text {
        @include media('>=desktop') {
            display: block;
            text-align: left;
        }
    }
}

.hdlink-wrapper {
    display: block;
    cursor: pointer;
    position: absolute;
    bottom: 22px;
    right: 20px;

    @include media('>=tablet') {
        bottom: 30px;
        right: 32px;
    }

    @include media('>=desktop') {
        bottom: 16px;
        right: 16px;
    }
}

.product-hd-image-link {
    display: flex;
    flex-direction: column;
    width: 40px;
    height: 40px;
    font-size: 0;
    line-height: 0;
    background-color: $white;
    border-radius: 50%;
    padding: 12px;

    &::after,
    &::before {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        border: 2px solid $black;
        opacity: 0.75;
    }

    &::before {
        align-self: flex-end;
        border-left: none;
        border-bottom: none;
    }

    &::after {
        border-top: none;
        border-right: none;
    }
}

.product-detail-wishlist-wrapper {
    position: absolute;
    left: 20px;
    bottom: 22px;

    @include media('>=tablet') {
        bottom: 30px;
        left: 32px;
    }

    @include media('>=desktop') {
        bottom: 16px;
        left: 16px;
    }

    .product-detail-wishlist {
        height: 40px;
        width: 40px;
        padding: 12px;
        background-color: $white;
        border-radius: 50%;
    }
}

.variation-select-title {
    .attribute-color &,
    .attribute-size & {
        @extend %ui1;
        margin: 0 0 9px;
    }

    .title-swatch-label {
        @extend %ui2;
    }

    .store-availability-wrapper & {
        text-align: left;
        margin: 0;
        height: 50px;
        cursor: pointer;
        border-right: 1px solid $light-grey;
        padding-left: vw(50px);

        &.active {
            background-color: $light-grey;
        }

        @include media('>=tablet') {
            padding-left: 50px;
        }

        @include media('>=desktop') {
            padding-top: 20px;
            padding-right: 15px;
            padding-left: 15px;
            height: 100%;
            min-height: 75px;
            display: block;

            &.active {
                background-color: transparent;
            }

            &::after {
                content: '';
                position: absolute;
                right: 15px;
                top: 25%;
                margin: 4px 0;
                @include triangle-down();
            }

            &.active {
                &::after {
                    @include triangle-up();
                }
            }
        }
    }

    .cross-sell-pdp & {
        font-size: 12px;
        padding: 5px 0;
        margin: 0;

        @include media('>=480px') {
            padding: 16px 0;
        }
    }
}

.productform-link-size-guide-margin {
    @extend %ui1;
    color: $dark-grey2;
}

.product-form-sticky {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 20000;
    background-color: $white;

    .menu-active & {
        position: static;
    }
}

.product-form-sticky-content {
    padding: 19px 20px 9px;
    border-top: 1px solid $light-grey;

    @include media('>=tablet') {
        padding: 20px 32px;
    }
}

.product-form-sticky-row {
    justify-content: space-between;
    margin: 0 0 13px;
}

.product-detail-name-sticky {
    @extend %h3;
    max-height: 48px;
    overflow: hidden;
    padding: 0 5px 0 0;
}

.product-detail-price-sticky {
    text-align: right;

    .price-value-text {
        display: block;
    }
}

.shipping-estimate-wrapper {
    @extend %ui1;

    .product-add-to-cart > & {
        margin-top: 4px;
        display: none;

        @include media('>=desktop') {
            display: block;
            margin-top: 19px;
        }
    }

    .product-form-sticky & {
        text-align: left;
        margin-top: 19px;
        display: block;

        @include media('>=desktop') {
            display: none;
        }
    }
}

.product-form-sticky-form-buttons-row {
    margin: 0 -6px;

    .product-pdp-button,
    .product-pdp-fake-link-btn {
        margin: 10px 6px 0;
    }

    .product-pdp-button {
        order: 1;
        width: calc(50% - 12px);
        flex-grow: 1;
    }
}

.product-form-sticky-form-row {
    display: flex;
    align-items: flex-end;

    @include media('>=tablet') {
        justify-content: space-between;
    }
}

.product-detail-size-sticky {
    width: 100px;
    margin: 0 6px 0 0;
    flex: 0 0 100px;

    @include media('>=tablet') {
        width: 286px;
        flex: 0 0 286px;
    }
}

.product-action-sticky {
    margin: 0 0 0 6px;
    width: 100%;
    position: relative;

    @include media('>=tablet') {
        width: 286px;
        flex: 0 0 286px;
    }

    &.not-found-variation-action {
        margin-top: 6px;
    }

    .add-to-cart {
        @extend %h3;
        height: 48px;
        padding: 0 15px;

        &:not(:disabled) {
            color: $white;
        }
    }

    // Back-in-stock in sticky panel
    .pdp-unavailable-size-text-sticky {
        @extend %ui3;
        position: absolute;
        top: -14px;
        margin: 0;
        width: 100%;
        height: 10px;
        overflow: hidden;
    }

    .gl_error-message {
        @extend %ui3;
        position: absolute;
        bottom: -12px;
        width: 100%;
        height: 10px;
        overflow: hidden;
    }

    .not-found-variation-input-sticky {
        padding: 0;
    }

    .input-email-sticky {
        height: 48px;
        line-height: 48px;
        padding: 0 vw(84px) 0 8px;

        &::placeholder {
            font-size: 12px;
        }

        @include media('>=desktop') {
            padding: 0 40px 0 8px;
        }
    }

    .input-ok-sticky {
        line-height: 48px;
    }

    .pdp-unavailable-size-block-sticky {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        height: 48px;
    }
}

.size-list {
    padding: 0;

    .size-select {
        height: 48px;
        text-transform: none;
        font-family: $Mono;

        @include media('<tablet') {
            font-size: 12px;
        }
    }

    .size-select-error-msg-sticky {
        max-width: 90%;
        height: 20px;
        overflow: hidden;
    }
}

.pdp-tabs-container {
    padding: 0 20px;

    @include media('>=tablet') {
        padding: 0 32px;
    }

    .tabs-accordion-header {
        @extend %h4;
        line-height: 22px;
        padding: 20px 0;
    }

    .size-chart-selector-wrapper {
        margin-top: 4px;
    }

    .tab {
        display: none;

        .size-chart-page-col {
            padding-bottom: 30px;
        }

        .tab-content,
        .product-description-tab-column {
            @extend %body1;
            padding: 0;
        }
    }

    .accordionminussvg,
    .accordionplussvg {
        display: none;
    }

    .gl_accordion-fold,
    .gl_accordion-deploy {
        width: 8px;
        height: 8px;
        border-width: 2px 2px 0 0;
        border-style: solid;
        border-color: $black;
    }

    .gl_accordion-deploy {
        transform: rotate(135deg);
    }

    .gl_accordion-fold {
        transform: rotate(-45deg);
    }

    @include media('>=desktop') {
        background: $white;
        border-bottom: none;
        padding: 0;
        margin-top: 115px;

        .tabs-accordion-header {
            .gl_plus-button {
                width: 18px; // to match .gl_close-button width
            }

            .gl_plus-button,
            .gl_close-button {
                font-size: 20px;
                font-weight: bold;
                line-height: 20px;
                text-align: center;

                span {
                    position: static;
                    display: block;
                    line-height: 20px;
                }
            }
        }
    }

    @include media('>=desktopLarge') {
        margin-top: 166px;
    }

    @include media('>=desktopLarge') {
        margin-top: 120px;
    }
}

.pdp-accordion-header-wrapper:first-child {
    .tabs-accordion-header {
        @include media('>=desktop') {
            border-top-width: 0;
        }
    }
}

.gshipping-title {
    @extend %h4;
    margin: 0 0 vw(15px);

    @include media('>=desktop') {
        margin: 0;
    }
}

.gshipping-details {
    @extend %body2;
    margin: 0;
}

.productform-links-wrapper {
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 20px 20px;

    @include media('>=tablet') {
        justify-content: flex-start;
        margin: 0 35px 27px;
    }

    @include media('>=desktop') {
        justify-content: space-between;
        margin: 0 0 41px;
    }

    .pt_mixmatch-iframe & {
        display: none;
    }
}

// Complete your look block
.pdplook-wrapper {
    position: relative;
    margin: 80px 0;

    @include media('>=tablet') {
        margin: 90px 32px;
    }

    .family-badge-wrapper {
        display: none;
    }
}

.pdplook-title-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0 20px 18px;

    @include media('>=tablet') {
        margin: 0 0 18px;
    }
}

.pdplook-title {
    @extend %h3;
}

.pdplook-arrows-wrapper {
    @include custom-arrows();
}

.pdplook-products {
    margin: 0 14px;

    @include media('>=tablet') {
        margin: 0;
    }

    &.pdplook-carousel-wrapper {
        @include media('<tablet') {
            margin: 0 0 0 14px;
        }
    }
}

.pdplook-carousel {
    @include media('>=tablet') {
        display: flex;
        margin: 0 -6px;
    }
}

.pdplook-tile {
    padding: 0 6px;
}

.pdplook-carousel:not(.slick-initialized) {
    .pdplook-tile {
        width: 100%;

        @include media('>=tablet') {
            flex: 0 0 33.33%;
            max-width: 33.33%;
        }
    }
}

.pdplook-carousel.slick-initialized {
    .pdplook-tile {
        width: 270px;
    }
}

.pdplook-button {
    padding: 18px 0 0;
}

.pdplook-link {
    @extend %button1;
    text-decoration: none;
    white-space: nowrap;
    line-height: 20px;
    border: 1px solid $black;
    padding: 8px 30px;
    display: inline-block;

    &:hover {
        background: $black;
        color: $white;
    }
}

// PDP Recently viewed products
.last-visited-title {
    @extend %h3;
    margin: 0 0 18px;
}

.last-visited-list-wrapper {
    margin: 70px 20px;

    @include media('>=tablet') {
        margin: 75px 32px;
    }

    .last-visited-tile-wrapper {
        padding: 0 6px 20px;
    }

    .last-visited-view-more {
        display: block;
        max-width: 250px;
        margin: 20px auto 0;
    }
}

.last-visited-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -6px;
}

.pdp-detail-variations {
    .attribute-color {
        margin: 10px 15px;

        @include media('>=tablet') {
            margin: 0 32px 10px;
        }

        @include media('>=desktop') {
            margin: 0 0 30px;
        }
    }
}

// PDP Thumbnail slider
.pdp-thumbnail-slider {
    width: 69px;
    display: none;

    @include media('>=desktop') {
        display: flex;
        flex-wrap: wrap;
    }

    @include media('>=desktopLarge') {
        width: 97px;
    }

    @include media('>=desktopXL') {
        width: 144px;
    }

    .pdp-thumbnail-slide {
        width: 100%;
        height: 104px;
        margin: 0 0 8px;
        cursor: pointer;
        overflow: hidden;

        @include media('>=desktopLarge') {
            height: 147px;
        }

        @include media('>=desktopXL') {
            height: 218px;
        }
    }

    .slick-slide {
        opacity: 0.5;

        &.slick-current {
            border-color: $black;
            opacity: 1;
        }
    }

    .slick-list {
        order: -1;
    }

    .slick-arrow {
        margin: 0;
        width: 50%;
        height: auto;
        text-align: center;
        margin-top: 22px;

        &::before {
            width: 6px;
            height: 6px;
            display: inline-block;
            transform-origin: center;
            border-width: 1px 0 0 1px;
        }

        &.slick-next {
            transform: translateY(-50%);
        }
    }
}

// price on PDP desktop
.product-detail-price-desktop {
    margin: 0 0 45px;

    .price-value-text {
        @include media('>=desktop') {
            display: block;
            text-align: left;
        }
    }
}

.product-detail-price {
    @extend %subtitle1;
    color: $dark-grey2;

    // Specific display on discount price on PDP
    /*
    &.promotional-price {
        .price-sales {
            color: $light-red2;
        }
    }
    */

    .store-availability-wrapper & {
        text-align: center;

        @include media('>=desktop') {
            text-align: left;
        }
    }
}

.product-abovefold-wrapper {
    position: relative;
    padding: 0 0 12px;
    flex-flow: row wrap;

    @include media('>=desktop') {
        padding: 0 32px;
        justify-content: space-between;
        align-items: flex-start;
        margin: 20px auto;
    }

    @include media('>=desktopLarge') {
        padding: 0 136px 0 38px;
    }

    @include media('>=desktopXL') {
        padding: 0 189px 0 32px;
    }
}

.pdp-tabs {
    position: relative;
    overflow: hidden;

    @include media('>=desktop') {
        border: none;
    }
}

.pdpdetails-additionnal {
    margin-top: 10px;
    border: 1px solid $black;
    padding: 15px 30px;

    @include media('>=desktop') {
        margin-top: 0;
    }
}

.product-detail-images-wrapper {
    width: 100%;

    @include media('>=desktop') {
        margin: 0 0 0 12px;
        width: calc(100% - 69px - 12px);
    }

    @include media('>=desktopLarge') {
        width: calc(100% - 97px - 12px);
    }

    @include media('>=desktopXL') {
        width: calc(100% - 144px - 12px);
    }
}

.product-primary-image-wrapper {
    width: 100%;
    cursor: zoom-in;
    position: relative;

    @include media('>=desktop') {
        .mixmatch-abovefold-wrapper & {
            width: 100%;
        }
    }

    .slick-arrow {
        width: 44px;
        height: 44px;
        padding: 5px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 9;
        background: $white;
        border-radius: 50%;

        &::before {
            border-width: 1px 0 0 1px;
            width: 5px;
            height: 5px;
        }
    }

    .slick-prev {
        left: 16px;
    }

    .slick-next {
        right: 16px;
    }
}

.productimage-wrapper {
    width: 100%;
    height: 100%;
    align-items: flex-start;
    align-content: center;
    flex-wrap: nowrap;
}

// PDP product form
.product-detail {
    position: relative;

    .gl_available-for-family {
        position: static;
        width: 60%;
        margin: 0 auto 20px auto;
        display: block;
    }
}

.product-detail-container {
    position: relative;
    background: $white;

    @include media('>=desktopLarge') {
        padding-top: 166px;
    }

    @include media('>=desktopXL') {
        padding-top: 53px;
    }

    .cross-sell-pdp & {
        display: flex;
        flex-direction: column;
        padding: 0;
        width: 100%;
        max-width: 100%;
        min-height: 100%;
        border: 0;

        @include media('>=desktop') {
            min-height: 600px;
        }
    }

    .pdp-in-quickview & {
        width: 100%;

        @include media('>=desktopLarge') {
            padding-top: 0;
        }
    }
}

.ecofriendly-tooltip-icon {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 3;
    cursor: pointer;
    width: 50px;
    height: 50px;

    @include media('>=desktop') {
        top: 10px;
        left: 96px;
    }

    @include media('>=desktopLarge') {
        left: 126px;
    }

    @include media('>=desktopXL') {
        left: 176px;
    }

    &:focus {
        outline: none;

        .ecofriendlysvg {
            outline: auto;
            outline-color: $blue-hover;
        }
    }
}

// Product set styles
.productset-wrapper {
    padding: 10px 0;
}

.productset-description {
    background-color: $white;
    border: 10px solid $black;
    outline: 10px solid $white;
    border-image-slice: 10 !important;
    padding: vw(95px) vw(25px);
    text-align: center;
    width: 100%;

    @include media('>=tablet') {
        padding: 25px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: stretch;
        align-content: center;
    }
}


.productset-shop-look {
    @extend %h2;
}

.product-set-separator {
    display: none;
}

.product-detail-model {
    @extend %ui4;
    margin: 15px 0;
    text-align: center;
}

.productset-selectlook {
    @extend %subtitle1;
    text-align: center;
    padding: 0 20px;
}

.productset-item-detail {
    display: block;
    padding: 0 20px 20px;

    @include media('>=tablet') {
        padding: 0 32px 32px;
    }

    @include media('>=desktop') {
        width: auto;
        padding: 0;
        margin: auto;
    }
}

.productset-product-detail-container {
    position: relative;
    padding: 20px;
    background: $white;
    border: 1px solid $black;

    @include media('>=desktop') {
        max-width: 380px;
        padding: 20px 32px;
    }
}


.productset-content-wrapper {
    margin-top: 20px;
    padding: 0 20px;
    flex-flow: row wrap;
    justify-content: center;

    @include media('>=tablet') {
        flex-flow: row nowrap;
        margin: 60px 0 0;
        padding: 30px 22px;
    }
}

.pdp-ps-image-slider-wrapper {
    margin: 20px auto;

    @include media('<desktop') {
        .slick-dots {
            margin-top: 20px;
        }
    }

    @include media('>=desktop') {
        margin: 0;

        .pdp-ps-image-slider {
            position: relative;
        }

        .slick-arrow {
            width: 44px;
            height: 44px;
            border-radius: 50%;
            background-color: $white;
            position: absolute;
            top: 50%;
            margin-top: -22px;
            z-index: 66;

            &::before {
                width: 7px;
                height: 7px;
            }
        }

        .slick-prev {
            left: 20px;
        }

        .slick-next {
            right: 20px;
        }
    }
}


.productset-description-wrapper,
.productset-main-image-wrapper {
    @include media('>=tablet') {
        width: 50%;
        padding: 0 10px;
    }
}


.productset-main-image-wrapper {
    margin-bottom: 30px;

    @include media('>=tablet') {
        margin-bottom: 0;
    }
}


.productset-description-wrapper {
    @include media('>=tablet') {
        display: flex;
    }
}


.productset-item-content,
.mixmatch-abovefold-wrapper {
    .product-image-container {
        @include media('>=desktop') {
            flex: 0 0 50%;
            width: 50%;
            max-width: 50%;
        }

        @include media('>=desktopLarge') {
            flex: 0 0 65%;
            width: 65%;
            max-width: 65%;
        }
    }
}


.pdp-ps-image-wrapper {
    text-align: center;
}

.productset-item-content {
    background: $grey-bg;

    @include media('>=desktop') {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-flow: row nowrap;
        padding: 32px;
    }
}

// new classes
.blog-pdp-category-section {
    margin: 25px 20px;

    @include media('>=tablet') {
        display: flex;
        justify-content: center;
    }

    @include media('>=tablet', '<desktop') {
        margin: 50px 26px 60px;
    }

    @include media('>=desktop', '<desktopXL') {
        margin: 60px 26px 90px;
    }

    @include media('>=desktopXL', '<desktopXL') {
        margin: 60px 26px 100px;
    }

    @include media('>=desktopXL') {
        margin: 70px 26px 120px;
    }

    // small blog aricles on pdp
    .small-blog-article-wrapper {
        @include media('>=tablet') {
            padding: 0 6px;
        }

        @include media('>=tablet', '<desktop') {
            flex: 0 0 50%;
            max-width: 50%;
        }

        @include media('>=desktop') {
            flex: 0 0 33.33%;
            max-width: 33.33%;
        }
    }
}

.productimage-slides-number {
    position: absolute;
    left: 20px;
    top: 22px;
    z-index: 1;

    @include media('>=tablet') {
        left: 32px;
        top: 30px;
    }

    @include media('>=desktop') {
        display: none;
    }

    .pdp-in-quickview & {
        display: none;
    }
}

.variation-select-title {
    display: none;
}

.pdp-block-gift {
    margin: 30px 0 40px;

    @include media('>=tablet') {
        margin: 36px 0 30px;
    }

    @include media('>=desktop') {
        margin: 62px auto 65px;
    }
}

.pdp-giftblock-content {
    text-align: left;
    padding: 0 20px 35px;
    flex-flow: column wrap;
    justify-content: start;
    align-self: start;
    flex: 0 1 100%;
    max-width: 100%;
    @extend %quote1;

    @include media('>=desktop') {
        flex: 0 1 45%;
        padding: 0 5% 0 26px;
    }
}

.pdp-giftblock-image {
    flex: 0 1 100%;
    padding: 0 20px;

    @include media('>=desktop') {
        flex: 0 1 55%;
        padding: 0 26px 0 0;
    }
}

.pdp-giftblock {
    position: relative;
    flex-flow: row wrap;
    margin: 40px 0 0;

    @include media('>=desktop') {
        flex-flow: row nowrap;
    }
}

.pdp-giftblock-link {
    text-decoration: none;

    &:hover {
        color: $black;
    }
}

.pdp-in-quickview {
    padding: 0 0 115px;

    &.product-abovefold-wrapper {
        @include media('>=desktop') {
            margin: 40px 0 10px;
            padding: 0 35px;
        }

        @include media('>=desktopLarge') {
            align-items: center;
        }
    }

    .product-col-1 {
        margin: 0 0 15px;
    }

    .product-dialog-footer {
        padding: 20px 15px 15px;

        @include media('>=desktop') {
            padding: 20px 0 0;
        }
    }

    .product-primary-image-wrapper {
        @include media('>=desktop') {
            width: 500px;
        }

        @include media('>=desktopXL') {
            width: 450px;
        }
    }
}

.product-col-2 {
    width: 100%;
    order: 4;

    @include media('>=desktop') {
        order: 2;
        width: 40%;
        padding: 0 0 0 50px;

        .pdp-in-quickview & {
            max-width: 32%;
            padding: 0;
        }
    }
}

.quickview-dialog {
    position: fixed !important;

    @include media ('<desktop') {
        top: 0 !important;
        left: 0 !important;
        height: 100% !important;
        width: 100% !important;
    }

    @include media('>=desktop') {
        max-width: 1000px;
        left: 50% !important;
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
    }

    .ui-dialog-titlebar-close {
        display: none;
    }
}

// deleted classes (commented, just the classname for trace)
/*
.gshipping-icon-container
.gshipping-icon
.product-detail-model-label
.title-size-label
*/

// =================================
// VILEBREQUIN GRAPHICAL CHART
// =================================
// PDP products navigation
// ECM-633 - Remove Next item/Previous item block from PDPs
// removed classes:
//.pt_product-details .breadcrumb, .product-nav-container, .product-previous, .product-next, .product-previous.divided, .product-nav-prev, .product-nav-next, .product-nav-separator

.product-nav-back {
    position: absolute;
    cursor: pointer;
    z-index: 1;
    top: 10px;
    left: 10px;
    border: 1px solid $blue-light;
    line-height: 40px;
    padding: 0 15px;
    text-align: center;
    background: $white;

    .breadcrumbs-wrapper & {
        border: 0;
        padding: 0;
        position: static;
        text-align: left;
        line-height: normal;
    }
}

.product-nav-back-link {
    font-size: 12px;
    text-decoration: none;

    &::before {
        content: '<';
        display: inline-block;
        margin: 0 5px 0 0;
        transform: scaleY(1.3);
    }
}

// Product form sticky: on mobile pdp & cross-sell dialog
.page-with-sticky-pdp-form {
    padding-bottom: 115px;

    @include media('>=desktop') {
        padding-bottom: 0;
    }
}

// Loader container
.product-content-updates {
    position: relative;
}

.pdp-progress-loader {
    .turtlesvg {
        width: 24px;
        height: 24px;
        margin: 7px;
    }
}

.attribute {
    .store-availability-wrapper & {
        float: left;
        height: 100%;
        position: relative;
    }

    .store-availability-wrapper & {
        width: 100%;
        border-bottom: 1px solid $light-grey;

        @include media('>=desktop') {
            width: 50%;
            border-bottom: 0;
        }
    }
}

.attribute-size {
    @include media('>=desktop') {
        margin: 0 0 10px;
    }

    .store-availability-wrapper & {
        margin: 0 0 0 -1px;
    }

    .store-availability-wrapper & {
        .variation-select-title {
            border-right: 0;
        }
    }
}

.product-variation-list {
    .store-availability-wrapper & {
        display: none;
        position: absolute;
        padding: 10px 3px 0 10px;
        background: $white;

        @include media('>=desktop') {
            border: 1px solid $light-grey;
            border-width: 0 1px 1px;
        }
    }

    .store-availability-wrapper & {
        position: relative;
        padding: vw(50px) vw(35px);

        @include media('>=tablet') {
            padding: 50px 35px;
        }

        @include media('>=desktop') {
            position: absolute;
            padding: 10px 3px 0 10px;
        }
    }
}

.variation-title-block {
    .store-availability-wrapper & {
        display: block;
    }

    .store-availability-wrapper & {
        line-height: 50px;

        @include media('>=desktop') {
            line-height: normal;
        }
    }
}

.product-sticky-col1 {
    float: left;
    width: 40%;
    padding: 20px 5px 0 20px;

    .store-availability-wrapper & {
        width: 100%;
        padding: vw(60px) vw(60px) 0;

        @include media('>=tablet') {
            padding: 60px 60px 0;
        }

        @include media('>=desktop') {
            padding: 0;
            height: 75px;
            align-items: center;
            width: 70%;
        }
    }
}

.product-sticky-col2 {
    float: left;
    width: 30%;

    .store-availability-wrapper & {
        float: right;
        width: 100%;
        margin-top: vw(70px);

        @include media('>=tablet') {
            margin-top: 35px;
        }

        @include media('>=desktop') {
            margin-top: 0;
            width: 30%;
        }
    }
}

.product-sticky-col3 {
    float: right;
    width: 30%;
    padding: 20px 20px 0;
}

.product-col-1 {
    position: relative;
    width: 100%;
    align-items: center;
    align-content: center;
    order: 1;

    @include media('>=desktop') {
        width: 60%;
        padding: 0 50px 0 0;

        .pdp-in-quickview & {
            max-width: 68%;
            padding: 0;
        }
    }
}

.thumbnailvideo {
    text-align: center;
}

.productthumbnail {
    height: 100%;
    width: auto;
    min-width: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background-color: $light-brown;
}

// PDP product primary image
.tap-zoom-wrapper {
    display: none;
    position: absolute;
    pointer-events: none;
    top: 50%;
    left: 50%;
    z-index: 1;

    @include media('>=desktop') {
        display: none;
    }
}

.tap-zoom {
    @include mobile-first-icon(tapzoom);
    @include viewport-icon('tapzoom_big', '>=tablet', '<desktop');
}

.pdpzoom-trigger-wrapper {
    display: none;

    @include media('>=desktop') {
        display: block;
        cursor: pointer;
        position: absolute;
        bottom: 10px;
        right: 10px;
    }

    .pdpzoom-trigger {
        transform-origin: center;
    }
}

.pdpzoom-trigger {
    @include mobile-first-icon(pdp_zoom);
}

.product-primary-image {
    display: none;
    position: relative;
    width: 100%;
    height: 100%;
    align-content: center;
    align-items: center;
    flex-flow: row wrap;
    background: $light-brown;

    &.pdp-main-image {
        margin-bottom: 16px;
    }
}

.secondary-image {
    background: $light-brown;
}

.iframe-fluid-container {
    position: relative;
    padding-bottom: 100%;
    width: 100%;
    height: 0;
    overflow: hidden;
}

.product-image-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.zoom-loader {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -75px 0 0 -75px;
    width: 150px;
}

.product-info {
    margin: 0 0 70px;
}

.pdp-tabs-img {
    width: 7%;
}

.product-detail-description {
    color: $blue;
    text-align: center;
    margin: vw(20px) 0 vw(10px);
    font-size: vw(36px);

    @include media('>=tablet') {
        font-size: 23px;
    }

    @include media('>=desktop') {
        margin: 20px 0 10px;
        font-size: 18px;
    }
}

.product-detail-separator {
    margin: 8px 0;
}

.product-detail-mobile-promotion {
    margin: 0 15px 15px;

    @include media('>=tablet') {
        margin: 0 35px 15px;
    }

    @include media('>=desktop') {
        display: none;
    }
}

.price-value-text {
    .store-availability-wrapper & {
        display: inline-block;
    }
}

.productform-link-size-guide {
    @include media('>=tablet') {
        min-width: 220px;
    }

    @include media('>=desktop') {
        min-width: auto;
    }
}

.product-detail-reviews {
    .product-detail-reviews-anchor {
        display: block;
    }

    .netreviews_review_rate_and_stars {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        height: auto;
        padding: 15px 0;
        top: 0;
        cursor: pointer;

        @include media('>=desktop') {
            justify-content: space-between;
            border-top: 1px solid $light-grey;
            border-bottom: 1px solid $light-grey;
            padding: 7px 0;
            margin: 0 0 16px;
        }

        @include media('>=desktopLarge') {
            margin: 0 0 12px;
        }
    }

    .netreviews_bg_stars,
    .netreviews_review_rate_and_stars {
        div:not(.netreviews_reviews_rate) {
            position: static;
            font-size: 16px;
        }

        // stars imposition
        div:not(.netreviews_reviews_rate):nth-child(2) {
            margin: 0 0 0 -80px;

            @include media('>=desktop') {
                position: absolute;
                right: 0;
                margin: 0;
            }
        }

        .nr-icon {
            margin-top: 2px;
        }
    }

    .netreviews_reviews_rate {
        position: static !important;
        padding: 0 0 0 12px;
        text-transform: none;
        text-decoration: underline;
        font: 12px / 14px $Arial !important;

        @include media('>=desktop') {
            padding: 0;
            order: -1;
        }
    }

    .netreviews_reviews_rate_empty {
        margin: 0 0 0 80px;

        @include media('>=desktop') {
            margin: 0;
        }
    }
}

.product-review {
    color: $blue;
}

.product-rating-label {
    text-align: center;
    font-family: $MontserratBold;
    text-transform: uppercase;
    font-size: vw(24px);
    line-height: vw(26px);

    @include media('>=desktop') {
        font-size: 12px;
        line-height: 16px;
    }
}

.product-detail-variations {
    clear: both;
    height: 100%;

    .variation-attribute {
        display: inline-block;
        vertical-align: top;
        margin: 0 15px 10px 0;
    }

    .variation-attribute:last-child {
        margin: 0 0 10px;
    }
}

.attribute-color {
    // fix for inner scroll content in the fieldset
    .color-fieldset {
        min-width: 100%;
        max-width: 100%;
    }

    .swatch-list {
        max-width: 100%;
        overflow: auto;
        display: flex;
        flex-flow: row nowrap;

        @include media('>=desktop') {
            display: block;
        }
    }
}

.title-size-value {
    display: none;

    &.show-title {
        display: inline-block;
    }

    .store-availability-wrapper & {
        display: none;

        @include media('>=desktop') {
            display: inline-block;
        }
    }
}

.title-swatch-value {
    .store-availability-wrapper & {
        display: none;

        @include media('>=desktop') {
            display: inline-block;
        }
    }
}

.product-variations,
.product-attributes-list {
    height: 100%;
}

.product-attributes-list {
    .store-availability-wrapper & {
        border-top: 1px solid $light-grey;

        @include media('>=desktop') {
            border-top: 0;
        }
    }
}

.title-size-label,
.title-size-value {
    display: none;
}

.size-variation-find {
    text-transform: none;
    font-size: vw(24px);
    padding-left: 10px;
    text-decoration: underline;
    color: $blue;
    cursor: pointer;

    .store-availability-wrapper & {
        display: none;
    }

    @include media('>=tablet') {
        font-size: 20px;
    }

    @include media('>=desktop') {
        font-size: 12px;
    }
}

.duties-and-taxes {
    display: block;
    text-align: center;
    padding: 0 0 6px;
    font-size: vw(24px);

    .store-availability-wrapper & {
        display: none;
    }

    @include media('>=tablet') {
        font-size: 20px;
    }

    @include media('>=desktop') {
        font-size: 12px;
    }

    &.mini-cart-subtotals-label {
        text-align: left;
        padding: 5px 0 0;
    }

    .cross-sell-pdp & {
        font-size: 12px;
    }
}

.availability-web {
    font-size: vw(24px);

    @include media('>=tablet') {
        font-size: 20px;
    }

    @include media('>=desktop') {
        font-size: 12px;
    }
}

.tooltip {
    .pdp-in-quickview & {
        display: none;
    }
}

.tooltip-content {
    display: none;
}

.back-order-section {
    margin: 20px 0 0;
}

// Link is on QV (modify in cart) & Shop By Look page
.view-product-detail {
    display: block;
    text-align: center;
    font-size: 12px;
    margin: 20px 0 0;

    @include media('>=desktop') {
        margin: 40px 0 0;
    }
}

//PDP below the fold

// PDP tabs / accordion
.pdpdetails-tab-heading {
    font-size: vw(28px);
    text-align: center;

    @include media('>=desktop') {
        font-size: 14px;
    }
}

.pdp-tab-list {
    padding: 50px 0 0;
    text-align: center;
}

.pdp-tab-list-item {
    display: inline-block;
    vertical-align: middle;
    margin: 0 35px;

    &.ui-state-active {
        .pdp-tab-list-link::after {
            visibility: visible;
        }
    }
}

.product-description-content-list {
    margin: vw(50px) 0;
    list-style-type: disc;
    padding: 0 0 0 14px;

    @include media('>=desktop') {
        margin: 0;
    }
}

a.pdp-tab-list-link {
    text-transform: capitalize;
    font-size: 20px;
}

.product-description-tab,
.care-instructions-tab {
    font-size: vw(24px);
    color: $blue;

    @include media('>=desktop') {
        font-size: 12px;
    }
}

.product-description-tab-column,
.care-instructions-tab-column {
    margin-bottom: 20px;
    padding: 0 3.8%;

    @include media('>=desktop') {
        margin-bottom: 30px;
        padding: 0;
    }

    p:first-child {
        margin-top: 0;
    }
}

.pdpdetails-additionnal-label {
    text-transform: uppercase;
}

.pdpdetails-additionnal-model {
    text-transform: capitalize;
}

// PDP giftblock slider section

.pdp-block-slider {
    margin: 30px 0 40px;
    padding: 0 3.8%;
    font-size: vw(24px);
    color: $blue;

    @include media('>=tablet') {
        margin: 36px 0 30px;
    }

    @include media('>=desktop') {
        margin: 62px auto 65px;
        max-width: 1024px;
        padding: 0;
        font-size: 12px;
    }

    @include media('>=desktopLarge') {
        max-width: 1255px;
    }
}

.giftblock-slide {
    position: relative;
    flex-flow: row wrap;
    margin: 40px 0 0;
    background-color: $giftblock-grey;

    @include media('>=desktop') {
        flex-flow: row nowrap;
    }
}

.pdp-block-dots {
    margin: vw(90px) 0 0;

    @include media('>=desktop') {
        margin: 0;
    }

    @include media('>=desktopLarge') {
        margin: 45px 0 0;
    }
}

li.pdp-block-dots-item {
    margin-right: vw(45px);

    @include media('>=desktop') {
        width: 13px;
        min-width: 13px;
        height: 13px;
        margin-right: 15px;
    }

    &:last-child {
        margin-right: 0;
    }
}

// PDP craftmanship slider section
.craftmanship-slider {
    margin: vw(20px) 0 0;

    @include media('>=desktop') {
        margin: 10px 0 0;
    }
}

.pdp-craftmanship-slider-text {
    text-align: center;
    background: $grey-bg;
    padding: 5%;
    font-size: vw(24px);
    margin: 0 0 vw(20px);
    justify-content: center;
    flex: 1;

    @include media('>=desktop') {
        margin: 0;
        font-size: 12px;
        padding: 0 5%;
    }
}

.craftmanship-section-title {
    font-size: vw(36px);
    text-transform: capitalize;

    @include media('>=desktop') {
        font-size: 18px;
    }
}

.pdp-craftmanship-slider-row {
    flex-flow: column wrap;
}

.craftmanship-slider-col1,
.craftmanship-slider-col2 {
    flex: 1 0 100%;

    @include media('>=desktop') {
        flex: 1;
    }
}

.pdp-craftmanship-slider-imagetop {
    width: 100%;
    margin: 0 0 vw(20px);

    @include media('>=desktop') {
        margin: 0 0 10px;
    }
}

.craftmanship-slider-col1 {
    margin: 0 10px 0 0;
}

.craftmanship-section-separator {
    margin: 10px 0 20px;
}

// Product carousel deviation on PDP
.product-carousel-component.pdp-product-carousel-component {
    margin: 30px 0 40px;

    @include media('>=tablet') {
        margin: 36px 0 30px;
    }

    @include media('>=desktop') {
        margin: 62px auto 65px;
        max-width: 1024px;
    }

    @include media('>=desktopLarge') {
        max-width: 1255px;
    }
}

.pdp-predictive-carousel-wrapper {
    margin: 70px 20px;

    @include media('>=tablet') {
        margin: 75px 32px;
    }

    .predictive-tile-wrapper {
        padding: 0 6px;
    }
}

.pdp-predictive-title-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0 0 18px;
}

.pdp-predictive-title {
    @extend %h3;
}

.pdp-predictive-arrows-wrapper {
    @include custom-arrows();
}

.pdp-predictive-carousel {
    margin: 0 -6px;
}

.pdp-predictive-carousel:not(.slick-initialized) {
    display: flex;
}

.pdplook-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    width: 34px;
    height: 34px;
    background: $grey-bg;
    border-radius: 50%;
    text-align: center;

    .svgchevron {
        width: 10px;
        padding-top: 25%;
    }
}

.pdplook-mainimage {
    margin: 0 0 30px;

    @include media('>=tablet') {
        width: 50%;
        margin: 0 5px 30px 0;
    }

    @include media('>=desktop') {
        width: auto;
        flex-grow: 1;
    }

    @include media('>=desktopLarge') {
        padding: 0 175px 0 5px;
    }
}

.no-family-badge {
    .family-badge {
        display: none;
    }
}

// predictive intelligence
div[id^="cq_recomm_slot-"] {
    width: 100%;
}

.pdplook-complete-image {
    width: 100%;
    margin: vw(20px) 0 0;
    order: 1;

    @include media('>=desktop') {
        margin: 50px 0 0;
        order: 0;
    }
}

.pdplook-badge {
    font-family: $MontserratBold;
    color: $white;
    font-size: 15px;
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    background-color: $blue;
    border-radius: 100%;
    position: relative;
    margin: vw(70px) auto vw(40px);

    @include media('>=desktop') {
        margin: 0 auto;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: 2px;
        border: 1px solid $white;
        border-radius: 100%;
    }
}

#writeReviewDialog {
    width: 100% !important;
    height: 100vh !important;
    min-height: 100vh !important;
    background-color: $white;
}

// Quickview (use case: Modify in the cart)
#QuickViewDialog {
    width: 100vw !important;
    height: 100vh !important;
    min-height: 100vh !important;
    background-color: $white;
    overflow: auto;

    @include media('>=desktop') {
        width: 1000px !important;
        height: auto !important;
        min-height: auto !important;
    }
}

.cross-sell-active {
    // no scroll on page body
    @include media('<desktop') {
        overflow: hidden;
        position: fixed;
    }
}

.cross-sell-dialog {
    .cross-sell-pdp-content {
        flex-grow: 1;
        flex-direction: column;
        min-height: 100%;

        @include media('>=desktop') {
            min-height: 600px;
            height: 600px;
        }
    }

    .product-image-container {
        display: flex;
        flex-grow: 1;
        background: $grey-bg;
        padding: 0 0 115px; // space for sticky bottom form
    }

    .productimage-wrapper {
        margin: auto 0;
    }
}

.monogram-dialog {
    @include media('>=desktop') {
        max-width: 1000px;
    }

    @include media('>=desktopLarge') {
        max-width: 1200px;
    }
}

.quickview-dialog,
.cross-sell-dialog,
.write-review-dialog {
    background: none;
    padding: 0;
}

.write-review-dialog {
    .ui-dialog-titlebar {
        height: 40px;
        background: $grey-bg;
    }
}

.monogram-dialog {
    border: 1px solid $blue;
}

.quickview-iframe {
    border: 0;
    width: 100%;
    height: 100%;
    min-width: 1024px;
}

.write-review-iframe {
    border: 0;
    width: 100%;
    height: 100%;
}

.cross-sell-iframe {
    border: 0;
    width: 100%;
    height: 100%;
    max-width: 480px !important;
}

// PDP Reviews tab
.reviews-tab {
    // ERV-442 Giant top padding for Reviews section on tablet - Digital team asked for no space for this tab
    padding: 0;

    .tab-content {
        padding-top: 4px;
        padding-bottom: 30px;
    }

    #netreviews_reviews_tab {
        font-family: $Arial;
    }

    .netreviews_reviews_rate,
    .netreviews_certification,
    .netreviews_certification:active,
    .netreviews_certification:visited,
    span[id^=netreviews_rate_total],
    .netreviews_filtering_section,
    .netreviews_subtitle {
        color: $blue;
    }
}

.write-review-button-wrapper {
    text-align: center;

    @include media('>=desktop') {
        height: 42px;
        margin: 0 0 30px;
    }
}

.write-review-button {
    max-width: 320px;
    margin: 0 auto;
}

.netreviews_filtering_section select {
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    appearance: menulist;
}

// End: PDP Reviews tab

// ENF-5 - Multiple bonus products
.bonus-anchor {
    cursor: default;
}

.pdp-unavailable-size-block {
    text-align: center;
    margin: 20px 15px 0;

    @include media('>=desktop') {
        margin: 20px 0 0;
    }
}

.pdp-unavailable-size-description,
.pdp-unavailable-size-option {
    font-size: vw(24px);
    color: $blue;

    @include media('>=tablet') {
        font-size: 20px;
    }

    @include media('>=desktop') {
        font-size: 12px;
    }
}

.pdp-unavailable-size-option {
    margin: 20px 0 10px;
}

// =================================
//    PRODUCT SET DETAIL PAGE
// =================================
.productset-description-inner {
    .gl_wave-separator {
        margin-bottom: 40px;
    }
}

.productset-name {
    text-align: center;
    text-transform: none;
    font-size: vw(48px);
    margin-bottom: 10px;

    @include media('>=tablet') {
        font-size: 24px;
    }
}

.productset-shopall-wrapper {
    margin: 20px 0;
    text-align: center;

    .gl_wave-separator {
        margin: 15px 0;
    }

    .pdpForm {
        margin: 0;
    }

    @include media('>=tablet') {
        margin: 60px 0;
    }
}

.add-all-to-cart {
    margin-top: 25px;
    width: auto;

    &.gl_button {
        padding: 0 60px;
        font-size: 13px;
    }
}

.productset-promotions {
    text-align: center;

    .promotion {
        margin: 20px 0;
    }
}

.productset-details {
    margin: 10px 0 40px;

    .product-item-name {
        text-decoration: none;
    }

    .add-sub-product {
        margin-top: 20px;
    }

    .product-detail-name {
        font-size: 18px;
        line-height: 18px;
        text-align: center;
    }
}

.productset-item {
    margin-top: 30px;
}

.productset-item-content {
    background: $grey-bg;

    @include media('>=desktop') {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-flow: row nowrap;
    }
}

.product-image-container {
    position: relative;
}

.productset-item-number {
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 10px;

    @include media('>=tablet') {
        text-align: right;
        padding-right: 15px;
    }
}

.pdp-ps-image {
    width: auto;
    margin: 0 auto;
    max-width: 100%;
}

// =================================
//    PRODUCT SET DETAIL PAGE END
// =================================

.product-pdp-button {
    margin: 10px 0 0;
}

.product-ufd-button {
    @include media('>=desktop') {
        margin: 10px 0 0;
    }
}

.product-pdp-fake-link-btn {
    display: block;
    text-align: center;
    width: 100%;
    margin: 10px 0 0;
    font: vw(24px) $Arial;

    @include media('>=tablet') {
        font-size: 20px;
    }

    @include media('>=desktop') {
        font-size: 12px;
    }
}

.pdp-preorder-msg {
    text-align: right;
    font: 12px/12px $Arial;
    color: $royal-blue;
    margin: 0 0 10px;

    @include media('>=desktop') {
        text-align: center;
    }
}

.pdp-preorder-msg.cross-sell-preorder-msg {
    text-align: right;
}

.pdp-preorder-msg.product-set-preorder-msg {
    text-align: center;
}

.pdp-not-possible-msg {
    text-align: center;
    margin: 10px 15px 0;
    font: 10px $Arial;
    color: $blue;

    @include media('>=tablet') {
        font-size: 12px;
    }

    @include media('>=desktop') {
        font-size: 10px;
        margin: 0 0 10px;
    }
}

.product-add-to-cart {
    .instorecollect-btn {
        height: 44px;
        font-size: 10px;
        line-height: 44px;
    }
}

// Cross-sell button
.cross-sell-wrapper {
    margin: 20px 0 10px;
    padding: 0 20px;
    text-align: center;

    .pt_product-iframe & {
        display: none;
    }

    @include media('>=375px', '<desktop') {
        padding: 0 30px;
    }

    @include media('>=desktop') {
        border: 1px solid $light-grey;
        padding: 10px 30px;
        margin: 20px 0;
    }
}

.cross-sell-link {
    text-decoration: none;
    color: $blue;
    cursor: pointer;
    display: inline-block;
}

.cross-sell-block {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.cross-sell-image {
    width: 97px;
    height: 97px;
    flex: 0 0 97px;
}

.cross-sell-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 20px;
    text-align: left;
}

.cross-sell-title {
    font: 16px / 22px $Arial;
    font-weight: bold;
    max-height: 22px;
    overflow: hidden;
}

.cross-sell-name {
    font: 12px / 16px $Arial;
    max-width: 180px;
    padding-top: 4px;
}

// Cross-sell panel
.cross-sell-pdp {
    display: flex;
    max-width: 100vw;
    min-height: 100%;
    background-color: $white;

    .product-primary-image-wrapper {
        display: flex;
        align-items: center;
        height: 100%;

        @include media('>=desktop') {
            width: 600px;
            margin: 0;
        }

        .slick-prev {
            left: 10px;

            @include media('>=tablet') {
                left: 25px;
            }

            @include media('>=desktop') {
                left: 15px;
            }
        }

        .slick-next {
            right: 10px;

            @include media('>=tablet') {
                right: 25px;
            }

            @include media('>=desktop') {
                right: 15px;
            }
        }

        img {
            margin: 0 auto;

            @include media('>=tablet') {
                width: 600px;
            }

            @include media('>=desktop') {
                width: 370px;
            }
        }
    }

    .product-primary-image {
        padding: 30px;
        text-align: center;

        &.gl_flex {
            justify-content: center;
        }

        @include media('>=tablet') {
            padding: 30px 70px;
        }
    }

    .product-attributes-list {
        @include media('>=480px') {
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: flex-start;
        }
    }

    .attribute-color {
        @include media('>=480px') {
            padding-right: 10px;
            flex: 0 0 40%;
        }
    }

    .attribute-size {
        @include media('>=480px') {
            padding-left: 10px;
            flex: 0 0 60%;
        }
    }

    .add-to-cart.gl_button {
        font-size: 11px;
    }

    .gl_size-button {
        width: 36px;
        height: 36px;
        line-height: 36px;
        font-size: 10px;
    }

    .gl_color-button {
        width: 35px;
        height: 35px;
        max-width: 35px;
        max-height: 35px;
        padding: 3px;
    }

    .slick-dots li {
        width: 16px;
        min-width: 16px;
        height: 16px;
        border-radius: 100%;
        margin-right: 10px;
        margin-bottom: 10px;

        @include media('>=480px') {
            width: 20px;
            min-width: 20px;
            height: 20px;
            margin-right: 15px;
            margin-bottom: 15px;
        }
    }

    .product-form-sticky {
        position: absolute;
    }
}

.cross-sell-pdp-header {
    position: relative;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $grey-bg;
}

.quickview-pdp-header {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $grey-bg;

    @include media('>=desktop') {
        display: block;
        border: 0;
    }
}

.cross-sell-style,
.quickview-pdp-name {
    padding: 15px 10px;
    flex-grow: 1;
    max-width: calc(100% - 48px);
}

.cross-sell-style {
    font: 16px / 19px $Arial;
    font-weight: bold;
    text-transform: none;
    height: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include media('>=tablet') {
        font-size: 18px;
        line-height: 22px;
        height: 52px;
    }

    @include media('>=desktop') {
        font-size: 16px;
        line-height: 19px;
        height: 49px;
    }
}

.cross-sell-pdp-close {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 1;
    width: 48px;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    border-left: 1px solid $grey-bg;
    cursor: pointer;

    .closesvg {
        width: 14px;
        height: 14px;

        .st0 {
            fill: $blue;
        }
    }
}

.quickview-pdp-close {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 1;
    width: 48px;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    border-left: 1px solid $grey-bg;
    cursor: pointer;

    .closesvg {
        width: 14px;
        height: 14px;

        .st0 {
            fill: $blue;
        }
    }

    @include media('>=desktop') {
        top: -40px;
        right: -35px;
        width: 40px;
        height: 40px;
        border: 0;
    }
}

.ecofriendlysvg {
    width: 50px;
    height: 50px;

    .st0 {
        fill: $ecofriendly-color1;
    }

    .st1 {
        fill: $ecofriendly-color2;
    }
}

.ecofriendly-tooltip {
    display: none;
    background-color: $blue;
    padding: 6px;
    color: $white;
    position: absolute;
    top: 1px;
    left: 14px;
    transform: translateY(-100%);
    font-size: 10px;
    max-width: calc(100% - 4px); // - 4px for left: 2px and keep 2px margin from viewport right border
    z-index: 20001; // hover header on mobile

    @include media('>=desktop') {
        top: 11px;
        left: 100px;
    }
}

.family-badge {
    .pdp-main & {
        top: 15px;
        right: 15px;

        @include media('>=desktop') {
            top: 10px;
            right: 20px;
        }
    }
}

.gshipping-block {
    margin: 0 0 40px 0;
}

.shipping-returnlimit-svg {
    .st0,
    .st1 {
        fill: $blue;
    }

    .st1 {
        stroke: $white;
    }

    .svg-text {
        font-family: $MontserratBold;
        fill: $white;
    }
}

.shipping-details-svg {
    .st0 {
        fill: $blue;
    }

    .st1 {
        fill: $white;
    }

    .st2 {
        stroke: $blue;
    }
}

.gshipping-delivery-separator {
    margin: vw(40px) 0 vw(70px);

    @include media('>=desktop') {
        margin: 20px 0 30px;
    }
}

// EPM-2 - Monogramming
.monogram-item {
    margin: 0 15px 10px;

    @include media('>=desktop') {
        margin: 0 auto 20px;
    }
}

.monogram-not-set {
    height: 44px;

    .monogram-add-btn {
        height: 44px;
        font-size: 10px;
        line-height: 44px;
    }

    .monogram-add-btn-price {
        font-family: $Arial;
    }
}

.monogram-set {
    position: relative;
    font-size: 14px;
    text-align: center;
    color: $blue;
    border: 1px solid $blue;
    padding: 10px 15px;
}

.monogram-set-label {
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 0 12px;
}

.monogram-set-value {
    margin: 10px 0 0;
    text-align: center;
}

.monogram-actions {
    display: flex;
    flex: row nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.monogram-action {
    color: $blue;
    text-decoration: underline;
    cursor: pointer;
    max-width: 50%;
}

.monogram-action-left {
    margin-right: 35px;

    @include media('>=desktop') {
        margin-right: 25px;
    }
}

.monogram-action-right {
    margin-left: 35px;

    @include media('>=desktop') {
        margin-left: 25px;
    }
}

.monogram-disclaimer {
    color: $red;
    font-size: 12px;
    margin: 10px 0;
}

.monogram-form-buttons,
.monogram-characters-inputs {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-flow: row wrap;
    margin: auto -6px;
}

.monogram-form-button {
    color: $blue;
    font-size: 16px;
    min-width: 42px;
    height: 42px;
    text-align: center;
    text-transform: uppercase;
    padding: 3px;
    background-color: $white;
    border: 1px solid $light-grey;
    cursor: pointer;
    text-decoration: none;
    margin: 6px;

    &:hover {
        border-color: $blue-hover;
        color: $white;
    }

    &.selected {
        border-color: $blue;
        color: $white;
    }

    .invalid & {
        border-color: $red;
        color: $red;
        outline-color: $red;
    }

    &.monogram-placement,
    &.monogram-font {
        &:hover {
            background-color: $blue-hover;
        }

        &.selected {
            background-color: $blue;
        }
    }

    &.monogram-placement {
        padding: 0;
        max-width: 50%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
    }

    &.monogram-font {
        padding: 3px 8px;
        line-height: 34px;
    }

    &.monogram-color {
        &:hover,
        &.selected {
            padding: 1px;
            border-width: 3px;
        }

        &.selected {
            outline: none;
        }
    }
}

.monogram-color-inner {
    width: 100%;
    height: 100%;
}

input[type=text].monogram-character-input {
    width: 42px;
    height: 42px;
    padding: 3px;
    background-color: $white;
    border: 1px solid $light-grey;
    text-align: center;
    color: $blue;
    font-size: 16px;
    line-height: 34px;
    margin: 6px;

    &.filled {
        border-color: $blue;
        background-color: $blue;
        color: $white;
    }

    &.invalid {
        border-color: $red !important;
        color: $red !important;
        outline-color: $red;
    }
}

.monogram-submit-wrapper {
    text-align: center;
}

.monogram-submit {
    @include media('>=480px') {
        max-width: 200px;
    }

    @include media('>=480px', '<desktop') {
        font-size: 12px;
    }
}

.monogram-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-flow: row wrap;
    padding: 0 10px;
    margin: 6px 4px;

    @include media('>=tablet') {
        padding: 0;
        margin: 3px 12px 12px;
    }
}

.monogram-header {
    flex: 0 0 100%;
    width: 100%;

    @include media('>=tablet') {
        padding: 0 0 0 60%;
    }
}

.monogram-rightcol {
    order: 1;
    flex: 0 0 100%;
    width: 100%;

    @include media('>=tablet') {
        order: 2;
        flex-basis: 40%;
        width: 40%;
        padding-left: 10px;
    }
}

.monogram-leftcol {
    order: 2;
    flex: 0 0 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-flow: row wrap;
    padding-top: 30px;

    @include media('>=tablet') {
        order: 1;
        flex-basis: 60%;
        flex-wrap: nowrap;
        width: 60%;
        padding-top: 34px;
        padding-right: 10px;
    }
}

.monogram-step + .monogram-step {
    margin-top: 20px;
}

.monogram-title {
    margin: 0;
    font-size: 16px;
    text-align: center;
    padding: 0 25px;

    @include media('>=tablet', '<desktop') {
        font-size: 18px;
        margin-top: 4px;
        padding: 0 25px 0 35px;
    }

    @include media('>=desktop') {
        font-size: 24px;
    }
}

.monogram-step-label {
    color: $blue;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 6px;
    margin-left: 20px;

    @include media('>=tablet') {
        margin-left: 0;
    }

    @include media('>=tablet', '<desktop') {
        font-size: 16px;
    }

    @include media('>=tablet') {
        font-size: 18px;
    }
}

.monogram-separator {
    text-align: center;
    margin: 10px auto 4px;

    .svgwave {
        transform: scale(0.5);
    }

    .svgwavepath {
        stroke: $blue;
    }
}

.monogram-thumbnails {
    order: 2;
    flex: 0 0 100%;
    width: 100%;
    padding: 10px 30px 0;

    @include media('<tablet') {
        position: relative;

        .slick-arrow {
            position: absolute;
            top: calc(50% - 25px);
            z-index: 2;
        }

        .slick-prev {
            left: -50px;
        }

        .slick-next {
            right: -50px;
        }
    }

    @include media('>=tablet') {
        order: 1;
        flex-basis: 100px;
        width: 100px;
        padding: 0 10px 0 0;
    }
}

.monogram-main-image-wrapper {
    order: 1;
    flex: 0 0 100%;
    width: 100%;
    padding-bottom: 10px;

    @include media('>=tablet') {
        order: 2;
        flex-basis: calc(100% - 100px);
        width: calc(100% - 100px);
        padding-bottom: 0;
        padding-left: 10px;
    }
}

.monogram-error {
    color: $red;
}

.monogram-help,
.monogram-error {
    font-size: 11px;
}

.monogram-help {
    &.invalid {
        color: $red;
    }
}

.monogram-error-invisible {
    visibility: hidden;
}

//.monogram-pdp-link {}

.cross-sell-pdp-links {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-content: center;
    align-items: center;
}

.monogram-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.monogram-thumbnail-slide {
    width: 100px;
}

.monogram-show-other-colors {
    text-decoration: underline;
    cursor: pointer;
    color: $blue;
    font-size: 12px;
    display: inline-block;
    margin-left: 20px;
    align-self: center;
}

.monogram-image-overlay {
    position: relative;

    &:focus {
        .monogram-image {
            border: 3px solid $blue-hover;
        }
    }
}

.monogram-thumbnail {
    border: 1px solid $white;
}

.monogram-thumbnail-slide-content {
    border: 1px solid $white;
    margin: 0 5px;

    @include media('>=tablet') {
        margin: 5px 0;
    }

    .slick-current & {
        border-color: $blue;
    }
}

.monogram-placement-image {
    flex: 0 0 auto;
    max-width: 60px;
    width: auto;
    max-height: 100%;

    @include media('>=tablet') {
        max-width: 90px;
    }
}

.monogram-placement-text {
    flex: 1 1 auto;
    font-size: 12px;
    text-transform: none;
    line-height: 1.1em;
    padding: 0 4px;
}

.debug-info-trigger {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    border-top: 12px solid $red;
    border-left: 12px solid transparent;
    z-index: 2;

    &:hover .debug-info-tooltip {
        display: block;
    }
}

.debug-info-tooltip {
    position: absolute;
    top: -8px;
    right: 3px;
    border: 1px dotted $black;
    padding: 5px;
    font-size: 10px;
    display: none;
    white-space: nowrap;
    background: $white;
    z-index: 99999;
}

// EPFB-1 - Revision of current cross sell position + develop an additional cross sell behavior for women bikinis
.product-col-break {
    flex-basis: 100%;
    order: 3;
}

.product-col-3 {
    order: 2;
    width: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;

    @include media('>=desktop') {
        order: 4;
    }
}

.product-crosssell-container {
    width: 100%;
    border-bottom: 1px solid $light-grey;
    position: relative;

    @include media('>=desktop') {
        border-bottom: 0;
        width: 615px; // from .product-primary-image-wrapper
        margin-right: 270px; // 250 + 2*10 from .product-detail
        margin-left: 100px; // 80 + 2+10 from .pdp-thumbnail-slider
    }

    @include media('>=desktopLarge') {
        margin-right: 525px; // 505 + 2*10 from .product-detail
    }
}

.cross-sell-bikini-wrapper {
    margin: 20px 0 10px;
    padding: 0 20px;
    align-items: center;
    align-content: center;

    @include media('>=375px', '<desktop') {
        padding: 0 30px;
    }

    @include media('>=desktop') {
        border: 1px solid $light-grey;
        padding: 10px 30px;
        margin: 20px 0;
    }
}

.cross-sell-bikini-title {
    color: $blue;
    font-weight: bold;
    width: 35%;
    padding-right: 30px;
    font-size: 12px;

    @include media('>=375px') {
        font-size: 14px;
        width: 30%;
    }

    @include media('>=480px') {
        font-size: 16px;
    }

    @include media('>=tablet', '<desktop') {
        padding-right: 40px;
    }
}

.cross-sell-bikini-slider-wrapper {
    overflow: hidden;
    width: 60%;

    @include media('>=375px') {
        width: 70%;
    }
}

.cross-sell-bikini-slider {
    &:not(.slick-initialized) {
        .cross-sell-bikini-tile {
            width: 97px;
            flex: 0 0 97px;
        }
    }

    .slick-arrow {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
    }

    .slick-prev {
        left: -20px;
    }

    .slick-next {
        right: -16px;

        @include media('>=375px') {
            right: -20px;
        }
    }
}

.cross-sell-bikini-tile {
    cursor: pointer;
    padding: 0 4px;
}

.cross-sell-bikini-image {
    padding: 3px;
    background-color: $grey-bg;
}

.productimage-wrapper {
    .js-bynderVideo,
    .bynder-widget {
        height: 100%;
    }
}

.productform-sizingalert {
    font: 12px / 14px $Arial;
    color: $blue;
    padding-right: 12px;
}

.monogram-deactivated {
    margin: 0 auto;

    & + .pdp-not-possible-msg {
        margin-bottom: 20px;
    }
}

.zoomImagesDialog {
    background-color: $white;

    &.ui-dialog {
        padding: 0 !important;
        height: 100vh !important;
        width: 100vw !important;
        max-height: 100vh !important;
        max-width: 100vw !important;
        min-height: 100vh !important;
        min-width: 100vw !important;
    }

    .product-primary-zoom {
        height: 100vh !important;
        width: 100vw !important;
        max-height: 100vh !important;
        max-width: 100vw !important;
        min-height: 100vh !important;
        min-width: 100vw !important;
        overflow: hidden !important;
        cursor: all-scroll;
    }

    .zoom-image {
        width: 922px;
        height: 1383px;
        object-fit: none; // Do not scale the image, it will be rendered in 922px*1383px
    }
}

.zoom-images {
    .product-image-container > & {
        display: none;
    }

    .slick-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 9;

        &.slick-disabled {
            display: none !important;
        }
    }

    .slick-prev {
        left: 35px;
    }

    .slick-next {
        right: 35px;
    }
}

.zoom-image {
    background-color: $white;
}
