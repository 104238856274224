// =================================
// SONIA RYKIEL General style for ui dialog
// =================================
// updated classes
// =================================
//    Radio Button
// =================================
.ui-checkboxradio-radio-label {
    @extend %ui1;
    position: relative;
    display: inline-block;
    padding: 0 0 0 30px;
    cursor: pointer;
    line-height: 22px;
    color: $black;

    .ui-icon-background {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        overflow: visible;
        background-color: $white;
        border: 4px solid $white;
        outline: 1px solid $light-grey;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }

    .ui-checkboxradio-icon-space {
        display: none;
    }

    &.ui-state-active {
        .ui-icon-background {
            outline-color: $black;
            background-color: $black;
        }
    }

    // EADA-25 - Lack of focus indicators
    &.ui-state-focus {
        .ui-icon-background {
            outline: auto;
        }
    }
}

// =================================
//    Checkbox
// =================================
.ui-checkboxradio-label:not(.ui-checkboxradio-radio-label) {
    position: relative;
    cursor: pointer;
    @extend %ui1;
    padding: 0 0 0 30px;
    display: inline-block;
    color: $black;

    span {
        vertical-align: middle;
    }

    .ui-checkboxradio-icon-space {
        display: none;
    }

    .ui-icon-background {
        width: 20px;
        height: 20px;
        overflow: visible;
        background-color: $white;
        border: 1px solid $black;
        border-radius: 4px;
        position: absolute;
        top: 50%;
        //ERV-450 Checkbox display KO on find a store page - Since the blue border is outline property, it needs more space to be entirely visible
        left: 1px;
        transform: translateY(-50%);
    }

    &:hover {
        .ui-icon-background {
            border-color: $black;
            background-color: $white;
        }
    }

    // active state: inner black square
    &:not(.gl_checkmark).ui-state-active {
        .ui-icon-background {
            border-color: $black;
            background-color: $black;

            &::before {
                position: absolute;
                left: -3px;
                bottom: 15%;
                height: 40%;
                width: 2px;
                background-color: $white;
                content: '';
                transform: translateX(10px) rotate(-50deg);
                transform-origin: left bottom;
                z-index: 1;
            }

            &::after {
                position: absolute;
                left: -3px;
                bottom: 16%;
                height: 2px;
                width: 70%;
                background-color: $white;
                content: '';
                transform: translateX(10px) rotate(-50deg);
                transform-origin: left bottom;
                z-index: 1;
            }
        }
    }

    // active state: checkmark
    &.gl_checkmark.ui-state-active {
        .ui-icon-background {
            border-color: $black;
            background: $white;
        }

        .ui-icon-background::before {
            position: absolute;
            left: -4px;
            bottom: 15%;
            height: 50%;
            width: 2px;
            background-color: $black;
            content: '';
            transform: translateX(10px) rotate(-50deg);
            transform-origin: left bottom;
            z-index: 1;
        }

        .ui-icon-background::after {
            position: absolute;
            left: -4px;
            bottom: 15%;
            height: 2px;
            width: 80%;
            background-color: $black;
            content: '';
            transform: translateX(10px) rotate(-50deg);
            transform-origin: left bottom;
            z-index: 1;
        }
    }

    // EADA-25 - Lack of focus indicators
    &.ui-state-focus {
        .ui-icon-background {
            border-width: 2px;
        }
    }
}

.ui-tooltip {
    @extend %ui3;
    position: absolute;
    z-index: 9999;
    max-width: 300px;
    background: $black;
    color: $white;
    padding: 6px 4px;
}

.ui-button {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    // Support: IE <= 11
    overflow: visible;
}

// =================================
//    UI dialog
// =================================
.ui-dialog {
    &.minicart-alert-dialog,
    &.minicart-cross-sell-alert-dialog,
    &.minicart-giftcert-alert-dialog {
        position: fixed !important;
        left: 50% !important;
        transform: translate(-50%, 0);
        width: calc(100% - 10px) !important;
        max-width: 335px;

        .ui-dialog-titlebar-close {
            padding: 16px;

            .ui-button-icon::before {
                line-height: 20px;
                font-size: 35px;
            }
        }

        @include media('>=tablet') {
            max-width: 448px;
        }
    }
}

.ui-dialog-titlebar-close {
    right: 0;
    top: 0;
    padding: 16px;
    z-index: 1;
    font-size: 0;
    cursor: pointer;
    overflow: hidden;

    .ui-button-icon::before {
        content: '\00d7';
        display: inline-block;
        font-family: $Saira;
        font-size: 30px;
        color: $black;
        line-height: 15px;
    }

    &.ui-button {
        position: absolute;
    }
}

.ui-selectmenu-icon {
    position: absolute;
    width: vw(60px);
    height: 100%;
    right: 0;
    top: 0;

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 10px;
        margin: -4px 0 0;
        @include triangle-down(10px, 5px, $black);
    }

    @include media('>=tablet') {
        width: 30px;
    }
}

.ui-selectmenu-button-open {
    .ui-selectmenu-icon {
        &::before {
            @include triangle-up(10px, 5px, $black);
        }
    }
}

.ui-dialog {
    background-color: $white;
    padding: 15px;
    z-index: 30000;
    max-width: 95%;
    max-height: 100vh;
    overflow: hidden;
    overflow-y: auto;

    &.pickUpInStoreDialog {
        padding: 0;
        position: fixed !important;

        @include media ('<=desktop') {
            top: 0 !important;
            left: 0 !important;
            height: 100% !important;
            width: 100% !important;
            max-width: 100%;
        }

        @include media('>desktop') {
            width: 1024px !important;
            max-width: 1024px;
            top: calc(50% - 384px) !important;
            left: calc(50% - 512px) !important;
        }

        .ui-dialog-content {
            width: 100vw !important;
            height: 100% !important;
            min-height: 100% !important;
            overflow: auto;

            @include media('>=desktop') {
                overflow: hidden;
            }

            @include media('>desktop') {
                width: 1024px !important;
                height: auto !important;
                max-height: 768px !important;
                min-height: auto !important;
                background: $white;
            }
        }

        .ui-dialog-titlebar {
            display: none;
        }
    }

    &.cross-sell-dialog {
        position: fixed;
        top: 0 !important;
        left: 0 !important;
        height: 100% !important;
        width: 100% !important;

        @include media ('>=desktop') {
            max-width: 600px;
            top: 100px !important;
            left: calc(50% - 300px) !important;
            height: 600px !important;
            overflow: hidden;
        }

        #cross-sellDialog {
            width: 100vw !important;
            height: 100% !important;
            min-height: 100% !important;
            background-color: $white;
            overflow: auto;

            @include media('>=desktop') {
                width: 600px !important;
                height: 600px !important;
                min-height: 0 !important;
                overflow: hidden;
            }

            .product-content {
                height: 100%;
            }
        }

        .ui-dialog-titlebar-close {
            display: none;
        }
    }

    &.shippingEditAddressDialog {
        padding: 0;
        position: fixed !important;
        top: 0 !important;
        left: 0 !important;
        height: 100% !important;
        width: 100% !important;
        max-width: 100%;

        @include media ('>=tablet') {
            height: auto !important;
        }

        @include media('>=desktop') {
            width: 1024px !important;
            max-width: 1024px;
            top: calc(50% - 384px) !important;
            left: calc(50% - 512px) !important;
        }

        .ui-dialog-content {
            width: 100vw !important;
            height: 100% !important;
            min-height: 100% !important;
            overflow: auto;

            @include media ('>=tablet') {
                height: auto !important;
                min-height: auto !important;
            }

            @include media('>=desktop') {
                width: 1024px !important;
                height: 768px !important;
                max-height: 768px !important;
                background: $white;
                overflow: hidden;
            }
        }

        .ui-dialog-titlebar {
            display: none;
        }
    }

    &.clickAndCollectDialog {
        padding: 0;
        position: fixed !important;

        @include media ('<=desktop') {
            top: 0 !important;
            left: 0 !important;
            height: 100% !important;
            width: 100% !important;
            max-width: 100%;
        }

        @include media('>desktop') {
            width: 1024px !important;
            max-width: 1024px;
            top: calc(50% - 384px) !important;
            left: calc(50% - 512px) !important;
        }

        .ui-dialog-content {
            width: 100vw !important;
            height: 100% !important;
            min-height: 100% !important;
            overflow: auto;
            border: 10px solid $store-dialog-border;
            padding: 20px;

            @include media('>=desktop') {
                overflow: hidden;
                border: none;
                padding: 0;
            }

            @include media('>desktop') {
                width: 1024px !important;
                height: auto !important;
                max-height: 768px !important;
                min-height: auto !important;
                background: $white;
            }
        }

        .ui-dialog-titlebar {
            display: none;
        }
    }

    &.contextChooserDialog {
        position: absolute !important;
        top: 0;
        left: 50% !important;
        transform: translate(-50%, 0);
        border: 5px solid $blue;
        min-width: 99vw;
        max-width: 99vw;
        padding: 5px 10px 10px;

        .ui-dialog-titlebar {
            display: none;
        }

        @include media('>=tablet') {
            top: 5% !important;
            min-width: 758px;
            max-width: 758px;
        }
    }

    &.registrationDialog,
    &.crisisDialog {
        position: absolute !important;
        top: 10% !important;
        left: 50% !important;
        transform: translate(-50%, 0);
        min-width: 99vw;
        max-width: 99vw;
        padding: 0;

        @include media('>=tablet') {
            top: 25% !important;
            min-width: 750px;
            max-width: 750px;
        }
    }

    &.cookieDialog {
        position: fixed !important;
        top: 5px !important;
        left: 50% !important;
        transform: translateX(-50%);
        border: 2px solid $black;
        min-width: calc(100% - 10px);
        max-width: calc(100% - 10px);
        padding: 30px 20px;

        @include media('>=desktop') {
            min-width: calc(100% - 10px);
            max-width: calc(100% - 10px);
        }

        .ui-dialog-titlebar {
            display: none;
        }

        .ui-dialog-content {
            min-height: auto;
        }
    }

    &.monogram-dialog {
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        min-width: 100vw;
        max-width: 100vw;
        padding: 0;

        @include media('>=tablet') {
            top: 5% !important;
            left: 50% !important;
            min-width: 750px;
            max-width: 750px;
            transform: translate(-50%, 0);
        }

        @include media('>=desktop') {
            min-width: 1024px;
            max-width: 1024px;
        }
    }

    .ui-dialog-buttonpane {
        text-align: center;
        margin-top: 0.5em;

        button {
            margin: 0.5em 1em;
            cursor: pointer;
            width: auto;
        }
    }

    &.mixmatch-minicart-mobile-alert-dialog {
        position: absolute;
    }
}

// new classes

// =================================
// VILEBREQUIN General style for ui dialog
// =================================

// ECM21-203 - ADA - cross sell - look product details modal miss aria details
.ui-dialog-title {
    display: none;
}

.no-titlebar .ui-dialog-titlebar {
    display: none;
}

// Overlay
.ui-widget-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: $grey;

    &.ui-front {
        z-index: 29999; //.ui-dialog z-index - 1; must be above #backtotop with z-index: 24000
    }
}

// =================================
//    General style for ui tabs
// =================================

.ui-tabs {
    position: relative;
}

.ui-tabs-tab {
    list-style: none;
}

.ui-tabs-anchor {
    text-decoration: none;
}

// =================================
//    Select Menu
// =================================
.gl-selectmenu-button {
    text-align: left;
    white-space: nowrap;
    width: 100%;
    height: vw(84px);
    line-height: vw(84px);
    min-height: 42px;
    padding: 0 vw(84px) 0 vw(30px);
    border: 2px solid $blue;
    display: inline-block;
    position: relative;
    margin-right: 0.1em;
    cursor: pointer;
    vertical-align: middle;
    overflow: visible;
    user-select: none;
    color: $blue;

    .ui-selectmenu-text {
        line-height: vw(84px);
        text-transform: uppercase;
        font-size: vw(24px);
        font-family: $Arial;
        font-weight: bold;
        display: block;

        @include media('>=tablet') {
            line-height: 38px;
            font-size: 16px;
        }

        @include media('>=desktop') {
            font-size: 11px;
        }
    }

    &.ui-selectmenu-button-open {
        border-bottom: 0;

        .ui-selectmenu-text {
            color: $blue-hover;
        }
    }

    @include media('>=tablet') {
        height: 42px;
        line-height: 42px;
        padding: 0 40px 0 8px;
    }

    @include media('>=desktop') {
        font-size: 11px;
    }
}

.ui-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
    outline: 0;
    position: absolute;
    width: 0;
}

.gl-selectmenu-menu {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    display: none;

    .ui-menu {
        background-color: $white;
        border: 2px solid $blue;
        border-top: 0;
        max-height: 200px;
        overflow-y: auto;
    }

    .ui-menu-item {
        cursor: pointer;

        div {
            height: vw(84px);
            width: 100%;
            line-height: vw(84px);
            padding: 0 vw(84px) 0 vw(30px);
            font-size: vw(24px);
            font-family: $Arial;
            font-weight: bold;
            text-transform: uppercase;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover {
                color: $blue-hover;
            }

            @include media('>=tablet') {
                height: 42px;
                line-height: 42px;
                padding: 0 13px;
                font-size: 16px;
            }

            @include media('>=desktop') {
                font-size: 11px;
            }
        }
    }

    .ui-state-disabled {
        display: none;
    }
}

.ui-selectmenu-menu {
    display: none;
}

.ui-selectmenu-open {
    display: block;
}

// =================================
//    State
// =================================
.ui-state-disabled {
    cursor: default !important;
    pointer-events: none;
}

.ui-selectmenu-disabled {
    background-color: $grey;
}

// =================================
//    Tooltip
// =================================
.ui-helper-hidden {
    display: none;
}

.ui-helper-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.ui-helper-reset {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    line-height: 1.3;
    text-decoration: none;
    font-size: 100%;
    list-style: none;
}

.ui-helper-clearfix::before,
.ui-helper-clearfix::after {
    content: '';
    display: table;
    border-collapse: collapse;
}

.ui-helper-clearfix::after {
    clear: both;
}

.ui-helper-zfix {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0;
}

.ui-front {
    z-index: 100;
}

.ui-widget-shadow {
    box-shadow: 0 0 10px 0 $tooltip-shadow;
}
