.pd-btn-link.gl_btn {
    display: block;
    @extend %buttonViewMore;
    margin: 30px 20px;
    width: calc(100% - 40px);

    @include media('>=tablet') {
        margin: 30px auto;
        width: 300px;
    }
}
