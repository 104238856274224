// =================================
//    Category
// =================================
// =================================
// SONIA RYKIEL GRAPHICAL CHART
// =================================

// updated classes
.category-link-slide {
    text-align: center;
    flex-grow: 1;
    vertical-align: middle;
    margin: 16px 6px;
    min-height: 80px;
    min-width: 236px;
    border: 1px solid $light-grey;

    @include media('>=tablet') {
        margin: 24px 6px;
        min-height: 104px;
        min-width: 227px;
    }

    @include media('>=desktop', '<desktopLarge') {
        min-width: 312px;
    }

    @include media('>=desktopLarge', '<desktopXL') {
        height: 144px;
        min-width: 422px;
    }

    @include media('>=desktopXL') {
        height: 208px;
        min-width: 611px;
    }
}

// custom scroll case
.categories-links {
    margin: 0 15px 20px;

    @include media('>=desktop') {
        margin: 0 32px;
    }
}

.category-carousel-image {
    display: block;
    object-fit: cover;
    max-width: 81px;
    max-height: 81px;

    @include media('>=tablet') {
        max-width: 107px;
        max-height: 104px;
    }

    @include media('>=desktop', '<desktopLarge') {
        max-width: 150px;
        max-height: 104px;
    }

    @include media('>=desktopLarge', '<desktopXL') {
        max-width: 205px;
        max-height: 142px;
    }

    @include media('>=desktopXL') {
        max-width: 299px;
        max-height: 206px;
    }
}

.categories-link-wrapper:not(.slick-initialized) {
    // mobile/tablet view - block with custom horizontal scrollbar
    display: flex;
    white-space: nowrap;
    overflow: auto;
    text-align: center;
    margin: 0;

    .category-link-slide {
        display: inline-block;
    }

    @include media('>=desktop') {
        height: auto;
        max-width: none;
        margin: 0 auto;
    }
}

.category-bottom-description {
    margin: 30px 15px;
    text-align: center;
    color: $black;
    position: relative;

    @include media('>=desktop') {
        margin: 30px 15px 65px;
    }

    h2 {
        @extend %heading;
        line-height: 20px;
        font-size: 18px;
        margin: 0 auto;
    }

    p {
        @extend %ui1;
        margin: 5px 0 0;
    }

    .category-bottom-description-content {
        padding: 0 3.8%;

        @include media('>=desktop') {
            padding: 0 200px;
        }
    }

    .read-more-link {
        text-decoration: underline;
    }
}

.category-bottom-description-separator {
    display: none;
}

.category-link {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.category-link-text-wrapper {
    width: 100%;
    padding: 11px 12px;
    text-align: left;
}

.category-link-text {
    @extend %h4;
    margin-bottom: 23px;
}

.category-link-subtext {
    @extend %button2;
    text-decoration: underline;
    font-weight: 400;
}

.rich-content-wrapper {
    display: flex;
    flex-direction: column;
    padding: 86px 0 90px;

    @include media('>=desktop') {
        flex-direction: row;
    }

    @include media('>=tablet', '<desktop') {
        padding: 85px 0 60px;
    }

    @include media('>=desktop', '<desktopLarge') {
        padding: 95px 0 80px;
    }

    @include media('>=desktopLarge', '<desktopXL') {
        padding: 95px 0 90px;
    }

    @include media('>=desktopXL') {
        padding: 136px 0 120px;
    }
}

.rich-content-block {
    flex: 1 1 0;
    padding-bottom: 20px;
    flex-direction: column;

    .rich-content-link {
        text-decoration: none;
    }

    .rich-content-title {
        @extend %h3;
    }

    .rich-content-text {
        @extend %quote1;
        padding-top: 23px;

        @include media('>=tablet', '<desktop') {
            padding-top: 37px;
        }

        @include media('>=desktop', '<desktopLarge') {
            padding-top: 39px;
        }

        @include media('>=desktopLarge', '<desktopXL') {
            padding-top: 33px;
        }

        @include media('>=desktopXL') {
            padding-top: 16px;
        }
    }

    .fluid-video-wrap.rich-content-video-responsive-16by9 {
        padding-bottom: 56.25%;
    }

    &:not(:last-child) {
        @include media('>=desktop', '<desktopLarge') {
            padding-right: 12px;
        }

        @include media('>=desktopLarge', '<desktopXL') {
            padding-right: 28px;
        }

        @include media('>=desktopXL') {
            padding-right: 16px;
        }
    }

    @include media('>=tablet', '<desktop') {
        padding-bottom: 23px;
    }

    @include media('>=desktop') {
        padding-bottom: 0;
    }
}

.category-back-button {
    position: absolute;
    top: 0;
    left: 15px;

    .backsvg {
        width: 20px;
        height: 20px;
        align-self: center;
    }

    .st0 {
        fill: $black;
    }

    .st1 {
        fill: $white;
        stroke: $black;
    }

    @include media('>=tablet') {
        display: none;
    }
}

.faq-carousel-wrapper {
    text-align: center;
    position: relative;
    padding: 25px 45px;

    .faq-title {
        @extend %h3;
        text-transform: unset;
        margin-bottom: 20px;
        color: inherit;
    }

    .faq-question {
        @extend %ui3;
        text-transform: unset;
        color: inherit;
    }

    .slick-arrow {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        &::before {
            border-color: inherit;
        }
    }

    .slick-prev {
        left: 16px;

        @include media('>=tablet') {
            left: 130px;
        }

        @include media('>=desktop') {
            left: 250px;
        }

        @include media('>=desktopLarge') {
            left: 420px;
        }
    }

    .slick-next {
        right: 16px;

        @include media('>=tablet') {
            right: 130px;
        }

        @include media('>=desktop') {
            right: 250px;
        }

        @include media('>=desktopLarge') {
            right: 420px;
        }
    }

    .slick-slider {
        position: unset;
    }

    .faq-question,
    .faq-answer-wrapper {
        @extend %ui3;
        color: inherit;
    }

    .faq-show-answer {
        text-decoration: underline;
        cursor: pointer;
    }

    .faq-question {
        margin-bottom: 15px;
    }

    .faq-carousel,
    .faq-title {
        @include media('>=tablet') {
            max-width: 350px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

// deleted classes


// =================================
// VILEBREQUIN GRAPHICAL CHART
// =================================
.catalog-category-tile {
    position: relative;
}

.catalog-tiles-container {
    flex-direction: column;

    @include media('>=desktop') {
        position: absolute;
        width: 100%;
        height: 100%;
        flex-direction: row;
        top: 0;
        left: 0;
        margin-top: 65px;
        margin-bottom: 35px;
        visibility: hidden;

        &.visible {
            visibility: visible;
        }
    }
}

.category-content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    align-items: center;
}

.category-tile-link {
    display: block;
}

.category-tile-background-container {
    padding: 5px;
}

.catalog-grid-sizer {
    display: none;

    @include media('>=desktop') {
        width: 25%;
        display: block;
    }
}

.category-title-container {
    width: 100%;
    padding: 0 25px;
}

.category-tile-title {
    color: $white;
    text-align: center;
}

.category-info {
    text-align: center;
}

.category-subtitle {
    text-align: center;
    font: 14px / 18px $Arial;
    color: $blue;
    text-transform: uppercase;
    margin: 20px 0;

    @include media('>=tablet') {
        font: 21px / 22px $Arial;
    }
}

.category-outlet-main-title {
    margin-top: 20px;
    text-align: center;
    font-size: vw(48px);

    @include media('>=desktop') {
        margin-top: 25px;
        font-size: 24px;
    }
}

.category-main-title {
    color: $blue;
    text-align: center;
    margin-top: vw(50px);

    @include media('<desktop') {
        font-size: vw(48px);
    }

    @include media('>=desktop') {
        margin-top: 37px;
    }
}

.category-article-container {
    padding: 0 50px;
    text-align: center;

    @include media('>=desktop') {
        position: absolute;
        left: 0;
        top: 0;
    }
}

.category-description {
    text-align: center;
    font: vw(24px) $Arial;
    color: $blue;

    @include media('>=desktop') {
        font-size: 12px;
    }
}

.category-products-number {
    font-size: vw(22px);
    text-align: center;
    margin-top: vw(40px);

    @include media('>=desktop') {
        margin-top: 10px;
        font-size: 14px;
    }
}

.catalog-header-wave {
    margin-top: vw(20px);

    @include media('>=desktop') {
        margin-top: 10px;
    }
}

.category-level-3-header {
    position: relative;
    z-index: 1000;
    margin-top: 20px;

    @include media('>=desktop') {
        margin-top: 0;
        min-height: 395px;
    }
}

.category-level-3-content-container {
    align-items: center;
    width: 100%;
    height: 100%;

    @include media('>=desktop') {
        position: absolute;
        left: 0;
        top: 0;
    }
}

.category-level-3-content-wrapper {
    background-color: $white;
    padding: 0;
    margin-bottom: vw(32px);
    max-height: 80%;

    @include media('>=desktop') {
        margin-bottom: 0;
        padding: 10px;

        &.pattern-content-wrapper {
            padding: 0;
            background-color: transparent;
        }
    }
}

.category-level-3-content {
    background-color: $white;
    border: 10px solid $blue;
    outline: 10px solid $white;
    border-image-slice: 10 !important;
    padding: vw(50px) vw(95px);
    text-align: center;
    width: 100%;

    @include media('>=desktop') {
        padding: 20px 5%; // top and bottom can not be in %, firefox bug
        border-image-slice: 5 !important;
        border-width: 5px;

        .pattern-content-wrapper & {
            background-color: $white;
            outline: 0;
            border: 5px solid transparent !important;
            box-shadow: 0 0 0 10px $white;
            background-clip: padding-box;
        }
    }

    @include media('>=desktopLarge') {
        padding: 55px 12%; // top and bottom can not be in %, firefox bug
    }
}

.category-level-3-subtitle,
.category-level-3-count {
    text-align: center;
    font-size: vw(22px);

    @include media('>=desktop') {
        font-size: 11px;
    }
}

.category-level-3-count,
.category-description {
    text-align: center;
    margin-top: 10px;
}

.category-level-3-title {
    text-align: center;
    margin-top: 10px;
    font-size: vw(48px);

    @include media('>=desktop') {
        font-size: 24px;
    }
}

.category-view-all {
    text-align: center;
    font: italic vw(28px) $Arial;
    margin-top: vw(20px);
    display: inline-block;
    text-transform: uppercase;
    padding: 5px 20px;
    border: 1px solid $blue;
    text-decoration: none;
    font-weight: bold;

    &:hover {
        color: $blue-hover;
    }

    @include media('>=tablet') {
        font-size: vw(24px);
    }

    @include media('>=desktop') {
        margin-top: 10px;
        margin-bottom: 0;
        font-size: 14px;
    }
}

.category-view-all-wrapper {
    .gl_ajax-loader {
        position: relative;
        margin: 0 auto;
    }
}

.search-refinements-lvl-3 {
    margin-top: 0;
}

//Mobile Design
.category-tile-mobile-container {
    margin-bottom: vw(20px);
    padding-right: vw(10px);
    padding-left: vw(10px);
}

.category-tile-link-mobile {
    font: vw(22px) $MontserratBold;
    line-height: 1;
    border: 1px solid $blue;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    padding: 5px;
    height: 55px;

    @include media('>=tablet') {
        font-size: vw(20px);
    }
}

.catalog-header-wave-mobile,
.category-tiles-mobile-container {
    margin-top: vw(40px);
}

.category-tiles-mobile-sticky {
    box-shadow: 0 3px 5px 0 $grey-shadow;
    border-top: 1px solid $light-grey;
    position: fixed;
    width: 100%;
    z-index: 20000;
    background-color: $white;
    top: 0;
    display: none;

    &.visible {
        display: block;
    }
}

.category-tiles-sticky-header {
    font: vw(28px) $Arial;
    font-weight: bold;
    color: $blue;
    line-height: vw(100px);
    width: 100%;
    padding-left: vw(40px);
    position: relative;

    @include media('>=tablet') {
        font-size: vw(20px);
        line-height: vw(60px);
    }

    &.ui-state-active {
        background-color: $light-grey;
    }

    .ui-accordion-header-icon {
        position: absolute;
        top: 0;
        right: vw(28px);
        height: 100%;
        width: 20px;
        justify-content: flex-start;
        align-content: stretch;
        flex-flow: row nowrap;
        display: flex;
        align-items: center;

        &::before {
            content: '';
            width: 100%;
            height: 11px;
            display: block;
            transform-origin: top right;
            transform: scale(0.5);
            background: url('../../images_active/menu-down-arrow.png') no-repeat;

            @include media('>=tablet') {
                transform: none;
            }
        }
    }

    &.ui-accordion-header-active {
        .ui-accordion-header-icon {
            &::before {
                transform: rotate(180deg) scale(0.5);
                position: relative;
                right: 10px;
                top: 6px;

                @include media('>=tablet') {
                    transform: rotate(180deg);
                    top: 11px;
                    right: 20px;
                }
            }
        }
    }
}

.category-tiles-sticky-content {
    padding-top: vw(20px);
}

.category-asset-title {
    font-size: 22px;

    @include media('>=tablet') {
        font-size: 32px;
    }

    @include media('>=desktop') {
        font-size: 24px;
    }
}

.category-asset-wave, {
    margin-top: vw(50px);

    @include media('>=desktop') {
        margin-top: 20px;
    }
}

.category-asset-subtitle {
    margin-top: vw(40px);
    font-size: 16px;

    @include media('>=tablet') {
        margin-top: 20px;
    }
}

.category-asset-button-container {
    padding: 0;
    margin-top: vw(70px);

    @include media('>=tablet') {
        padding: 0 10px;
    }

    @include media('>=desktop') {
        margin-top: vw(38px);
    }

    @include media('>=desktopLarge') {
        margin-top: 88px;
    }
}

.category-asset-button {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.category-level-3-header-not-full-width {
    position: relative;
    z-index: 1000;
    background-color: $white;

    @include media('>=desktop') {
        max-width: 1358px;
        margin: 0 auto;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        align-content: stretch;
        flex-flow: row nowrap;
    }
}

.category-level-3-not-full-width-content-wrapper {
    border: 10px solid $blue;
    outline: 10px solid $white;
    padding: 10px 20px;
    text-align: center;
    display: block;

    @include media('>=desktop') {
        display: flex;
        padding: 15px 20px;
        text-align: center;
        flex: 1 1 auto;
        border: 0;
        outline: none;
        margin: 0 25px 0 0;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
}

.category-level-3-not-full-width-content {
    flex: 0 0 100%;

    @include media('>=desktop') {
        flex: 1 1 60%;
        max-width: 60%;
    }
}

.category-level-3-image-wrapper {
    flex: 0 0 auto;
}

.category-level-3-image {
    width: auto;
    max-height: 400px;
    max-width: 503px;

    @include media('>=desktopLarge') {
        max-height: 530px;
        max-width: 666px;
    }
}

.category-seo-links-wrapper {
    font-size: 11px;
    padding-bottom: 10px;
}

.category-seo-links-title {
    font-size: 11px;
    margin-bottom: 10px;
}

.read-more-dots {
    display: none;

    &.visible {
        display: inline;

        @include media('>=desktop') {
            display: none;
        }
    }
}

.read-more-link {
    display: none;
    text-decoration: underline;
    cursor: pointer;

    @include media('>=tablet') {
        display: none;
    }

    &.visible {
        display: inline;

        @include media('>=tablet') {
            display: none;
        }
    }
}

.read-more-text {
    display: none;

    @include media('>=tablet') {
        display: inline;
    }

    &.visible {
        display: inline;
    }

    @include media('>=tablet') {
        display: inline;
    }
}

.discover-universe {
    display: none;
}

.category-title {
    font-size: 20px;
    padding: 20px 10px;
    text-align: center;

    @include media('>=tablet') {
        border-top: 1px solid $light-grey;
    }

    @include media('>=desktop') {
        border: 0;
    }
}

.category-top-description {
    font: 12px / 14px $Arial;
    text-align: center;
    color: $blue;
    margin: 0 15px 15px;

    @include media('>=tablet') {
        margin: 0 15px 20px;
        line-height: 12px;
    }

    @include media('>=desktop') {
        max-width: 765px;
        margin: 10px auto 20px;
    }
}

.categories-link-wrapper {
    margin: 0 35px 15px;
    position: relative;

    @include media('>=desktop') {
        max-width: 705px;
        margin: 0 auto;
    }

    @include media('>=desktopLarge') {
        max-width: 1063px;
    }

    .slick-arrow {
        position: absolute;
        top: calc(50% - 15px);
        z-index: 2;

        @include media('>=desktop') {
            top: calc(50% - 25px);
        }
    }

    .slick-prev {
        width: 30px;
        height: 30px;
        left: -30px;

        @include media('>=desktop') {
            width: 50px;
            height: 50px;
            left: -50px;
        }
    }

    .slick-next {
        width: 30px;
        height: 30px;
        right: -30px;

        @include media('>=desktop') {
            width: 50px;
            height: 50px;
            right: -50px;
        }
    }
}

.reset-all-filters {
    text-align: center;
}
