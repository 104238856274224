// PDP size chart
// DO NOT UPDATE THE CLASS NAMES, THERE ARE USED DIRECTLY IN CONTENT ASSETS
// =================================
// SONIA RYKIEL GRAPHICAL CHART
// =================================

// updated classes
.size-chart-unit {
    margin: 0 10px;
    border-width: 2px;
    text-transform: uppercase;
    width: auto;
    padding: 0 14px;
    min-width: 60px;
    display: inline-block;
    line-height: 36px;
    height: 42px;

    &.active {
        background-color: $black;
        color: $white;
    }
}

#size-chart {
    color: $black;
}

.size-chart-table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
        border-style: solid;
        border-color: $light-grey;
        border-width: 1px 0;
        text-align: center;
        vertical-align: middle;
        height: 30px;
        padding: 5px 10px;

        @include media('>=desktop') {
            padding: 5px 15px;
        }
    }

    th {
        @extend %ui4-bold;
    }

    td {
        @extend %ui3;
    }

    thead tr:first-child {
        td,
        th {
            border-top-width: 0;
        }
    }

    tbody {
        td,
        th {
            white-space: nowrap;
        }
    }

    tbody tr:last-child {
        td,
        th {
            border-bottom-width: 0;
        }
    }
}

.sizeChart-lineHightlight {
    th,
    td {
        border-color: $blue;
        border-top-width: 2px;
        border-bottom-width: 2px;
    }

    th {
        border-left-width: 2px;
    }

    td:last-child {
        border-right-width: 2px;
    }
}

.sizeChart__popupLink {
    cursor: pointer;
    color: $black;
}

// EW-123 - Size chart reboot
.size-chart-selector-wrapper {
    margin: 20px 0;
    flex: row nowrap;
    justify-content: center;

    @include media('>=desktop') {
        margin: 20px 0;

        .sizeChartPage-wrapper & {
            margin: 20px 0;
        }
    }
}

.size-chart-description-text {
    @extend %ui3;
    margin-top: 20px;
    text-align: left;
    padding: 2px;

    .size-chart-page-col > & {
        padding: 2px 0;
    }
}

.size-chart-tables-wrapper {
    margin-bottom: 40px;
    text-align: center;
    width: 100%;
}

// new classes

// deleted classes (commented, just the classname for trace)

// =================================
// VILEBREQUIN GRAPHICAL CHART
// =================================
.sizeChart-title {
    text-align: center;
    margin: vw(70px) 0 0;

    @include media('>=tablet') {
        margin: 70px 0 0;
    }
}

.sizeChart-separator {
    margin: 20px 0;
}

.sizeChart-tabTogglers {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-flow: row wrap;

    margin: 0 auto;
    width: 300px;

    @include media('>=tablet') {
        width: auto;
    }
}

.sizeChart-tabToggler {
    @extend %h3;
    line-height: 42px;
    height: 42px;
    min-width: 150px;
    padding: 0 12px;
    cursor: pointer;
    background-color: $light-grey;
    text-align: center;
    position: relative; // IE-10 (+ z-index)
    z-index: 2;
    order: 1;
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: auto;

    &.active {
        background-color: $black;
        color: $white;
    }

    &:focus {
        z-index: 3;
    }
}

.sizeChart-tabs {
    margin-top: -22px;
    background-color: $white;

    @include media('>=tablet') {
        border-top: 2px solid $blue;
    }
}

.sizeChart-tabContent {
    display: none;
    padding: 40px 20px 20px;

    &.shown {
        display: block;
    }
}

.sizeChart-pageAnchors {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-flow: row wrap;
    margin: 0 auto;
}

.sizeChart-pageAnchor {
    order: 1;
    flex: auto;
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: auto;
    cursor: pointer;
    padding: 5px;
    text-align: center;
    min-width: 90px;

    &.active {
        color: $blue;
        text-decoration: underline;
    }
}

.sizeChartPage-wrapper {
    margin-top: 20px;
    display: none;

    &.shown {
        display: block;
    }
}

.sizeChartPage {
    background-color: $white;
    padding: 20px;
    color: $blue;

    .sizeChartPage__wrapper & {
        padding: 0;
    }
}

.sizeChartPage__title {
    font-family: $MontserratBold;
    font-size: vw(28px);
    text-align: center;

    @include media('>=tablet') {
        font-size: 14px;
    }
}

.size-chart-separator {
    margin: vw(40px) 0 vw(60px);

    @include media('>=tablet') {
        margin: 20px 0 40px;
    }
}

.sizeChartPage__content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-flow: row wrap;

    @include media('>=tablet') {
        flex-wrap: nowrap;
    }
}

.sizeChartPage__sizeLabel {
    font-family: $MontserratBold;
    text-transform: uppercase;
    font-size: vw(28px);

    @include media('>=tablet') {
        font-size: 16px;
    }
}

.sizeChartPage__sizeEquivalent__label {
    display: inline-block;
    font-size: vw(22px);
    margin: vw(20px) 0 vw(30px);

    @include media('>=tablet') {
        font-size: 11px;
        margin: 20px 0 10px;
    }
}

.sizeChartPage__silhouette__wrapper {
    order: 1;
    text-align: center;
    flex: 0 0 auto;
    align-self: center;
    padding-bottom: 5px;
    width: 180px;
    margin: 0 0 vw(120px);

    @include media('>=tablet') {
        margin: 0;
        order: 2;
        flex-basis: 180px;
    }
}

.sizeChartPage__silhouette {
    width: auto;
}

.sizeChartPage__sizeBlocks {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: flex-start;
    order: 2;
    flex: 1 1 auto;

    @include media('>=tablet') {
        order: 1;
    }
}

.sizeChartPage__sizeBlock__wrapper {
    order: 1;
    flex: 0 1 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: flex-start;

    @include media('>=tablet') {
        flex-basis: 50%;
    }

    @include media('>=desktop') {
        flex-basis: 30%;
    }
}

.sizeChartPage__sizeBlock {
    text-align: center;
    padding: vw(20px);
    width: 220px;
    margin: 0 auto;
    border: 3px solid transparent;

    @include media('>=desktop') {
        padding: 20px;
    }

    &.active {
        border: 3px solid $blue;
    }
}

.sizeChartPage__sizeEquivalent,
.sizeChartPage__sizeMeasurements {
    width: 100%;

    td,
    th {
        font-size: vw(22px);
        padding: 10px;
        vertical-align: middle;
        border-bottom: 1px solid $blue;

        @include media('>=tablet') {
            font-size: 11px;
        }
    }

    th {
        font-family: $MontserratBold;
        text-transform: uppercase;
        width: 50%;
    }

    tr:last-child {
        th,
        td {
            border: 0;
        }
    }
}

.sizeChartPage__sizeMeasurements__label {
    font-size: vw(22px);
    margin: 20px auto;

    @include media('>=tablet') {
        font-size: 11px;
    }
}

.sizeChartPage__sizeEquivalent {
    margin-bottom: 10px;
}

.sizeChart__popupLink__wrapper,
.howToMeasure__wrapper {
    padding: 10px 20px;
    background-color: $white;
}

.sizeChart__popupLink__wrapper {
    text-align: right;
}

.size-chart-countries {
    margin: 0 10px;

    &.gl_select {
        text-transform: uppercase;
        max-width: 50%;

        select {
            width: auto;
            max-width: 100%;
        }
    }
}

.size-chart-countries-select,
.size-chart-unit {
    font-size: 11px;
}

.size-chart-units {
    font-size: 0;
    white-space: nowrap;
}

.size-chart-page-cols {
    margin-bottom: 40px;

    @include media('>=desktop') {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        align-content: flex-start;
    }

    .sizeChartPage-wrapper & {
        margin-bottom: 90px;
    }
}

.size-chart-page-col {
    & + & {
        margin-top: 30px;

        @include media('>=desktop') {
            .sizeChartPage-wrapper & {
                margin-top: 0;
            }
        }
    }

    @include media('>=desktop') {
        .sizeChartPage-wrapper & {
            flex: 0 0 50%;
        }
    }
}

.size-chart-figures-wrapper {
    padding: 0 10px;
    margin-bottom: 40px;

    .gl_wave-separator {
        margin: 20px 0 40px;
    }

    .sizeChartPage-wrapper & {
        display: none;
    }

    @include media('>=desktop') {
        width: 100%;
        margin: 0 0 40px;
    }
}

.size-chart-figures-title {
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
}

.size-chart-figures {
    flex-flow: row wrap;
    justify-content: center;
    align-items: stretch;
    align-content: stretch;
}

.size-chart-figure {
    flex: 0 0 50%;
    text-align: center;
    padding: 2px;

    @include media('>=tablet') {
        flex: 0 0 20%;
        padding: 2px 30px;
    }
}

.size-chart-shape-wrapper {
    text-align: center;
}

.size-chart-shape {
    max-width: 100%;

    .size-chart-figure & {
        width: auto;
    }
}

.size-chart-figure-text {
    text-align: center;
    padding: 2px;
    font-size: 12px;
}

.size-chart-figure-title {
    font-weight: bold;
    font-size: 16px;
}

.size-chart-description {
    margin-bottom: 40px;

    .sizeChartPage-wrapper & {
        padding: 0 10px;
    }

    .gl_wave-separator {
        margin: 20px 0 40px;

        .sizeChartPage-wrapper & {
            display: none;
        }
    }

    @include media('>=desktop') {
        width: 60%;
        margin: 0 0 40px;
        padding: 0;

        .sizeChartPage-wrapper & {
            margin: 0 20%;
        }
    }
}

.size-chart-description-title {
    font-weight: bold;
    text-transform: uppercase;

    .sizeChartPage-wrapper & {
        text-align: center;
        margin-bottom: 20px;
    }
}

.size-chart-description-subtitle {
    @extend %ui4-bold;
}

.collection-selector-wrapper {
    margin-bottom: 20px;
}

.size-chart-page-title {
    display: none;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;

    .sizeChartPage-wrapper & {
        display: block;
    }
}

.pt_sizecharts .primary-content {
    margin-bottom: 70px;
}

// STYLE GUIDE PAGE
.styleguide-banner {
    position: relative;
}

.styleguide-banner-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    font-size: 28px;
    text-align: center;
    min-width: 80%;
}

.styleguide-info-wrapper {
    background-color: $white;
    border: 2px solid $blue;
    outline: 4px solid $white;
    max-width: 92%;
    margin: 10px 4% 30px;
    padding: 30px 15px 40px;
    text-align: center;

    @include media('>=tablet') {
        position: relative;
        top: -30px;
        z-index: 2;
        margin: 0 4%;
    }
}

.styleguide-title {
    font-size: 20px;
}

.styleguide-separator {
    &.gl_wave-separator {
        margin: 5px 0 20px;
    }
}

.styleguide-description {
    font-size: 13px;
    font-style: italic;
}

.styleguide-tabButtons {
    border: solid $blue-light;
    border-width: 1px 0;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 0 20px;
}

.styleguide-tabButton-wrapper {
    text-align: center;
    min-width: 120px;
}

.styleguide-tabButton {
    margin: 9px 20px 6px;
    cursor: pointer;

    @include media('>=tablet') {
        font-size: 12px;
    }
}

.styleguide-tabs {
    margin: 20px 0 30px 0;
}

.styleguide-tab {
    display: none;

    &.active {
        display: block;
    }

    @include media('>=desktop') {
        display: none;

        &.active {
            display: block;
        }
    }

    .styleguide-content {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-content: center;
        align-items: stretch;

        &.slick-slider {
            display: block;
        }
    }
}

.styleguide-activities,
.styleguide-fits {
    text-align: center;
    color: $blue;
    position: relative;
}

.styleguide-fits {
    .styleguide-pdp & {
        @include media('>=desktop') {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-content: center;
            align-items: center;
        }
    }
}

.styleguide-fit,
.styleguide-activity {
    padding: 5px 5px 100px 5px;
    position: relative;
}

.styleguide-fit {
    @include media('>=desktop') {
        flex: 0 0 16.66%;
    }

    .styleguide-pdp & {
        display: none;

        &.styleguide-pdp-match {
            display: block;
        }

        @include media('>=desktop') {
            display: block;
            opacity: 0.5;

            &.styleguide-pdp-match {
                opacity: 1;
            }
        }
    }
}

.styleguide-pdp {
    margin-bottom: 20px;

    @include media('>=desktop') {
        margin-bottom: 0;
    }
}

.styleguide-pdplink-wrapper {
    margin-top: 30px;
    text-align: center;
}

.styleguide-pdplink.gl_button {
    width: auto;
    min-width: 180px;
}

.styleguide-activity {
    @include media('>=desktop') {
        flex: 0 0 16.66%;
    }
}

.styleguide-activity-image,
.styleguide-fit-shape {
    width: auto;
    max-height: 237px; // 711/3
}

.styleguide-activity-suptitle,
.styleguide-fit-suptitle {
    font-size: 10px;
    line-height: 12px;
}

.styleguide-activity-title,
.styleguide-fit-title {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    max-width: 200px;
    margin: 0 auto;
    text-decoration: none;
    display: block;
    letter-spacing: normal;
}

.styleguide-activity-suptitle {
    margin-top: 20px;
}

.styleguide-fit-description,
.styleguide-activity-description {
    font-size: 12px;
    max-width: 200px;
    margin: 10px auto 0;
    min-height: 42px;

    @include media('>=desktop') {
        min-height: auto;
    }
}

.styleguide-activity-button,
.styleguide-fit-button {
    position: absolute;
    left: 50%;
    bottom: 45px;
    transform: translateX(-50%);

    &.gl_button {
        width: auto;
        min-width: 120px;
        max-width: 100%;
    }
}

.styleguide-tab-description {
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    align-content: center;

    @include media('>=tablet') {
        flex-wrap: wrap;
    }
}

.styleguide-description-image-wrapper {
    flex: 0 0 100%;
    position: relative;

    @include media('>=tablet') {
        flex-basis: 50%;
        padding-right: 5px;
    }

}

//.styleguide-description-image {}

.styleguide-description-title {
    text-transform: uppercase;
    color: $white;
    font: 16px $MontserratBold;
    position: absolute;
    max-width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.styleguide-description-text {
    text-align: left;
    flex: 0 0 100%;
    position: relative;
    padding: 10px;

    @include media('>=tablet') {
        flex-basis: 50%;
        padding: 0 0 0 5px;
    }
}

.pdp-main-tab.styleguide-tab {
    padding-bottom: 30px;

    .styleguide-fit,
    .styleguide-activity {
        padding-bottom: 0;
    }

    .styleguide-pdplink-wrapper {
        margin-top: 10px;

        @include media('>=desktop') {
            margin-top: 30px;
        }
    }
}

// STYLE GUIDE PAGE: women
.women-styleguide-slider {
    @include media('>=desktop') {
        margin: 0 80px;
        position: relative;

        .slick-arrow {
            position: absolute;
            top: 30%;
        }

        .slick-prev {
            left: -50px;
        }

        .slick-next {
            right: -50px;
        }
    }

    .slick-track {
        margin: 0 auto;
    }
}

.styleguide-slider-title {
    margin: 20px 0;
    text-align: center;
    font-size: 28px; // like styleguide-banner-title

    &.active {
        color: $blue-hover;
    }

    // css hack to have proper anchor view on scroll if sticky panel is
    @include media('>=desktop') {
        margin-top: -80px;
        padding-top: 100px;
    }
}

.styleguide-attr-box {
    border: 1px solid $blue;
    padding: 14px;
    color: $blue;
}

.styleguide-fakelink {
    font-weight: bold;
    cursor: pointer;
}

.styleguide-silhouette {
    padding: 0 20px 100px;
    text-align: center;
}

.styleguide-silhouette-img-top {
    max-height: 100px;
}

.styleguide-silhouette-img-bottom {
    max-height: 150px;
}

.styleguide-silhouette-img-one-piece {
    max-height: 200px;
}

.styleguide-silhouette-img {
    width: auto;
    padding: 0 0 10px;

    @include media('>=desktop') {
        width: 100%;
        max-height: none;
        padding: 0 10px 10px;
    }
}

.styleguide-additional-content-wrapper {
    color: $blue;
    margin: 0 10%;

    &::before,
    &::after {
        content: '';
        display: block;
        height: 3px;
        background-color: $blue;
    }

    &::before {
        margin: 0 auto 20px;
    }

    &::after {
        margin: 20px auto 0;
    }

    @include media('>=desktop') {
        &::before,
        &::after {
            width: 250px;
        }
    }

    .styleguide-silhouette-img {
        @include media('>=desktop') {
            max-width: 250px;
            float: right;
        }
    }
}

.styleguide-additional-content-wrapper-last {
    margin-bottom: 20px;

    @include media('>=desktop') {
        margin: 0 10% 20px;
    }
}

.styleguide-additional-content-grid {
    align-items: center;
}

.styleguide-additional-content-col {
    margin-bottom: 20px;
}

.styleguide-additional-content-info {
    padding: 15px;
    border: 1px solid $light-grey;
}

.styleguide-additional-content-title {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
}

.styleguide-additional-content-row {
    margin-bottom: 10px;
}

.styleguide-additional-content-subtitle {
    font-size: 12px;
    font-weight: bold;
}

.styleguide-additional-content-desc {
    font-size: 12px;
}

// STYLE GUIDE PAGE: women on PDP
.styleguide-women-pdp {
    text-align: center;
    color: $blue;

    @include media('>=desktop') {
        margin: 20px 60px 0;
    }

    .women-styleguide-slider {
        @include media('>=desktop') {
            margin: 0;
        }
    }

    .styleguide-silhouette {
        padding: 0 5px;
        opacity: 0.5;

        &.styleguide-pdp-match {
            opacity: 1;
        }
    }

    .styleguide-silhouette-img {
        max-width: 60%;
    }
}
