.text-images-button-component {
    @include media('>=desktop') {
        display: flex;
        flex-flow: row nowrap;
    }
}

.text-image-button-col {
    @include media('>=desktop') {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

.text-image-button-content {
    color: $black;
    background-color: $white;
    padding: 30px 20px 60px;

    @include media('>=tablet') {
        padding: 30px 32px 60px;
    }

    @include media('>=desktop') {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        padding: 30px 50px 60px;
    }
}

.text-images-button-title {
    @extend %h2;
    margin: 0 0 20px;
}

.text-images-button-text {
    @extend %subtitle1;
    margin: 0 0 30px;

    @include media('>=desktop') {
        margin: 0 0 40px;
    }

    @include media('>=desktopLarge') {
        margin: 0 0 60px;
    }
}

.text-images-button-text-link.gl_btn {
    @extend %buttonViewMore;
    display: inline-block;
    min-width: 300px;
    width: auto;

    @include media('>=desktop') {
        max-width: 300px;
    }
}

.text-image-button-carousel-wrapper {
    .images-carousel-slide-link {
        display: block;
        margin: 0;
    }

    .carousel-with-arrows {
        padding: 0 30px;
    }

    .slick-arrow {
        position: absolute;
        top: calc(50% - 10px);
        width: 25px;
        height: 25px;
        padding: 5px;
        z-index: 1;
    }

    .slick-prev {
        left: 5px;
    }

    .slick-next {
        right: 5px;
    }
}
