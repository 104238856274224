// =================================
//    Retina
// =================================
@mixin retina($image, $showWidth: true, $showHeight: true) {
    @media (min-resolution: 192dpi), (min-resolution: 2dppx) {
        @content;
    }
}

// =================================
//    Icons
// =================================
@mixin mobile-first-icon($image, $hover: '') {
    display: block;
    @include sprite($image);

    @include media('retina2x') {
        @include sprite("#{$image}#{$sprite-suffix}");
        transform-origin: top left;
        transform: scale(0.5);

        * {
            transform: scale(2);
        }
    }
}

@mixin viewport-icon($image, $viewportStart, $viewportEnd: '', $hover: '') {
    display: block;

    @if $viewportEnd == '' {
        @include media($viewportStart) {
            @include sprite($image);
        }

        @include media('retina2x', $viewportStart) {
            @include sprite("#{$image}#{$sprite-suffix}");
            transform-origin: top left;
            transform: scale(0.5);

            * {
                transform: scale(2);
            }
        }
    } @else {
        @include media($viewportStart, $viewportEnd) {
            @include sprite($image);
        }

        @include media('retina2x', $viewportStart, $viewportEnd) {
            @include sprite("#{$image}#{$sprite-suffix}");
            transform-origin: top left;
            transform: scale(0.5);

            * {
                transform: scale(2);
            }
        }
    }
}

// =================================
//    Css arrows up/down
// =================================

@mixin triangle-up($width: 8px, $height: 4px, $color: $black) {
    border-left: calc($width / 2) solid transparent;
    border-right: calc($width / 2) solid transparent;
    border-bottom: $height solid $color;
    border-top: 0;
}

@mixin triangle-down($width: 8px, $height: 4px, $color: $black) {
    border-left: calc($width / 2) solid transparent;
    border-right: calc($width / 2) solid transparent;
    border-top: $height solid $color;
    border-bottom: 0;
}

// =================================
//    Css arrows prev/next
// =================================

@mixin triangle-prev($width: 8px, $height: 4px, $color: $black) {
    border-top: calc($width / 2) solid transparent;
    border-bottom: calc($width / 2) solid transparent;
    border-right: $height solid $color;
    border-left: 0;
}

@mixin triangle-next($width: 8px, $height: 4px, $color: $black) {
    border-top: calc($width / 2) solid transparent;
    border-bottom: calc($width / 2) solid transparent;
    border-left: $height solid $color;
    border-right: 0;
}

// =================================
//    Carousel controls: arrows
// =================================
@mixin carousel-arrow($direction: 'next') {
    width: 50px;
    height: 50px;
    display: block;
    margin: 0 auto;
    border: 0;
    background-color: transparent;
    padding: 0;
    position: relative;
    text-align: center;

    &::before {
        content: '';
        display: inline-block;
        width: 50%;
        height: 50%;
        border: 3px solid $black;
        border-width: 3px 0 0 3px;

        @if $direction == 'next' {
            transform: rotate(-225deg);
        } @else if $direction == 'prev' {
            transform: rotate(-45deg);
        } @else if $direction == 'up' {
            transform: rotate(45deg);
        } @else if $direction == 'down' {
            transform: rotate(225deg);
        }
    }
}

// =================================
//    Carousel controls: triangle arrows
// =================================
@mixin carousel-triangle-arrow($direction: 'next', $width: 8px, $height: 4px, $color: $black) {
    width: 50px;
    height: 50px;
    display: block;
    margin: 0 auto;
    border: 0;
    background-color: transparent;
    padding: 0;
    position: relative;
    text-align: center;

    &::before {
        content: '';
        display: inline-block;

        @if $direction == 'next' {
            @include triangle-next($width, $height, $color);
        } @else if $direction == 'prev' {
            @include triangle-prev($width, $height, $color);
        }
    }
}

// =================================
//    vw font size
// =================================
@function vw($pixels, $viewport: $tablet) {
    @return calc($pixels / ($viewport * 0.01) * 1vw);
}

// =================================
//    Absolute center
// =================================
@mixin absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin absolute-horizont-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

@mixin absolute-vertical-center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

@mixin absolute-fixed-center {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// =================================
//    Header style
// =================================
@mixin header-title() {
    font: 16px / 19px $Arial;
    letter-spacing: normal;
    font-weight: bold;
    text-transform: none;
    text-align: left;
    margin: 0 0 5px;

    @include media('>=tablet') {
        text-align: center;
        font-size: 20px;
        line-height: 20px;
    }
}

@mixin header-title-wave() {
    .gl_svg-wave-separator {
        text-align: left;
        margin: 0 0 5px;

        @include media('>=tablet') {
            text-align: center;
        }
    }

    .svgwave {
        margin-left: -30px;

        @include media('>=tablet') {
            margin: 0;
        }
    }
}

// slick custom arrows
@mixin custom-arrows($color: $black) {
    position: relative;
    width: 50px;

    .slick-arrow {
        position: absolute;
        top: 12px;
        right: 0;
        width: 17px;
        height: 2px;
        background: $color;

        &.slick-disabled {
            opacity: 0.3;
        }

        &::before {
            content: "";
            width: 8px;
            height: 8px;
            border-width: 2px 0 0 2px;
            border-style: solid;
            border-color: $color;
        }

        &::after {
            display: none;
        }
    }

    .slick-prev {
        margin-right: 32px;

        &::before {
            margin: -4px 0 0 -5px;
        }
    }

    .slick-next {
        &::before {
            margin: -4px 0 0 5px;
        }
    }
}
