// =================================
// SONIA RYKIEL GRAPHICAL CHART
// =================================

// updated classes
.store-locator-title {
    @extend %h4;
    text-align: center;
    margin: 0 0 15px;
    padding: 16px 20px 17px;
    border-bottom: 1px solid $light-grey;

    @include media('>=desktop') {
        border: none;
        padding: 0;
        margin: 27px 0 29px;
    }

    .clickAndCollectDialog & {
        margin-top: 0;
    }
}

.store-locator-name {
    @extend %h4;
    margin: 0;
    display: inline;

    a {
        text-decoration: none;
    }

    .store-locator-details & {
        @extend %h2;
        display: block;
        text-align: center;
        margin: 28px 0 42px;

        @include media('>=desktop') {
            text-align: left;
        }
    }
}

.store-locator-address {
    @extend %body1;
    margin: 10px 0 0;

    @include media('>=tablet') {
        .store-locator-details & {
            margin-top: 30px;
        }
    }
}

.store-locator-phone {
    @extend %body1;
    text-decoration: none;
    margin: 0;
    display: block;
}

.store-locator-time {
    @extend %body1;
    color: $medium-grey;
    margin: 10px 0 0;

    ul {
        @extend %ui3;
        color: $black;
        display: table;

        li {
            display: table-row;

            span {
                display: table-cell;
                padding-right: 10px;
            }
        }
    }

    @include media('>=desktop') {
        padding-left: 30px;
    }
}

.store-types-legend {
    @extend %ui3;
    margin: 20px 0 16px;
    padding: 0 20px;
    flex-wrap: wrap;

    @include media('>=desktop') {
        margin: 35px 0 33px;
        padding: 0;
        justify-content: flex-end;
    }
}

.store-type {
    flex: 0 0 100%;

    @include media('>=desktop') {
        flex: 0 0 auto;
        padding-left: 24px;
    }
}

.store-type-icon {
    display: inline-block;
    margin-right: 5px;
    width: 20px;
    flex-grow: 0;
    flex-shrink: 0;
}

.store-type-text {
    display: inline-block;
    align-self: center;
}

.store-detail-time {
    @extend %body1;
    margin: 20px 0 0;
    color: $medium-grey;

    ul {
        @extend %ui3;
        color: $black;
        display: table;

        li {
            display: table-row;

            span {
                display: table-cell;
                padding-right: 10px;
            }
        }
    }
}

.store-item-bold {
    @extend %ui4;
    margin: 10px 0 0;
}

.store-detail-favorite-store {
    justify-content: center;
    display: flex;

    .wishlist-icon-container {
        height: 16px;
        line-height: 100%;
    }

    &:hover {
        .st0 {
            stroke: $medium-grey;
        }
    }
}

.store-locator-filters-wrapper {
    flex-wrap: wrap;
    padding: 0 20px;

    .store-availability-wrapper & {
        padding: 0;
    }

    @include media('>=desktop') {
        flex-wrap: nowrap;
        padding: 0;
    }
}

.google-map-wrapper {
    height: 336px;

    .pickUpInStoreDialog & {
        height: vw(460px);
    }

    @include media('>=tablet') {
        height: 400px;

        .pickUpInStoreDialog & {
            height: 300px;
        }
    }

    @include media('>=desktop') {
        height: 512px;
    }
}

.store-locator-store {
    border-top: 1px solid $light-grey;
    padding: 35px 0;
    margin: 0 20px;
    position: relative;

    &.selected {
        border: 3px solid $black;
    }

    @include media('>=desktop') {
        margin: 0;
        padding: 26px 0 25px;
    }

    .clickAndCollectDialog & {
        padding: vw(40px) 0;

        @include media('>=desktop') {
            padding: 9px;
        }
    }

    [class^=col] {
        padding: 0;
    }
}

.store-locator-discover-store {
    @extend %ui3;
    margin-top: 10px;
    display: inline-block;
}

.store-locator-icon {
    position: absolute;
    top: 32px;
    right: 0;
    width: 25px;
    height: 25px;
    padding: 2.5px;

    .store-type-icon {
        margin: 0;
    }

    @include media('>=desktop') {
        top: 23px;
    }
}

.store-detail-hours-info {
    order: 5;
    padding: 55px 0 0;

    & + & {
        order: 4;
        padding: 0;
    }

    @include media('>=desktop') {
        order: 3;
        margin-top: 0;
    }
}

.opening-hours-title {
    @extend %h5;
    margin: 0 0 20px;
}

.store-detail-main-info {
    order: 1;
    margin-bottom: 10px;
    width: 100%;
}

.store-detail-phone {
    margin-top: 10px;
}

.store-detail-button {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 15px;
    width: auto;

    @include media('>=desktop') {
        .clickAndCollectDialog &,
        .pickUpInStoreDialog & {
            width: 210px;
        }
    }
}

.store-detail-actions {
    order: 3;
    padding: 0;
    flex-direction: column;

    @include media('>=desktop') {
        order: 4;
        flex-direction: row;
        justify-content: space-between;
    }
}

.store-locator-details {
    width: 100%;
    margin-bottom: 60px;
}

.store-detail-get-direction {
    display: none;
}

.clickAndCollectDialog {
    .storelocator-close {
        display: block;
        float: right;
        width: 40px;
        height: 40px;
        border: none;

        @include media('>=desktop') {
            width: 100%;
            height: 100%;
            border-left: 1px solid $grey-bg;
        }

        .closesvg {
            width: 14px;
            height: 14px;

            .st0 {
                fill: $black;
            }
        }
    }
}

.storelocator-content {
    color: $black;
}

.call-the-store-wrapper {
    margin: vw(30px) 0 0;
    color: $black;
    font-size: vw(24px);

    @include media('>=tablet') {
        margin-top: 30px;
        font-size: 24px;
    }

    @include media('>=desktop') {
        font-size: 12px;

        .clickAndCollectDialog &,
        .pickUpInStoreDialog & {
            border-bottom: 0;
            margin-top: 25px;
        }
    }
}

.storelocator-close {
    width: 50px;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    border-left: 1px solid $grey-bg;
    cursor: pointer;

    @include media('>=tablet') {
        width: 60px;
    }

    .closesvg {
        width: 20px;
        height: 20px;

        @include media('>=tablet') {
            width: 14px;
            height: 14px;
        }

        .st0 {
            fill: $black;
        }
    }
}

.pickUpInStoreDialog {
    .storelocator-close {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        z-index: 1;
    }
}

.storelocator-google-map-search-wrapper {
    margin: 0;
    padding: 15px 15px 0;

    @include media('>=desktop') {
        padding: 15px;
        position: absolute;
        left: 0;
        top: 0;
        width: 375px;
    }

    .storelocator-google-map-search {
        height: 44px;
        line-height: 44px;
        padding: 0 42px 0 10px;

        @include media('>=tablet') {
            height: 50px;
            line-height: 50px;
            padding: 0 48px 0 10px;
        }

        @include media('>=desktop') {
            height: 44px;
            line-height: 44px;
            padding: 0 42px 0 10px;
        }
    }

    .storelocator-google-map-input-wrapper {
        .storelocator-input-magnifying-glass-container {
            background-color: $black;
            width: 44px;

            @include media('>=tablet') {
                width: 48px;
            }

            @include media('>=desktop') {
                width: 44px;
            }
        }
    }

    .storelocator-magnifying-glass-container {
        width: 44px;
        height: 44px;

        .svgmagnifyingglass {
            position: relative;
            width: 20px;
            height: 20px;
        }

        .st0 {
            stroke: $white;
        }
    }
}

.storelocator-store {
    border-bottom: 1px solid $light-grey;
    padding: 15px 0;
    color: $black;

    &.selected {
        border: 1px solid $black;
    }
}

.storelocator-store-details-toggle-chevron {
    display: inline-block;
    width: 15px;
    height: 100%;
    margin: 0 0 5px 0;
    transform: rotate(90deg);

    .svgchevron {
        width: 5px;
    }

    .st0 {
        fill: $black;
    }
}

.clickAndCollectDialog {
    .storelocator-title {
        font: 18px / 22px $Arial;
        text-align: center;
        letter-spacing: normal;
        color: $black;
        text-transform: uppercase;
        padding: 40px 0 20px;

        @include media('>=desktop') {
            text-align: left;
            padding: 15px;
        }
    }
}

.storelocator-store-item-bold {
    font: 11px / 14px $Arial;
    text-transform: uppercase;
    margin: 10px 0 0;
    color: $black;
}

.gl_btn.storelocator-btn {
    display: block;
    font: 10px / 30px $Arial;
    height: 30px;
    padding: 0 8px;
    margin: 13px 0 0;
}

.checkout-store-mobile-thumb {
    width: 100%;
    position: relative;
    border-top: 1px solid $light-grey;
    border-bottom: 1px solid $light-grey;
    margin-top: 6px;
    font: vw(24px) $Arial;

    @include media('>=tablet') {
        margin-top: 30px;
        font-size: 24px;
    }

    @include media('>=desktop') {
        font-size: 12px;
    }

    &.ui-state-active {
        border-bottom: 0;
        background-color: $light-grey;
    }
}

.checkout-store-detail-toggle {
    position: relative;
    padding-right: 30px;
    font: vw(28px) $Arial;
    text-transform: uppercase;

    @include media('>=tablet') {
        font-size: 28px;
    }

    @include media('>=desktop') {
        font-size: 11px;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 4px;
        right: 10px;

        @include triangle-down();
    }

    &.active::after {
        @include triangle-up();
    }
}

.storelocator-opening-hours-title {
    font: 12px $Arial;
}

// new classes

// deleted classes
/*
.store-detail-main-info
.store-detail-hours-info
.store-detail-back
*/

// =================================
// VILEBREQUIN GRAPHICAL CHART
// =================================

.pt_store-locator {
    .header-search-mobile {
        display: none;
    }
}

.store-locator-content {
    flex-wrap: wrap;

    @include media('>=desktop') {
        flex-wrap: nowrap;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

.store-locator-stores-content {
    padding: 0;

    @include media('>=desktop') {
        overflow-y: auto;
        position: relative;

        .store-availability-wrapper & {
            flex-grow: 1;
        }
    }
}

.stores-wrapper {
    padding: 0;

    .clickAndCollectDialog &,
    .pickUpInStoreDialog & {
        margin-top: 20px;
        overflow: auto;
        position: relative;
        left: 0;
        top: 0;
        height: auto;
        width: 100%;
        padding: 0;
    }

    @include media('>=desktop') {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        padding: 0 10px 0 20px;

        .clickAndCollectDialog & {
            margin: 60px 0 0;
            overflow: auto;
            position: relative;
            padding-left: 0;
            padding-right: 1px;
        }

        .pickUpInStoreDialog & {
            width: 40%;
            float: left; //FE to optimize, floats should not be used
            height: 650px;
        }

        .store-availability-wrapper & {
            padding: 0;
        }
    }
}

.store-locator-map-content {
    padding: 0;
    flex-direction: column;

    @include media('>=desktop') {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.google-map-search-wrapper {
    width: 100%;

    .store-availability-wrapper & {
        padding: 10px 0 0;
    }

    @include media('>=desktop') {
        .pickUpInStoreDialog & {
            width: 40%;
            float: left; //FE to optimize, floats should not be used
        }

        .store-availability-wrapper & {
            margin-bottom: 10px;
            width: 100%;
            padding: 0;
        }
    }

    .clickAndCollectDialog & {
        width: 100%;

        @include media('>=desktop') {
            padding: 15px;
            position: absolute;
            left: 0;
            top: 0;
            width: 375px;
        }
    }

    &.gl_form-group {
        padding-bottom: 0;
    }
}

.limit-google-map-results {
    margin-top: vw(20px);

    @include media('>=desktop') {
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: vw(50px, $desktopLarge);
    }

    @include media('>=desktopLarge') {
        margin-left: 98px;
    }
}

.store-locator-title-wave {
    display: none;
}

.only-pick-up-in-store {
    .store-locator-filters-wrapper & {
        line-height: 24px;
    }

    @include media('>=desktop') {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

//Store Details
.store-detail-header {
    position: relative;
}

.store-detail-image-wrapper {
    height: 0;
    order: 1;
}

.store-detail-image {
    max-width: 265px;
    border: 3px solid $white;
    position: relative;
    top: -140px;
}

.store-detail-status-group {
    margin-top: vw(30px);

    @include media('>=tablet') {
        margin-top: 30px;
    }
}

.favorite-store-icon {
    width: 13px;
    height: 100%;
    align-items: center;
}

.favorite-store-text {
    margin-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.store-availability-wrapper {
    margin-bottom: 30px;
}

// Click-and-collect dialog on PDP/cart; In-store-pick-up dialog on checkout
.storelocator-dialog-active {
    // no scroll on page body
    @include media('<=desktop') {
        overflow: hidden;
        position: fixed;
    }
}

.pickUpInStoreDialog {
    .storelocator-header {
        position: relative;
        width: 100%;
        height: 60px;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $grey-bg;
    }

    .storelocator-title {
        padding: 15px;
        flex-grow: 1;
        max-width: calc(100% - 50px);
        font: 16px / 19px $Arial;
        font-weight: bold;
        text-transform: none;
        letter-spacing: normal;

        @include media('>=tablet') {
            font: 18px / 22px $Arial;
            font-weight: bold;
            max-width: calc(100% - 60px);
        }
    }
}

.pickUpInStoreDialog {
    .storelocator-content {
        @include media('>=desktop') {
            display: flex;
            flex-direction: row;
            position: relative; // important for search input
        }
    }
}

.storelocator-stores-wrapper {
    border-top: 1px solid $light-grey;
    padding: 0 15px 15px;

    @include media('>=desktop') {
        margin: 75px 0 0; // space for absolute search input
        overflow: auto;
        height: calc(768px - 60px - 75px);  // (dialog height - header hieght - search input line-height)
    }

    .store-availability-wrapper & {
        padding: 0;
    }
}

.storelocator-name {
    font: 12px / 16px $Arial;
    font-weight: bold;
    letter-spacing: normal;
    text-transform: none;
}

.storelocator-name-link {
    text-decoration: none;
}

.storelocator-address {
    font: 12px / 16px $Arial;
}

.storelocator-phone {
    display: block;
    font: 12px / 16px $Arial;
    font-weight: bold;
    text-decoration: none;
}

.clickandcollect-select-store-disclaimer {
    text-align: right;
    font-size: 11px;
    font-style: italic;
    margin-top: 4px;
}

.storelocator-store-top-content {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.storelocator-store-details-toggle-wrap {
    flex-shrink: 0;
    font: 12px / 14px $Arial;
    padding: 0 0 0 10px;
}

.storelocator-store-details-toggle {
    text-decoration: underline;
}

.storelocator-store-details-toggle.active {
    .storelocator-store-details-toggle-chevron {
        transform: rotate(-90deg);
    }
}

.storelocator-store-detail-time {
    font-size: 12px;
    margin: 15px 0 0;
}

.cc-disclaimer {
    margin: 20px 0 0;
    text-align: right;
    font-size: 11px;
    font-style: italic;
}

// Click-and-collect dialog on PDP/cart
.storelocator-dialog-active {
    // no scroll on page body
    @include media('<=desktop') {
        overflow: hidden;
        position: fixed;
    }
}

.clickAndCollectDialog {
    .storelocator-content {
        @include media('>=desktop') {
            height: calc(768px - 60px);
            overflow-y: auto;
        }
    }

    .checkout-store-data-content {
        @include media('>=desktop') {
            margin: 0 -10px;
        }
    }
}

.storelocator-header {
    @include media('>=desktop') {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $grey-bg;
    }
}

.storelocator-top-title {
    @include media('>=desktop') {
        padding: 15px;
        flex-grow: 1;
        max-width: calc(100% - 60px);
    }
}

.storelocator-middle-title {
    border-top: 1px solid $light-grey;

    @include media('>=desktop') {
        padding: 15px 15px 0;
    }
}

.storelocator-mobile-close-panel {
    position: absolute;
    top: 9px;
    left: 10px;
    width: calc(100% - 20px);
    background: $white;
    height: 40px;
    z-index: 1;

    @include media('>=desktop') {
        top: 0;
        left: auto;
        right: 0;
        width: 60px;
        height: 59px;
    }
}

.checkout-store-controls {
    justify-content: space-between;
    display: none;

    @include media('>=desktop') {
        display: flex;
        margin-top: 15px;
    }
}

.checkout-store-mobile-thumb-link {
    line-height: 50px;
    padding-left: vw(20px);

    .clickAndCollectDialog & {
        line-height: 25px;
    }
}

.storelocator-subtitle {
    font: 11px / 12px $Arial;
    margin: 0 0 10px;
}

.storelocator-ufd-subtitle {
    @include media('>=desktop') {
        text-align: center;
    }
}

.storelocator-collect-subtitle {
    @include media('>=desktop') {
        margin: 0 15px 10px;
    }
}

.storelocator-ufd-wrapper {
    padding: 0 0 15px;

    @include media('>=desktop') {
        padding: 15px;
    }
}

.storelocator-ufd-remove-wrapper {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 20px;

    .storelocator-ufd-your-code {
        flex-grow: 1;
        font: 16px / 18px $Arial;
    }

    .ufd-remove-btn {
        width: auto;
        min-width: 125px;
        margin: 0 0 0 10px;
        flex-shrink: 0;
    }
}

.storelocator-ufd-form-wrapper {
    @include media('>=desktop') {
        max-width: 345px;
        margin: 0 auto;
    }
}

.storelocator-ufd-slot {
    font: 11px / 12px $Arial;

    @include media('>=desktop') {
        text-align: center;
    }
}

.storelocator-clickandcollect-wrapper {
    @include media('>=desktop') {
        display: flex;
        flex-direction: row;
        position: relative;
    }
}

.storelocator-col-map {
    padding: 15px;

    @include media('>=desktop') {
        flex-grow: 1;
        max-width: calc(100% - 375px);
    }

    .google-map-wrapper {
        margin: 0;
        height: 235px;

        @include media('>=tablet') {
            height: 570px;
        }

        @include media('>=desktop') {
            height: calc(768px - 60px - 30px);
        }
    }
}

.storelocator-col-list {
    padding: 20px 0 0;

    @include media('>=desktop') {
        width: 375px;
        padding: 15px;
    }
}

.storelocator-no-stores-for-product {
    padding: 15px;
    font: 14px/16px $Arial;
    color: $red;
    text-align: center;
}

// In-store-pick-up dialog on checkout: additional elements on details
.storelocator-store-detail-status-group {
    margin: 15px 0 0;
}

.storelocator-store-detail-hours-info {
    margin: 15px 0 0;
}

.storelocator-store-detail-actions {
    margin: 15px 0 0;

    .storelocator-btn {
        margin: 5px 0 0;
    }
}

.whatsappstore-list-icon-wrapper {
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    top: 4px;
}
