// Headings
%heading {
    font-family: $Saira;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: normal;
    color: $black;
    margin: 0;
    padding: 0;
}

%h1 {
    @extend %heading;
    line-height: 38px;
    font-size: 40px;
}

%h2 {
    @extend %heading;
    line-height: 24px;
    font-size: 20px;
}

%h3 {
    @extend %heading;
    line-height: 24px;
    font-size: 16px;
}

%h4 {
    @extend %heading;
    line-height: 16px;
    font-size: 14px;
}

%h5 {
    @extend %heading;
    line-height: 16px;
    font-size: 12px;

    @include media('>=desktopXL') {
        font-size: 14px;
    }
}

%h6 {
    font-family: $Univers;
    color: $black;
    line-height: 20px;
    font-size: 16px;
    font-weight: normal;
}

// Body
%body {
    font-family: $Univers;
    color: $black;
}

%body1 {
    @extend %body;
    line-height: 24px;
    font-size: 16px;

    @include media('>=tablet', '<desktopXL') {
        font-size: 14px;
    }
}

%body2 {
    @extend %body;
    line-height: 24px;
    font-size: 14px;

    @include media('>=tablet', '<desktopXL') {
        font-size: 12px;
    }
}

// Subtitle
%subtitle {
    font-family: $Univers;
    color: $black;
}

%subtitle1 {
    @extend %subtitle;
    line-height: 24px;
    font-size: 16px;
}

%subtitle1-bold {
    @extend %subtitle1;
    font-weight: bold;
}

%subtitle2 {
    @extend %subtitle;
    line-height: 20px;
    font-size: 14px;
}

// Buttons
%button1 {
    font-family: $Saira;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    text-transform: none;
    color: $black;
}

%button2 {
    font-family: $Univers;
    text-decoration: underline;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;

    @include media('>=desktopLarge') {
        font-size: 12px;
    }
}

%button3 {
    font-family: $Univers;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: 400;
    color: $black;
    text-decoration: none;
    font-size: 14px;

    @include media('>=desktopLarge') {
        font-size: 12px;
    }
}

%buttonViewMore {
    font-family: $Saira;
    font-size: 16px;
    text-transform: none;
    line-height: 20px;
    text-decoration: none;
    letter-spacing: normal;
}

%buttonViewMoreLink {
    @extend %buttonViewMore;
    display: block;
    border: 1px solid $black;
    padding: 9px 30px;
    text-align: center;

    &:hover {
        background-color: $light-grey;
        color: $black;
        border-color: $light-grey;
    }
}

%discoveryCTA {
    font-family: $Saira;
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    display: inline-block;
    font-weight: bold;
    letter-spacing: normal;
    text-transform: none;
    overflow: hidden;

    &:hover {
        text-decoration: underline;
    }

    &::before {
        content: "\2022";
        display: inline-block;
        margin-right: 8px;
        font-size: 30px;
        vertical-align: middle;
        line-height: 20px;
    }
}

// Links
%link1 {
    text-decoration: none;
    color: $black;

    &:hover {
        color: $medium-grey;
    }
}

%fakeButton {
    display: inline-block;
    font-family: $Saira;
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    text-align: center;
    border: 1px solid $black;
    padding: 10px 30px;
}

// UIs, label and components
%ui {
    font-family: $Univers;
    color: $black;
    line-height: 20px;
}

%ui1 {
    @extend %ui;
    font-size: 14px;
    text-transform: none;
    font-weight: normal;
    letter-spacing: normal;
}

%ui1-grey {
    @extend %ui1;
    color: $dark-grey2;
}

%ui1-bold {
    @extend %ui1;
    font-weight: bold;
}

%ui2 {
    @extend %ui;
    font-size: 14px;
    font-weight: bold;
}

%ui2-grey {
    @extend %ui2;
    color: $dark-grey2;
}

%ui2-grey-bold {
    @extend %ui2-grey;
    font-weight: bold;
}

%ui3 {
    @extend %ui;
    font-weight: normal;
    font-size: 12px;
}

%ui3-bold {
    @extend %ui3;
    font-weight: bold;
}

%ui3-grey {
    @extend %ui3;
    color: $dark-grey2;
}

%ui3-italic {
    @extend %ui3;
    font-style: italic;
}

%ui4 {
    @extend %ui;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    line-height: 24px;
}

%ui4-bold {
    @extend %ui4;
    font-weight: bold;
}

%italic {
    font-family: $TimesNewRoman;
    font-style: italic;
}

%italic1 {
    @extend %italic;
    font-size: 18px;
}

// Quote
%quote {
    font-family: $Univers;
    color: $black;
    line-height: 40px
}

%quote1 {
    @extend %quote;
    letter-spacing: -1px;
    font-size: 32px;

    @include media('>=desktopLarge', '<desktopXL') {
        font-size: 36px;
        line-height: 48px;
    }

    @include media('>=desktopXL') {
        font-size: 52px;
        line-height: 72px;
    }
}

%quote2 {
    @extend %quote;
    text-transform: uppercase;
    letter-spacing: -3px;
    font-size: 40px;

    @include media('>=tablet', '<desktopLarge') {
        font-size: 60px;
    }

    @include media('>=desktopLarge', '<desktopXL') {
        font-size: 104px;
    }

    @include media('>=desktopXL') {
        font-size: 144px;
    }
}
