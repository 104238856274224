// =================================
//    FAQ Page
// =================================
// =================================
// SONIA RYKIEL GRAPHICAL CHART 
// =================================

// updated classes
.faq-info-desc {
    @extend %body1;
    display: none;

    &.active {
        display: block;
    }
}

.contactus-counter-message {
    @extend %ui4;
    text-align: left;
}

.faq-info-btn {
    padding: vw(30px) 30px vw(30px) 0;
    text-align: left;
    border-bottom: 2px solid $grey2;
    cursor: pointer;
    position: relative;

    @include media('>=tablet') {
        padding: 14px 30px 14px 0;
    }

    &.active {
        color: $black;
        border: 0;

        .faq-arrow::before {
            @include triangle-up();
        }
    }
}

.customerservice-title {
    text-align: center;
    margin-bottom: 35px;

    @include media('>=desktop') {
        margin: 70px 0 35px;
    }
}

.contactus-title {
    margin: 0;
    text-align: center;

    @include media('>=desktop') {
        margin: 70px 0 0;
    }
}

.contactus-title-secondary {
    padding: 20px 0;
}

.optin-item-title {
    @extend %ui2;
    margin: 0 0 15px;

    @include media('>=desktop') {
        margin: 0 0 20px;
    }
}

// new classes

// =================================
// VILEBREQUIN GRAPHICAL CHART 
// =================================

.faq-wrapper {
    margin: vw(100px) 0;

    @include media('>=desktop') {
        margin: 70px 0;
    }
}

.customerservice-separator {
    margin: 20px auto;
}

.optin-wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 8px;

    @include media('>=tablet') {
        flex-direction: row;
        align-items: stretch;
        margin: 0 0 25px;
    }
}

.optin-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 215px;
    padding: 15px;
    margin: 0 12px 12px;
    border: 1px solid $blue-light;
    text-align: center;

    @include media('>=tablet') {
        margin: 0 10px;
    }

    @include media('>=desktop') {
        width: 245px;
        padding: 20px 15px;
    }
}

.optin-item-option {
    white-space: nowrap;
}

.optin-desc-bottom {
    text-align: center;
    color: $blue;
    font-size: vw(28px);
    margin: 0 0 5px;

    @include media('>=tablet') {
        font-size: 16px;
        margin: 0 0 35px;
    }

    @include media('>=desktop') {
        font-size: 12px;
        margin: 0 0 25px;
    }
}

.faq-arrow {
    position: absolute;
    right: 5px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 4px;

    &::before {
        content: '';
        display: block;
        @include triangle-down();
    }
}

// Sitemap
.sitemap-title {
    margin: vw(60px) 0;
    text-align: center;

    @include media('>=tablet') {
        margin: 30px 0;
    }
}

.sitemap-wrapper {
    margin: 0 0 vw(60px);
    font-size: vw(24px);
    flex-flow: column nowrap;

    @include media('>=tablet') {
        margin: 0 0 30px;
        font-size: 12px;
    }

    @include media('>=desktop') {
        flex-flow: row nowrap;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    p {
        margin: 10px 0;
    }

    a {
        margin: 3px 0;
    }

    ul {
        padding: 0 0 0 15px;
        margin: 5px 0;
    }
}

.sitemap-column {
    flex-flow: column nowrap;
    padding: 0 0 vw(70px);
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 100%;

    @include media('>=tablet') {
        font-size: 12px;
        padding-bottom: 70px;
    }

    @include media('>=desktop') {
        padding: 0 2%;
        flex-basis: 33%;
    }
}

// Contactus page
.contactus-wrapper {
    background-repeat: repeat;
    background-position: center center;
    padding: vw(70px) 0;

    @include media('>=tablet') {
        padding: 70px 0;
    }

    @include media('retina2x') {
        background-size: 50% auto;
    }

    @include media('>=desktop') {
        padding: 70px 200px 86px; // bottom + 16px for background image with footer wave
    }

    @include media('<desktop') {
        background: none !important;
    }
}

.contactus-outside-container {
    background: $white;

    @include media('>=desktop') {
        border: 5px solid transparent;
        outline: 10px solid $white;
        background-clip: padding-box;
        overflow: hidden;  // fix FF issue with outline
    }
}

.contactus-inside-container {
    padding: 0 10px;

    .gl_form-group {
        margin: vw(60px) 0 0;

        @include media('>=desktop') {
            margin: 30px 0 0;
        }

        + .gl_form-group {
            margin-top: 0;
        }
    }

    .consent {
        .gl_form-group + .gl_form-group {
            margin-top: 10px;
        }
    }

    .gl_checkbox {
        text-align: left;
    }
}

.contactus-confirmation-message {
    margin: 0 0 vw(70px);
    padding: 2%;
    text-align: center;

    @include media('>=desktop') {
        margin: 0;
        padding: 0;
    }
}

.mexicoineform-title {
    margin: 0 0 20px;

    @include media('>=desktop') {
        margin: 70px 0 20px;
    }
}

.contactus-submit {
    margin-bottom: vw(80px);

    @include media('>=desktop') {
        margin-bottom: 40px;
    }

    > div { // Google reCaptcha wrapper
        display: flex;
        justify-content: center;
    }
}

.contactus-contact-information {
    margin-bottom: 0;
    text-align: center;

    &.contactus-submit-error {
        color: $red;
    }
}

.contactus-required {
    text-align: right;
}

.contactusWrapper .main-footer {
    margin-top: -16px;
}

.grecaptcha-badge {
    margin-bottom: 20px;
}

// Do-not-sell-personal-information page
.donotsellpersonalinfo-bottom-desc {
    @extend %body1;
    margin: 20px 0;
}

.donotsellpersonalinfo-disclaimer {
    @extend %body1;
    margin: 20px 0 30px;
}
