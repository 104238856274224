// =================================
//    Footer
// =================================
// =================================
// SONIA RYKIEL GRAPHICAL CHART
// =================================

// updated classes

/* Back to top button */
.backtotop-circle-btn {
    margin: 5px 0;
    width: 46px;
    height: 46px;
    background: $black;
    border-radius: 50%;
    flex-direction: column;

    @include media('>=desktop') {
        margin: 15px 25px;
    }

    &:focus {
        outline-offset: 2px;
    }
}

.prefooter {
    background-color: $white;
    padding: 51px 0 40px 0;
    margin: 0 20px;

    @include media('>=tablet') {
        padding: 70px 0 60px 0;
        margin: 0 32px;
    }

    @include media('>=desktop') {
        padding: 76px 0 0 0;
        margin: 0 33px;
    }

    @include media('>=desktop', '<desktopLarge') {
        border-bottom: 1px solid $dark-grey2;
    }

    @include media('>=desktopLarge') {
        padding-top: 68px;
    }

    @include media('>=desktopXL') {
        padding-top: 80px;
        margin: 0 30px;
    }
}

.pre-footer-picto-col {
    color: $white;
    margin: 0 0 25px;

    .pre-footer-picto-title {
        @extend %h5;
        color: $black;
        margin: 0 0 7px;

        @include media('>=desktopXL') {
            margin: 0 0 18px;
        }
    }

    @include media('>=desktop') {
        margin: 0 0 58px;
    }
}

.pre-footer-picto-section {
    display: flex;
    flex-direction: column;

    @include media ('>=tablet') {
        flex-direction: row;
        justify-content: space-between;
    }

    @include media ('>=desktopLarge') {
        justify-content: flex-start;
    }
}

.footer-flex-container {
    display: flex;
    flex-direction: column;

    @include media('>=desktop') {
        flex-direction: row;
        justify-content: space-between;
    }
}

/* Special max-width on footer */
.footer-container {
    padding: 0 20px 40px 20px;

    @include media ('>=tablet') {
        padding: 0 32px 40px;
    }

    @include media ('>=desktop') {
        padding: 51px 33px 56px;
    }

    @include media ('>=desktopLarge') {
        padding: 35px 33px 54px;
    }

    @include media ('>=desktopXL') {
        padding: 60px 30px 144px;
    }
}

/* Footer section title */
.footer-title {
    @extend %h5;
    color: $white;
    text-decoration: none;
}

.main-footer-wrapper {
    background-color: $white;
    position: relative; // parent for back-to-top button
}

/* Footer link */
.footer-item-link {
    @extend %subtitle2;
    line-height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    text-decoration: none;
    color: $dark-grey3;

    &:hover {
        color: $black;
    }

    @include media('>=desktop') {
        line-height: 20px;
        display: block;
        border: none;
        color: $dark-grey2;
    }
}

.footer-subscribe-input {
    background-color: transparent;

}

.lifebuoysvg,
.turtlesvg {
    .st0 {
        fill: $black;
    }
}

// Footer accordion
.footer-input {
    max-width: 100%;

    @include media('>=tablet') {
        max-width: 50%;
    }

    @include media('>=desktop') {
        max-width: 80%;
    }

    @include media('>=desktopLarge') {
        max-width: 65%;
    }

    @include media('>=desktopXL') {
        max-width: 45%;
    }
}

.footer-newsletter-mandatory {
    max-width: 100%;

    @include media('>=tablet') {
        max-width: 50%;
    }

    @include media('>=desktop') {
        max-width: 80%;
    }

    @include media('>=desktopLarge') {
        max-width: 65%;
    }

    @include media('>=desktopXL') {
        max-width: 45%;
    }
}

.footer-control {
    .svgchevron,
    .plussvg,
    .minussvg {
        width: 10px;

        .st0 {
            fill: $black;
        }
    }
}

/* Footer forms */
.footer-form-group {
    padding-bottom: 44px;
}

.footer-form-group.gl_form-group {
    .gl_error-message,
    .backend-error-message {
        background-color: $white;
        padding: 0 10px;
        max-width: 100%;

        @include media('>=tablet') {
            max-width: 50%;
        }

        @include media('>=desktop') {
            max-width: 80%;
        }

        @include media('>=desktopLarge') {
            max-width: 65%;
        }

        @include media('>=desktopXL') {
            max-width: 45%;
        }
    }
}

// new classes
.prefooter-wrapper {
    background-color: $white;
}

.pre-footer-picto-column {
    display: flex;
    max-width: 100%;
    flex-direction: column;

    @include media('>=tablet') {
        width: 33.33%;
        padding-right: 45px;
    }

    @include media('>=desktop') {
        padding-right: 65px;
    }

    @include media('>=desktopLarge') {
        width: 25%;
        padding-right: 90px;
    }
}

.footer-column-left {
    max-width: 100%;

    @include media('>=desktop') {
        width: 40%;
    }

    @include media('>=desktopLarge') {
        width: 50%;
    }
}

.footer-column-right {
    max-width: 100%;

    @include media('>=desktop') {
        width: 60%;
    }

    @include media('>=desktopLarge') {
        width: 50%;
    }
}

.footer-row-section {
    flex-direction: column;

    @include media('>=desktop') {
        margin: 0 0 41px;
        flex-direction: row;
        justify-content: space-between;
    }

    @include media('>=desktopXL') {
        margin: 0 0 56px;
    }
}

.footer-row-item {
    padding: 0;
    flex-basis: 100%;

    .footer-title {
        @extend %h5;
        color: $white;
        margin: 0 0 8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0;

        @include media('>=desktop') {
            margin: 0 0 7px;
        }

        @include media('>=desktopXL') {
            margin: 0 0 18px;
        }

        .footer-control-close {
            display: none;
        }
    }

    .footer-title-newsletter {
        @extend %h5;
        color: $black;
        margin: 0 0 12px;
    }

    .footer-title.active {
        .footer-control-open {
            display: none;
        }

        .footer-control-close {
            display: flex;
        }
    }

    .footer-title-item {
        @extend %h5;
        flex-grow: 1;
        color: $black;
        line-height: normal;
    }

    .footer-subscribe-btn {
        display: flex;
        align-items: center;
        background: transparent;
        width: auto;
        padding: 0 25px;

        .footer-subscribe-btn-label {
            @extend %button1;
            color: $black;
            text-transform: none;

            &:hover {
                color: $dark-grey2;
            }
        }

        @include media('>=desktop') {
            width: auto;
        }
    }

    .footer-subscribe-label {
        left: 15px;
        background: $white;
    }

    @include media('>=desktop') {
        flex-basis: 50%;
    }
}

.footer-social-section {
    border-top: 1px solid $dark-grey2;
    padding-top: 19px;
    margin-top: 10px;

    @include media('>=tablet') {
        padding-top: 28px;
    }

    @include media('>=desktop') {
        border: none;
        padding-top: 0;
        margin-top: 0;
    }
}

.backtotop-circle-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;

    @include media('>=desktop') {
        justify-content: flex-end;
    }
}

.pre-footer-picto-subtext {
    @extend %subtitle2;
    color: $dark-grey3;
    margin: 0;

    @include media('>=desktopLarge') {
        color: $dark-grey2;
    }
}

.footer-item-list {
    display: none;
    flex-direction: column;

    &.active {
        display: flex;
    }

    @include media('>=tablet') {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    @include media('>=desktop') {
        flex-direction: column;
        justify-content: flex-start;
    }
}

.footer-item-list-li {
    max-width: 100%;

    @include media('>=tablet') {
        width: 50%;
    }

    @include media('>=desktop') {
        width: 100%;
    }
}

.footer-item-list {
    .footer-item-list-li:last-child {
        margin: 0 0 21px;

        @include media('>=tablet') {
            margin: 0 0 29px;
        }

        @include media('>=desktop') {
            margin: 0;
        }
    }
}

.footer-item-list-social {
    @include media('>=tablet') {
        flex-direction: column;
    }
}

.footer-item-social {
    line-height: 20px;
}

// deleted classes

/* .footer-info-item:last-child {
    border-bottom: 1px solid $footer-item-link;

    @include media('>=desktop') {
        border: none;
    }
} */

/* .chevron-container {
    height: 16px;
    transform: rotate(-90deg);

    .svgchevron {
        width: 10px;
    }

    .st0 {
        fill: $white;
    }
} */

/* .footer-info-section {} */

/* .footer-info-item {} */

/* .footer-action-section {} */

/* .footer-action-item {} */

/* .footer-form-group {
    .gl_form-mandatory {
        @include media('>=desktop') {
            width: 280px;
            padding-bottom: 0;
        }
    }
} */

/* .pre-footer-icon {
    width: auto;
    max-width: 100%;
    margin: 0 auto 15px;
} */

// Footer accordion
/* .lifebuoysvg,
.turtlesvg,
.eyesvg {
    .st0 {
        fill: $blue;
    }
} */

/* .footer-icon-svg {
    margin-right: 15px;

    svg {
        width: 22px;
    }
} */

// Footer Wave
/* .footer-waves-container {
    position: relative;
    text-align: center;
    margin: 0 auto;
    height: 16px;
    top: -15px;

    .footer-waves-svg {
        width: 100%;
    }

    .footer-waves {
        fill: $light-grey;
    }
} */

// Footer Social
/* .follow-us {
    .footer-title {
        @include media('>=desktop') {
            margin: 0 0 16px;
        }
    }
}

.footer-social-icons {
    justify-content: space-between;

    @include media('>=tablet') {
        justify-content: flex-start;
        height: 50px;
    }

    @include media('>=desktop') {
        justify-content: flex-start;
        height: 36px;
    }
}

.footer-social-icon {
    display: block;

    @include media('>=tablet') {
        margin-right: 9px;
    }

    @include media('>=desktop') {
        margin-right: 12px;
    }
}

.wechatsvg,
.youtubesvg,
.instagramsvg,
.pinterestsvg,
.facebooksvg,
.twittersvg {
    width: 50px;
    height: 50px;
    background-color: $white;
    border: 5px solid $white;
    border-radius: 50%;

    @include media('>=desktop') {
        width: 36px;
        height: 36px;
        border: none;
    }

    .st0 {
        fill: $white;
    }

    .st1 {
        fill: $blue;
    }

    &:hover .st1 {
        fill: $blue-hover;
    }
}

.instagramsvg {
    .st2 {
        stroke: $blue;
    }

    &:hover .st2 {
        stroke: $blue-hover;
    }
}

// Footer widget
.footer-widget-section {
    padding: 30px 15px 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    @include media('>=desktop') {
        flex-direction: column-reverse;
        padding: 0;
        position: absolute;
        top: 15px;
        right: 0;
    }
} */

/* .turtlecirclesvg {
    width: 108px;
    height: 108px;

    .st0 {
        fill: $blue;
    }

    .st1 {
        stroke: $blue;
    }
} */

/* .footer-review-container {
    padding: 0 0 0 8px;
    text-align: center;

    @include media('>=desktop') {
        padding: 0 0 15px;
    }
} */

/* .site-reviews {
    display: none;
    width: 108px;
    height: 108px;
    padding: 2px;
    border-radius: 50%;
    border: 3px solid $blue;
    background-color: $blue;
    white-space: nowrap;
    overflow: hidden;

    .site-reviews-link {
        display: block;
        text-decoration: none;
        color: $white;

        &:hover {
            color: $white;
        }
    }

    .site-reviews-score {
        display: block;
        height: 35px;
        border-bottom: 1px solid $white;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0.02em;
    }

    .site-reviews-total {
        display: block;
        height: 30px;
        border-bottom: 1px solid $white;
        font-size: 10px;
        line-height: 12px;
    }

    .site-reviews-bottom {
        display: block;
        height: 35px;
    }

    .site-reviews-title {
        max-width: 87px;
        margin: 0 auto;
        font-size: 9px;
        line-height: 18px;
        font-style: italic;
        overflow: hidden;
    }
}

.site-reviews-rateyo-stars {
    display: block;
    padding: 3px 0 0;

    &.jq-ry-container[readonly="readonly"] {
        cursor: pointer;
    }

    .jq-ry-normal-group {
        svg {
            fill: $dark-grey;
        }
    }

    .jq-ry-rated-group {
        svg {
            fill: $white;
        }
    }
} */

// WeChat dialog
/* .weChatDialog { // jQuery UI dialog class
    position: fixed !important;
    top: 0 !important;
    left: 100vw !important;
    height: 100vh !important;
    width: 100vw !important;
    transition: transform 0.5s linear;

    &.ui-dialog {
        padding: 0;
        max-width: 100vw;
        background-color: $blue;

        @include media ('>=tablet') {
            max-width: 60vw;
        }
    }

    &.opened {
        transform: translateX(-100%);
    }

    .ui-dialog-titlebar {
        display: none;
    }

    .ui-dialog-titlebar-close {
        .ui-button-icon::before {
            color: $white;
        }
    }
}

.wechat-dialog-wrapper {
    background-color: $blue;
    height: 100%;
    min-height: 100vh;
    width: 100%;

    .main-footer & {
        display: none;
    }
}

.wechat-dialog-content {
    height: 100vh;
    min-height: 100vh;
    width: 100%;
    text-align: center;
    flex-flow: column nowrap;
    justify-content: center;
    align-content: center;
    align-items: content;
    color: $white;
}

.weChatIcon {
    cursor: pointer;
}

.wechat-qrcode {
    width: auto;
}

.wechat-title {
    font-family: $MontserratBold;
    font-size: 20px;
    text-transform: uppercase;
}

.wechat-subtitle {
    font-family: $Arial;
    font-weight: bold;
    font-size: 14px;
    padding-bottom: 20px;

    @include media('>=tablet') {
        padding-bottom: 30px;
    }
}

.wechat-account {
    font-family: $Arial;
    font-weight: bold;
    font-size: 20px;
    padding-top: 30px;
} */

// Footer bottom websites links
/* .footer-website-block {
    padding: 10px 15px 40px;

    @include media('>=desktop') {
        padding: 0 0 85px;
    }
}

.footer-website-items {
    font: 14px / 16px $Arial;

    @include media('>=tablet') {
        display: flex;
        flex-flow: row nowrap;
        font-size: 12px;
    }
}

.footer-website-item {
    margin: 0 0 10px;

    @include media('>=tablet') {
        margin: 0;

        &::after {
            content: '|';
            font-size: 14px;
            padding: 0 5px;
        }

        &:last-child::after {
            content: none;
        }
    }
}

.footer-website-link {
    text-decoration: none;
} */


// =================================
// VILEBREQUIN GRAPHICAL CHART
// =================================

// Prefooter icons

.footer-container {
    position: relative; // parent for footer widget
}

.svgmaplocation {
    width: 22px;
    height: 22px;

    .st0 {
        fill: $blue;
    }

    .st1 {
        fill: $white;
    }
}

.footer-main-logo-container {
    padding: 0 8px 0 0;

    @include media('>=desktop') {
        padding: 0;
    }
}

// crisis Dialog
.crisis-modal {
    font: 14px $Arial;
    font-weight: bold;
    padding: 60px 20px;

    @include media('>=tablet') {
        padding: 120px 40px;
        font-size: 18px;
    }
}

.crisisDialog {
    color: $blue;

    .ui-dialog-titlebar {
        height: 0;
    }

    .ui-dialog-titlebar-close {
        top: 8px;
        right: 8px;
        z-index: 3;

        .ui-button-icon::before {
            font-size: 36px;
        }
    }
}
