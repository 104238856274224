// =================================
// SONIA RYKIEL GRAPHICAL CHART
// =================================

// updated classes
////////////////////
// Small blog article tile: general styles
// - blog pages: blog home page & blog category page
// - pdp: blog tile
////////////////////
.small-blog-article-title {
    @extend %h2;
    margin: 0 0 18px;
}

.small-blog-tile-date-wrapper {
    @extend %ui1-grey;
    display: block;
    text-decoration: none;
    margin: 0 0 6px;
}

.small-blog-tile-date {
    display: inline-block;

    &::after {
        content: "\2022";
        display: inline-block;
        margin: 0 5px 0 8px;
    }
}

.small-blog-tile-category {
    @extend %ui2-grey-bold;
    display: inline-block;
}

.small-blog-article-content {
    margin: 27px 0 50px;

    @include media('>=tablet') {
        margin: 25px 0 0;
    }
}

.small-blog-article-img-wrapper {
    display: block;
    text-decoration: none;
    margin: 0 0 20px;
}
////////////////////
// Small blog article tile end
////////////////////

.blog-tile-more {
    @extend %discoveryCTA;
    display: block;

    &:hover {
        color: $black;
    }
}

// new classes

// deleted classes (commented, just the classname for trace)
/*
.blog-tile-title {
    font: 11px / 14px $MontserratBold;
    margin-top: 20px;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    padding: 0 20%;

    @include media('>=tablet') {
        .article-main-content & {
            line-height: 22px;
            font-size: 22px;
        }
    }

    @include media('>=desktop') {
        .article-main-content & {
            line-height: 11px;
            font-size: 11px;
        }
    }
}

.blog-tile {
    width: 100%;
    text-align: center;
}

.blog-tile-separator {
    width: 100%;
    margin-top: 10px;
}

.blog-tile-date {
    font: 10px / 10px $MontserratBold;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;

    @include media('>=desktop') {
        margin-top: 10px;
    }
}

.header-blog-article {
    margin-bottom: vw(60px);

    @include media('>=tablet') {
        margin-bottom: 50px;

        .header-menu-middle-section & {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.blog-separator {
    display: none;
}
//mobile styles for the blog will be added in blog integration task
.header-blog-article-tile-wrapper {
    &:not(:first-of-type) {
        margin-top: 50px;

        @include media('>=desktop') {
            margin-top: 0;
        }
    }
}

.header-blog-article-row {
    width: 100%;
}
*/

// =================================
// VILEBREQUIN GRAPHICAL CHART
// =================================

//blog category tiles
.blog-other-category-li {
    width: 100%;
}
