.fullwidth-richtext-component {
    margin: 30px 20px;
    color: $black;

    @include media('>=tablet') {
        margin: 75px 32px;
    }

    @include media('>=desktop') {
        margin: 100px 50px;
    }
}

.fullwidth-richtext-title {
    @extend %h2;
    margin: 0 0 20px;
}

.fullwidth-richtext-text {
    @extend %subtitle1;
}
