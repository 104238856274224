// PD blogarticleheader component style deviations with _blog_landing.scss
.blogarticleheader-component {
    .blog-big-article-wrapper {
        margin: 0 0 45px;

        @include media('>=tablet') {
            margin: 0 0 93px;
        }

        @include media('>=desktop') {
            margin: 0 0 115px;
        }

        @include media('>=desktopLarge') {
            margin: 0 0 130px;
        }

        @include media('>=desktopXL') {
            margin: 0 0 155px;
        }
    }
}
