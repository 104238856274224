.title-richtext-component {
    margin: 45px 20px 60px;
    color: $black;

    @include media('>=tablet') {
        margin: 90px auto 120px;
    }

    @include media('>=desktop') {
        margin: 100px auto 100px;
    }
}

.title-richtext-title {
    @extend %h2;
    margin: 0 0 20px;
}

.title-richtext-text {
    @extend %body1;
    margin: 0 auto;

    @include media('>=tablet') {
        max-width: 585px;
    }

    @include media('>=desktop') {
        max-width: 640px;
    }

    @include media('>=desktopXL') {
        max-width: 920px;
    }

    p {
        margin: 0;
    }
}
