.paragraph-image-component {
    margin: 45px 0;
    color: $black;

    @include media('>=tablet') {
        margin: 100px 32px;
    }
}

.paragraph-image-content {
    padding: 30px 20px 0;

    @include media('>=tablet') {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-end;
        padding: 0;
    }

    &.position-right {
        @include media('>=tablet') {
            justify-content: flex-end;
        }
    }
}

.paragraph-image-col-text {
    @include media('>=tablet') {
        flex: 0 0 292px;
        max-width: 292px;
    }

    @include media('>=desktop') {
        flex: 0 0 324px;
        max-width: 324px;
    }

    @include media('>=desktopLarge') {
        flex: 0 0 355px;
        max-width: 355px;
    }

    @include media('>=desktopXL') {
        flex: 0 0 466px;
        max-width: 466px;
    }

    &.position-left {
        @include media('>=tablet') {
            padding: 0 12px 0 0;
        }
    }

    &.position-right {
        @include media('>=tablet') {
            padding: 0 0 0 12px;
        }
    }
}

.paragraph-image-col-img {
    @include media('>=tablet') {
        flex: 0 0 50%;
        max-width: 50%;
    }

    @include media('>=desktopXL') {
        flex: 0 0 65%;
        max-width: 65%;
    }
}

.paragraph-image-image {
    @include media('<desktop') {
        margin: 0 auto;
    }
}

.paragraph-image-link {
    display: block;
    text-decoration: none;
}

.paragraph-image-text {
    // included title-richtext component override
    .title-richtext-component {
        margin: 0;
        max-width: none;
    }

    .title-richtext-text {
        p {
            margin: 0;
        }
    }

    // included pd button link override
    .pd-btn-link.gl_btn {
        width: 100%;
        margin: 20px 0 0;

        @include media('>=tablet') {
            width: 250px;
            margin: 20px 0 0;
        }
    }
}
