// Social media carousel

.socialmedia-wrapper {
    position: relative;
    padding: 0;
    margin: vw(140px) 0 0;
    background-position: 98% top;
    background-repeat: no-repeat;
    background-size: contain;

    @include media('>=desktop') {
        margin: 70px 0 0;
    }

    @include media('<desktop') {
        background: none !important;
    }
}

.socialmedia-carousel {
    padding: 0 35px;

    @include media('>=desktop') {
        padding: 0;
    }

    .slick-dots {
        margin: 40px 0 0;
    }
}

.socialmedia-separator {
    margin: vw(40px) 0;

    @include media('>=desktop') {
        margin: 20px 0;
    }
}

.socialmedia-slide {
    padding: 5px;

    @include media('>=desktop') {
        padding: 0 5px;
    }
}

.socialmedia-title {
    text-align: center;
}

.socialmedia-subtitle {
    text-align: center;
    color: $blue;
    margin: 0 0 -24px 0;
}

.socialmedia-carousel-wrapper {
    text-align: center;
}

.socialmedia-link {
    margin-top: 20px;
    border: 1px solid $blue;
    width: auto;

    @include media('>=desktop') {
        margin-top: 50px;
    }
}

.socialmedia-carousel-desktop {
    margin: 50px 0;
    display: none;

    @include media('>=desktop') {
        display: block;
    }
}

.socialmedia-carousel-mobile {
    margin: vw(100px) 0;
    display: block;

    .slick-dots {
        margin: 60px 0 10px;
    }

    @include media('>=desktop') {
        display: none;
    }

    img {
        display: inline-block;
        vertical-align: middle;
        width: 48%;
        margin: 0 1% 2% 0;
    }
}

// volume promotion msg: in minicart, mobile add-to-cart dialog, on the cart page
.volume-promo-msg {
    text-align: center;
    color: $azure-radiance;
    font: 12px $Arial;
    margin: 20px 0;
}

// need-help top slot: title_html_contentasset.isml
.need-help {
    text-align: center;
    border: 1px solid $light-grey;
    border-width: 1px 0 0;
    padding: 25px 20px;

    @include media('>=tablet') {
        padding: 25px 35px;
    }

    @include media('>=desktop') {
        padding: 25px 35px;
    }

    .need-help-title {
        font: 16px / 19px $Arial;
        text-transform: none;
        letter-spacing: normal;
        font-weight: bold;
    }

    .help-wave-separator {
        margin: 3px 0 8px;
    }

    .need-help-body {
        font: 12px / 14px $Arial;
        color: $blue;
    }
}

// need-help slot on cart page
.full-cart-need-help-wrap {
    .need-help {
        border-width: 0;

        @include media('>=desktop') {
            border-width: 1px 0 0;
        }
    }
}

// need-help bottom slot: globalneedhelpbottom.isml
.need-help-content {
    font-size: vw(22px);
    text-transform: uppercase;
    color: $blue;
    padding: vw(70px) 0;
    border: 1px solid $light-grey;
    border-width: 1px 0 0;

    @include media('>=tablet') {
        padding: 30px 0 70px;
    }

    @include media('>=desktop') {
        font-size: 11px;
        padding: 0;
    }

    .shipping-details-svg {
        width: 64px;
        height: 64px;
    }
}

.need-help-list {
    @include media('>=desktop') {
        margin: 0 auto;
        max-width: 1000px;
    }
}

.need-help-item {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    position: relative;
    padding: 25px 0;
    text-align: center;

    @include media('>=desktop') {
        width: 33%;
    }
}

.need-help-icon-container,
.need-help-item-content {
    display: inline-block;
    vertical-align: middle;
}

.need-help-item-content {
    position: static;
    text-align: left;
    width: 45%;
}

.need-help-icon-container {
    width: 30%;
    margin: 0 5% 0 0;
    text-align: left;
    height: 58px;

    @include media('>=desktop') {
        text-align: center;
    }
}

.need-help-icon {
    position: static;
    display: inline-block;
}
