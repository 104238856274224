.pd-newsletter {
    background-color: $black;
    color: $white;
    padding: 30px 20px;

    .pd-newsletter-title {
        @extend %h2;
        color: $white;
        margin: 0 0 30px;
        text-align: left;
    }

    .pd-newsletter-input {
        background: transparent;
        padding: 2px 110px 0 17px;
        height: 48px;
        border-color: $dark-grey2;
    }

    .pd-newsletter-group {
        margin: 0 auto;
        max-width: 400px;
    }

    .pd-newsletter-btn.gl_input-submit {
        display: flex;
        align-items: center;
        background: transparent;
        width: auto;
        padding: 0 25px;

        .pd-newsletter-btn-label {
            @extend %button1;
            color: $white;
            text-transform: none;

            &:hover {
                color: $dark-grey2;
            }
        }

        @include media('>=desktop') {
            width: auto;
        }
    }

    .pd-newsletter-label {
        left: 15px;
        background-color: $black;
        color: $white;
    }

    .ui-checkboxradio-label {
        color: $white;

        .ui-icon-background {
            background: transparent;
            border-color: $dark-grey2;
        }
    }

    .gl_loading .pd-newsletter-btn {
        display: none;
    }

    .subscription-success {
        display: block;
        @extend %body1;
        color: $white;
    }
}
