// small blog on PD article page
.blog-article-page {
    .small-blog-article-wrapper,
    .small-pdarticle-wrapper {
        margin: 50px 20px;

        @include media('>=tablet') {
            width: calc(50% - 32px - 6px);
            margin: 50px 32px 60px;
        }

        @include media('>=desktop') {
            width: calc(33.33% - 30px);
            margin: 60px 32px 90px;
        }

        &.position-right {
            @include media('>=tablet') {
                margin-left: auto;
            }
        }
    }
}
