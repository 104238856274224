.category-pattern-content-wrapper {
    text-align: center;
    flex-direction: column;
    padding: 0;

    @include media('>=desktopLarge') {
        padding-left: 60px;
        padding-right: 60px;
    }
}

.pattern-content-container {
    position: absolute;
    left: 0;
    top: 0;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: vw(30px);

    @include media('>=tablet') {
        padding: 30px;
    }

    @include media('>=desktop') {
        padding: 10px;
    }
}

.pattern-inner-container {
    border: 10px solid $white;
    height: 100%;
    width: 100%;
    align-items: center;

    @include media('>=desktop') {
        border-width: 5px;
    }
}

.category-pattern-main-title,
.category-pattern-description {
    color: $white;
}

.category-pattern-main-title {
    font: vw(64px) $MontserratBold;

    @include media('>=tablet') {
        font-size: 64px;
    }

    @include media('>=desktop') {
        font-size: vw(50px, $desktopLarge);
    }

    @include media('>=desktopLarge') {
        font-size: 50px;
    }
}

.pattern-header-wave {
    margin: 15px 0;
    transform: scale(1.4);
}

.category-pattern-subtitle {
    text-align: center;
    font: 14px / 18px $Arial;
    color: $white;
    text-transform: uppercase;
    margin: 20px 0;

    @include media('>=tablet') {
        font: 21px / 22px $Arial;
    }
}

.category-pattern-description {
    font: vw(24px) $Arial;
    margin: 10px 0;

    @include media('>=desktop') {
        font-size: 24px;
    }

    @include media('>=desktop') {
        font-size: vw(16px, $desktopLarge);
    }

    @include media('>=desktopLarge') {
        font-size: 16px;
    }
}

//Pattern Search Result Page
.mobile-pattern {
    width: 160px;
    border-radius: 80px;
    margin: vw(50px) auto 0 auto;
}

.category-pattern-view-all {
    margin-top: vw(20px);

    @include media('>=desktop') {
        margin-top: 20px;
    }
}

.pattern-wrapper {
    .search-result-items {
        position: relative;
    }
}
