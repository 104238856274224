////////////////////////////
//CHECKOUT COMMON ELEMENTS
////////////////////////////
// =================================
// SONIA RYKIEL GRAPHICAL CHART
// =================================

// updated classes

.cartpage-header {
    text-align: center;
    padding: 16px 15px 35px;

    @include media('>=tablet') {
        padding: 30px 15px;
        border-bottom: 1px solid $light-grey;
    }
}

.cartpage-title {
    @extend %h4;
    text-align: center;
    margin: 0;

    .cart-empty & {
        margin-bottom: 35px;
    }
}

.full-cart-wave-separator {
    display: none;
}

.forced-quantity,
.item-price,
.bonus-item,
.bonus-item-quantity {
    @extend %ui3;
}

.cartpage-item-list {
    color: $black;
    width: 100%;
    flex-flow: column nowrap;
    border: 1px solid $light-grey;
    border-width: 1px 0 0;

    @include media('>=desktop') {
        border: 0;
        margin: 0 0 5px;
    }

    .bonus-item {
        text-align: right;
    }

    .forced-quantity {
        display: block;
        width: 60px;
        padding: 0 10px;
        text-align: center;
    }

    .item-quantity {
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-end;

        .quantity-range-error {
            @extend %ui3;
            color: $red;
            max-width: 200px;
            white-space: nowrap;
            align-self: center;

            @include media('>=desktop') {
                position: relative;
                top: 25px;
                white-space: normal;
                align-self: auto;
            }
        }
    }

    .item-price {
        text-align: right;
    }

    .price-promotion {
        display: flex;
        flex-direction: column;

        .price-standard {
            @extend %ui3;
        }
    }

    .item-total {
        width: 95px;
        flex-shrink: 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-direction: column;

        .price-total {
            @extend %ui3;
            display: flex;
            flex-direction: column;
            text-align: right;
            margin: 0 0 0 5px;
        }

        .price-unadjusted {
            @extend %ui3;
        }
    }

    .item-giftbox {
        .giftbox-error {
            @extend %ui3;
            color: $red;
        }
    }

    .item-monogram-details {
        flex-grow: 1;
        border-top: 1px solid $light-grey;
        padding: 15px 0;

        @include media('>=desktop') {
            padding: 15px 0;
            margin: 0 15px 0 0;
        }
    }
}

// Shipping bug on cart (deviation from minicart)
.item-product-details {
    flex-grow: 1;

    @include media('>=desktop') {
        padding-right: 10px;
    }

    .mini-cart-name-link {
        @extend %ui3-bold;
    }

    .item-edit-details {
        margin: 10px 0;
    }

    .cart-edit-product,
    .cart-remove-product {
        @extend %ui1;
        display: inline-block;
        text-decoration: underline;
        margin: 0;

        &:hover {
            color: $medium-grey;
        }
    }

    .cart-edit-separator {
        display: inline-block;
    }

    .minicart-product-model.mini-cart-bold,
    .product-variation-attribute {
        @extend %ui1-grey;
        margin: 0;
    }

    .sku {
        @extend %ui3-grey;
    }
}

.giftbox-label-wrapper {
    display: block;
    margin-left: 85px;

    @include media('>=desktop') {
        margin: 0;
    }

    .giftbox-label {
        @extend %subtitle2;
        display: flex;
        flex-direction: row;
        align-items: center;
        line-height: 20px;
    }

    .giftbox-icon-container {
        height: 16px;
    }

    .giftsvg {
        width: 15px;
        margin: 0 0 0 10px;

        .st0 {
            fill: $black;
        }
    }
}

.cart-coupon-title,
.cart-coupon-accordion-title {
    @extend %h4;
    margin: 0 0 10px;
}

// Gift certification on cart
.giftcert-image {
    width: 70px;
    height: 100px;
    display: flex;
    align-items: center;
    background: $light-brown;

    @include media('>=desktop') {
        width: 185px;
        height: 270px;
    }
}

.giftcert-list-item {
    .giftcert-name {
        @extend %h4;
    }

    .giftcert-edit-details {
        margin: 10px 0;
    }

    .giftcert-details-dl {
        @extend %ui1;
        color: $dark-grey2;
        margin: 0;
    }
}

.giftcert-mobile-list-item {
    margin: 10px 0 30px;
}

.cart-row.cart-giftcert-row {
    .item-quantity {
        padding: 0 0 10px;

        @include media('>=desktop') {
            padding: 0 0 5px;
        }
    }
}

// Summary: cart & checkout pages & confirmation page
.order-summary-section,
.confirmation-summary-totals {
    // dl order totals deviation
    .dt-order-totals,
    .dd-order-totals,
    .dt-order-totals.order-total,
    .dd-order-totals.order-total {
        border: 0;
    }

    .dt-order-totals {
        white-space: normal;
    }

    .order-sales-tax {
        @extend %ui3;
        color: $dark-grey2;

        .price-display-component {
            @extend %ui3;
            color: $dark-grey2;
        }
    }

    .dd-order-totals {
        padding: 0 0 0 15px;
    }

    .dt-order-totals.order-total,
    .dd-order-totals.order-total {
        border: 1px solid $light-grey;
        border-width: 1px 0 0;
        margin: 17px 0 0;
    }

    .order-total-label {
        padding: 17px 0 0;
    }

    .order-total-value {
        padding: 17px 0 0 8px;
        overflow: hidden;
    }

    .price-display-component {
        color: $black;
    }

    .order-subtotal {
        @extend %ui1;
    }
}

.cart-coupon-code {
    position: relative;
    padding: 10px 15px 20px;
    background: $white-grey;
    border-width: 0 0 1px 0;

    @include media('>=tablet') {
        padding: 10px 15px 17px;
    }

    @include media('>=desktop') {
        border-width: 1px;
        margin: 0 0 5px;
    }
}

.giftbox-input-label,
.gift-message-checkbox-label {
    @extend %subtitle2;
}

.giftbox-amount-label {
    @extend %ui2;
}

.giftbox-message-info {
    @extend %body2;
    color: $dark-grey2;
    margin: 10px 0 0;
}

.cartpage-update-cart {
    line-height: 100%;
    padding: 13px 20px;
    position: absolute;
    left: -52px;
    top: 0;

    .refreshsvg {
        vertical-align: top;
    }
}

.cart-coupon-remove-wrapper {
    @extend %ui3;
    margin: 20px 0 0;
}


.cart-coupon-remove-promo-msg {
    @extend %ui3;
    color: $medium-grey;
}

.cart-coupon-remove-notapplied {
    @extend %ui3-bold;
    color: $red;
}

.cart-order-discount {
    padding: 10px 15px 20px;
    border: 1px solid $white-grey;
    border-width: 0 0 1px;
    color: $black;

    @include media('>=tablet') {
        padding: 10px 15px 17px;
    }

    @include media('>=desktop') {
        border-width: 1px;
    }
}

.cart-order-discount-title {
    @extend %subtitle1;
    margin: 0 0 10px;
}

.cart-order-discount-item {
    @extend %ui3-italic;
    display: block;
    margin: 0 0 5px;
}

.cart-actions-title {
    @extend %ui3;
    text-align: center;
    margin: 0 0 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .price-display-component {
        @extend %h3;
    }
}

.cart-actions-title-price {
    @extend %ui3-bold;
}

.cart-actions {
    position: relative;
    background-color: $white-grey;
    padding: 13px 15px 15px;

    @include media('>=desktop') {
        margin-top: 36px;
    }
}

.cart-wave-separator {
    display: none;
    margin: 5px 0 10px;

    @include media('>=tablet') {
        margin: 5px 0 13px;
    }
}

.cart-empty-message {
    @extend %ui3;
    text-align: center;
    margin: 0 0 28px;

    @include media('>=tablet') {
        margin: 0 0 24px;
    }
}

.cartpage-input-quantity {
    text-align-last: center;
    min-width: 65px;
    max-width: 65px;

    &.has-error {
        border-color: $red;
    }
}

.order-summary-title {
    @extend %h4;
    margin: 0 0 15px;
}

.gift-box-counter-wrapper {
    @extend %ui3-grey;
}

// Summary: cart & checkout pages
.order-summary-section {
    background: $white-grey;
    border-width: 1px 0;
    padding: 10px 15px 12px;

    @include media('>=desktop') {
        border-width: 1px;
    }
}

.cart-row {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    border: 1px solid $white-grey;
    border-width: 0 1px 1px;
    margin: 0 15px;

    @include media('>=desktop') {
        margin: 0;
    }
}

.item-shipment,
.item-giftbox {
    padding: 15px 0;
    flex-grow: 1;

    @include media('>=desktop') {
        border-top: 1px solid $light-grey;
    }
}

.item-empty,
.item-monogram-characters {
    @include media('>=desktop') {
        width: 85px;
        padding: 0 15px;
    }
}

.dt-order-totals,
.dd-order-totals {
    order: 1; // default order
    border-top: 1px solid $white-grey;
    flex-grow: 1;

    &:first-of-type {
        border-top: 0;
    }
}

.gift-box-amount-selector {
    margin-left: 10px;
    min-width: 65px;
}

.cart-row.cart-row-header {
    padding: 8px 0 11px;
    border: 0;
}

.cart-row.cart-main-row {
    border: 0;
    padding: 0;
    margin: 30px 15px 0;

    @include media('>=desktop') {
        padding: 0 0 15px;
        margin: 45px 0 0;
    }
}

.cart-row.cart-giftbox-row {
    border: 0;

    .item-empty {
        display: none;

        @include media('>=desktop') {
            display: block;
        }
    }
}

.gift-message-apply-to-all {
    @extend %button2;
    display: block;
    text-align: right;
    margin: 5px 0 0;
}

.cart-empty_discover {
    .images-carousel:not(.slick-initialized) {
        .carousel-image-tile {
            padding: 0 4px;
        }
    }
}

.giftbox-block {
    background: $white-grey;
    padding: 25px 16px 8px;
    margin-top: 9px;

    @include media('>=desktop') {
        margin-top: 17px;
    }
}

////////////////////////////
//Checkout Login
////////////////////////////
.checkoutlogin-wrapper {
    @include media('>=desktop') {
        margin-top: 80px;
    }

    .checkout-sub-title,
    .login-section-title {
        @extend %h2;
        text-align: left;
        margin: 0 0 10px;
    }

    .login-account-password {
        padding: 0 0 20px;
    }

    .gl_form-group {
        padding: 0 0 25px;
    }

    .gl_form-group.required .gl_error-message {
        position: relative;
        top: 0;
    }

    .login-account-form-util {
        padding: 0 0 15px;
    }
}

.login-account-guestemail-help {
    @extend %ui3;
    margin: 0 0 18px;

    @include media('>=tablet') {
        margin: 0 0 10px;
    }
}

////////////////////////////
//Checkout header
////////////////////////////
.checkout-progress-indicator {
    padding: 16px 20px 25px 20px;
    margin: 0 auto;
    width: 202px;
    justify-content: space-between;
    align-items: center;

    @include media ('>=tablet') {
        padding: 31px 20px 35px 20px;
        width: unset;
        justify-content: center;
        border-bottom: 1px solid $light-grey;

        .checkout-status-header-item {
            padding: 0 16px;
        }
    }

    .svgwave {
        display: none;
    }
}

.checkout-header-status-link {
    @extend %ui1;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: $dark-grey7;

    .active & {
        color: $black;
    }
}

.checkout-header-step {
    border-radius: 50%;
    line-height: 22px;
    text-align: center;
    width: 22px;
    height: 22px;
    color: $dark-grey7;
    border: 1px solid $dark-grey7;
    flex-shrink: 0;
    flex-grow: 0;

    @include media ('>=tablet') {
        margin-right: 8px;
    }

    .active & {
        border-color: $black;
        color: $black;
    }
}
////////////////////////////
//Checkout header end
////////////////////////////

////////////////////////////
//Checkout Accordion
////////////////////////////
.checkout-main-accordion-item {
    margin: 5px;

    @include media('>=desktop') {
        margin: 0 0 5px;
    }
}

.checkout-step-edit {
    @extend %ui1;
    text-transform: capitalize;
    text-align: right;
    text-decoration: underline;
    letter-spacing: 0;
    cursor: pointer;
}

.checkout-main-accordion-thumb {
    @extend %h4;
    background-color: $white-grey;
    padding: 17px 15px 20px;
    display: none !important;

    .active & {
        display: flex;
        opacity: 1;
    }
}

.checkout-main-accordion-link {
    text-decoration: none;
    cursor: default;

    .enabled & {
        cursor: pointer;
    }
}

////////////////////////////
//Checkout Accordion end
////////////////////////////

////////////////////////////////////
// CHECKOUT SINGLE SHIPPING STEP
////////////////////////////////////
.cart-accordion-content-section {
    position: relative;
}

.checkout-shipping-address-section {
    .postal {
        .form-caption {
            @include media('>=tablet') {
                width: 150%;
            }
        }
    }
}

.checkout-singleshipping-mandatory {
    margin: 20px 0 10px;
}

.checkout-step-title {
    @extend %h4;
}

.checkout-shipping-title {
    @extend %h5;
    margin: 0 0 5px;
}

.address {
    .row {
        margin: 0 -5px;
    }

    .form-col {
        padding: 0 5px;
    }

    .country-description {
        @extend %ui1;
        padding: 0 0 30px;

        @include media('>=tablet') {
            padding: 0 0 10px;
        }
    }
}

////////////////////////////////////
// CHECKOUT SINGLE SHIPPING STEP end
////////////////////////////////////

.switch {
    position: relative;
    height: 28px;
    display: inline-block;
    cursor: pointer;

    .ui-checkboxradio-icon {
        display: none;
    }
}

.switch-input {
    &:checked + .switch .switch-slider {
        background-color: $black;
    }

    &:checked + .switch .switch-slider::before {
        transform: translateX(17px);
    }

    &:focus + .switch .switch-slider {
        outline: auto;
        outline-color: $black;
    }
}

.switch-slider {
    position: absolute;
    top: 0;
    left: 0;
    height: 28px;
    width: 45px;
    background-color: $light-grey;
    transition: 0.4s;
    border-radius: 16px;

    &::before {
        position: absolute;
        content: '';
        height: 22px;
        width: 22px;
        left: 2px;
        bottom: 2px;
        background-color: $white;
        transition: 0.4s;
        border: 1px solid $black;
        border-radius: 50%;
    }
}

.switch-label {
    @extend %ui3;
    display: inline-block;
    height: 28px;
    margin-left: 54px;
    padding-top: 4px;
}

.price-display-component {
    white-space: nowrap;
}

.shippingmethod-details-wrapper {
    @extend %ui1;
    display: inline-block;
}

.shipping-method-form-desc {
    @extend %ui3;
    padding: 0 0 0 30px;
    color: $black;
}

.shipping-method-form-caption {
    @extend %ui3;
    padding: 0 0 0 30px;
    color: $medium-grey;
}

.shippingmethod-price-wrapper {
    text-align: right;

    .standard-shipping {
        font-size: 10px;
    }

    .price-display-component {
        @extend %ui2;
    }
}

.no-shipping-methods {
    @extend %ui3;
    color: $medium-grey;
}

.shipping-promos {
    @extend %ui3;
    padding: 10px 0 0 30px;

    .shipping-promo-callout-msg {
        color: $medium-grey;
    }
}

.singleshipping-notallowed-wrapper {
    @extend %ui3;
    margin: 20px 0;
}

.billing-address-summary {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0 0 20px;

    .mini-address {
        @extend %ui1;
    }

    .mini-address-location {
        margin: 0;
    }
}

// Checkout gift certification
.checkout-giftcert-section {
    position: relative;
    padding: 15px 10px;
    border-bottom: 1px solid $light-grey;
    color: $black;

    @include media('>=desktop') {
        padding: 17px 15px;
    }
}

.checkout-giftcert-accordion-title-toggle {
    position: relative;
    line-height: 22px;
    cursor: pointer;
    margin: 0 0 10px;

    &.active {
        .checkout-giftcert-arrow::before {
            @include triangle-up();
        }
    }
}

.checkout-giftcert-arrow {
    position: absolute;
    right: 5px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 4px;

    &::before {
        content: '';
        display: block;
        @include triangle-down();
    }
}

.checkout-giftcert-accordion {
    display: none;
}

.checkout-giftcert-warning {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    @extend %body1;
    color: $light-red2;
    padding: 15px 10px;
    border: 1px solid $light-grey;

    @include media('>=tablet') {
        padding: 25px 20px;
    }
}

.checkout-giftcert-warning-icon {
    padding: 0 20px 0 0;

    .moneysvg {
        width: 52px;
        height: 52px;
    }

    .st0 {
        fill: $light-red2;
    }
}

.checkout-giftcert-warning-text1 {
    font-weight: bold;
}

.checkout-giftcert-warning-link {
    color: $light-red2;

    &:hover {
        color: inherit;
    }
}

.checkout-giftcert-link {
    @extend %ui1;
    text-decoration: underline;
    flex-shrink: 0;
}

.checkout-giftcert-row {
    @include media('>=tablet') {
        display: flex;
        flex-flow: row nowrap;
    }
}

.checkout-giftcert-input {
    display: flex;
    flex-flow: row nowrap;

    @include media('>=tablet') {
        flex-grow: 1;
    }

    .checkout-giftcert-code-field {
        flex-grow: 1;
        padding: 0 10px 20px 0;
    }

    .checkout-giftcert-code-field.gl_has-success {
        .gl_input-success::before {
            content: none;
        }

        .gl_input-success::after {
            content: none;
        }
    }
}

.checkout-giftcert-error {
    @extend %ui1;
    color: $red;
    text-align: center;
}

.checkout-giftcert-balance {
    @extend %ui1;
    flex-grow: 1;
    text-align: right;
    padding: 0 0 0 15px;

    @include media('>=tablet') {
        text-align: center;
        padding: 0;
    }

    &.error {
        color: $light-red2;
    }

    .balance-value {
        font-weight: bold;
    }
}

.checkout-giftcert-mobile-checking {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    padding: 0 0 10px;
}

.checkout-giftcert-checking {
    @include media('>=tablet') {
        padding: 10px 0 0 30px;
    }
}

.checkout-giftcert-redemption {
    padding: 20px 0 0;
}

.checkout-giftcert-pi {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    padding: 0 0 10px;
}

.checkout-giftcert-pi-text {
    @extend %ui1;
    flex-grow: 1;
    padding: 0 20px 0 0;
}

.checkout-giftcert-pi-remove {
    @extend %ui1;
}

.checkout-giftcert-warn-more {
    @extend %ui1;
    color: $light-red2;
    padding: 10px 0;
}

.checkout-giftcert-pi-amount,
.checkout-giftcert-more-amount {
    font-weight: bold;
}

.checkout-giftcert-pi-amount,
.checkout-giftcert-more-amount,
.checkout-giftcert-more-text {
    display: inline-block;
}

.checkout-giftcert-method-info {
    @extend %ui1;
    color: $light-red2;
}

.billing-payment-methods-title {
    @extend %ui3-bold;
    margin: 0 0 20px;
}

.cvv-tooltip {
    @extend %ui3;
    text-decoration: underline;
}

.billing-sales-rules,
.sales-rules-label {
    @extend %ui1;
}

.order-shipping,
.order-discount {
    @extend %ui3;
    color: $dark-grey2;

    .price-display-component {
        @extend %ui3;
        color: $dark-grey2;
    }
}

.dt-order-totals.order-total,
.dd-order-totals.order-total {
    @extend %subtitle1;

    .price-display-component {
        @extend %subtitle1;
    }
}

.dt-order-totals.order-subtotal,
.dd-order-totals.order-subtotal {
    @extend %subtitle1;
}

.mini-shipment-item {
    padding: 15px 15px 20px;
    background-color: $white-grey;
    border: 0;
    margin-top: 16px;

    @include media ('>=desktop') {
        margin-top: 8px;
    }
}

.checkout-summary-shipment-title {
    @extend %h4;
}

.checkout-summary-address-details {
    @extend %ui1;
}

.checkout-summary-shipment-methods-details {
    @extend %ui3;
}

.checkout-summary-basket {
    margin-top: 16px;
    padding: 10px 15px 13px;
    background: $white-grey;

    @include media ('>=tablet') {
        margin-top: 8px;
    }
}

.checkout-summary-basket-title {
    @extend %h4;
}

.checkout-summary-basket-total {
    @extend %ui3-bold;

    .checkout-summary-basket-items {
        @extend %ui3;
    }
}

.checkout-summary-basket-link {
    display: block;
    @extend %ui3;
    padding: 0 0 5px;
    text-decoration: underline;

    .checkout-summary-basket-link-view {
        display: block;
    }

    .checkout-summary-basket-close {
        display: none;
    }
}

// Shipping bug on checkout (deviation from minicart)
.checkout-summary-basket-itemes {
    .minicart-product-info {
        @extend %ui1;
        flex-grow: 1;
    }

    .mini-cart-product {
        padding: 10px 0 20px;
        border-bottom: 1px solid $light-grey;
        margin: 0;
    }

    .mini-cart-product:last-child {
        padding: 10px 0 0;
        border: 0;
    }

    .mini-cart-name-link {
        @extend %h4;
    }

    .minicart-product-model {
        @extend %ui3;
        margin: 0;
    }

    .mini-cart-attributes {
        margin: 0;
    }

    .product-variation-attribute,
    .lineitem-data {
        @extend %ui1;
        text-transform: none;
        color: $dark-grey2;

        .item-number {
            @extend %ui3;
            color: $dark-grey2;
        }
    }

    .product-availability-preorder {
        margin: 10px 0 20px;
    }

    .mini-cart-pricing {
        text-transform: none;
        margin: 10px 0 0;
    }

    .price-value {
        text-align: right;  // fix for iOS alignment
    }

    .mini-cart-qty {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        color: $dark-grey2;
    }

    .mini-cart-price {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin: 4px 0 0;
        @extend %ui1;

        .price-value {
            @extend %h6;
            color: $dark-grey2;
        }

        .price-sales {
            display: inline-block;
            margin: 0 5px 0 0;
            font-size: 10px;
        }
    }

    .mini-cart-option {
        margin: 4px 0 0;
    }
}

.confirmation-border {
    border: 10px solid $black;
    border-image-slice: 10 !important;
    text-align: center;
    width: 100%;

    @include media('>=desktop') {
        padding: 0 5%; // top and bottom can not be in %, firefox bug
        border-image-slice: 5 !important;
        border-width: 5px;
    }
}

.confirmation-message {
    margin: vw(50px) auto;

    @include media('>=desktop') {
        margin: 20px auto;
    }

    @include media('>=desktopLarge') {
        margin: 70px 0;
    }
}

.confirmation-subtitle {
    margin: vw(30px) 0;

    @include media('>=desktop') {
        margin: 15px 0;
    }
}

.confirmation-separator {
    margin: vw(20px) 0;

    @include media('>=desktop') {
        margin: 20px 0;
    }
}

.confirmation-asset-wrapper {
    margin: 0 auto 20px;

    .editprofile-section {
        text-align: left;
        margin-top: 35px;

        @include media('>=tablet') {
            margin-top: 70px;
        }
    }

    .account-benefits {
        padding-bottom: 0;
    }
}

.confirmation-customer-email {
    text-decoration: underline;
}

.confirmation-order-date {
    @extend %ui2;
}

.confirmation-order-info {
    color: $black;
    padding: 15px 3.8%;
    border-bottom: 1px solid $white-grey;

    @include media('>=tablet') {
        display: flex;
        flex-flow: row wrap;
    }

    @include media('>=desktop') {
        margin: 20px auto 10px;
        max-width: 70%;
        border: 1px solid $white-grey;
        padding: 10px 15px;
    }
}

.confirmation-order-item {
    @extend %ui3;
    padding: 15px 0;

    @include media('>=tablet') {
        flex: 0 0 50%;
        max-width: 50%;
    }

    @include media('>=desktop') {
        padding: 10px 0;
    }
}

.confirmation-order-method {
    @extend %ui3;
}

.confirmation-preorder-info {
    margin: 0 0 10px;
}

.confirmation-order-title {
    @extend %h4;
    margin: 0 0 5px;
}

.confirmation-preorder-title {
    @extend %ui3-bold;
}

.confirmation-summary-info {
    color: $black;
    padding: 15px 3.8%;
    border-bottom: 1px solid $white;

    @include media('>=desktop') {
        margin: 10px auto;
        max-width: 70%;
        border: 1px solid $white;
        padding: 10px 15px;
    }
}

.confirmation-order-products {
    margin: 0 0 15px;

    &.confirmation-order-last-products {
        margin: 0;
    }
}

.confirmation-order-product {
    @extend %ui3;
    border-bottom: 1px solid $white-grey;
    padding: 15px 0;

    .product-variation-attribute,
    .lineitem-data {
        @extend %ui3;
        text-transform: none;
    }

    .monogram-option,
    .minicart-product-model {
        @extend %ui3;
        margin: 0;
    }

    &.confirmation-order-last-product {
        border: 0;
    }
}

////////////////////////////////////
// CHECKOUT SINGLE SHIPPING STEP
////////////////////////////////////
.multipleshipping-link {
    @extend %ui3;
    text-decoration: underline;
}

.shipping-type-select {
    color: $black;
    padding: 15px 0 20px;

    @include media('>=desktop') {
        padding: 15px 0 30px;
    }
}

.shipping-type-selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 90px;
    height: 100%;
    border: 1px solid $light-grey;
    padding: 15px 10px 10px;
}

.shipping-type-input {
    @extend .offscreen;

    &:focus + .shipping-type-selector {
        outline: 1px solid $white-grey;
        outline-offset: 1px;
    }
}

.shipping-type-icon {
    opacity: 0.2;

    .st0 {
        fill: $black;
    }
}

.shipping-type-text {
    @extend %h5;
    opacity: 0.2;
    margin: 10px 0 0;
    text-align: center;
}

.shipping-type-selector.active {
    background-color: $black;

    .shipping-type-icon {
        opacity: 1;

        .st0 {
            fill: $white;
        }
    }

    .shipping-type-text {
        color: $white;
        opacity: 1;
    }
}

.checkout-shipping-methods-section {
    padding: 15px 0 5px;

    @include media('>=desktop') {
        padding: 15px 0 5px;
    }
}

.shipping-method-wrapper {
    color: $black;
    margin: 0 0 16px;
    padding: 19px 25px 21px 19px;
    border: 1px solid $light-grey;

    &.active {
        border-color: $black;
    }
}

.item-image,
.item-empty,
.item-monogram-characters {
    width: 87px;
    padding-right: 12px;
    flex-shrink: 0;

    @include media('>=desktop') {
        width: 205px;
        padding-right: 20px;
    }
}

.cart-product-image {
    background-color: $light-brown;
}

.billing-payment-methods {
    padding: 15px 10px 10px;

    @include media('>=desktop') {
        padding: 17px 15px;
    }

    .input-text.cc-number {
        padding: 0 10px 0 50px;
    }
}

.payment-method-description {
    @extend %ui1;
}

.payment-method-option,
.hpp-method {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 0 20px;

    @include media('>=tablet') {
        display: inline-block;
        padding: 0 0 5px;
    }

    .payment-selector-label {
        padding: 0 10px 0 25px;
    }
}

// new classes

.confirmation-banner-title {
    @include media ('<tablet') {
        word-break: break-word;
    }
}

.confirmation-fail-actions {
    .gl_button {
        line-height: 42px;
    }
}

.address-popup-close {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50px;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    border-left: 1px solid $grey-bg;

    @include media('>=tablet') {
        width: 62px;
    }

    .closesvg {
        width: 20px;
        height: 20px;

        @include media('>=tablet') {
            width: 14px;
            height: 14px;
        }

        .st0 {
            fill: $black;
        }
    }
}

.confirmation-product-gift-icon {
    height: 16px;

    .giftsvg {
        width: 15px;

        .st0 {
            fill: $black;
        }
    }
}

.confirmation-banner-section {
    background: $black;
    padding: 3.8%;

    @include media('>=tablet') {
        padding: 4.2%;
    }

    @include media('>=desktop') {
        padding: 32px 0;
    }
}

.confirmation-wrapper {
    justify-content: center;
    position: relative;
    background: $black;

    .login-create-account {
        text-align: left;
        justify-content: center;
    }

    .login-box {
        padding: 0;
    }

    .noresult-footer {
        margin: vw(140px) auto;

        @include media('>=desktop') {
            margin: 70px auto;
        }
    }
}

.cart-row-header-item {
    @extend %ui3-bold;
    text-align: right;
}

.item-delivery-options-label {
    flex-shrink: 0;
    font: 12px / 16px $Arial;
    font-weight: bold;
    color: $black;
    margin: 0 6px 0 0;
}

.instore-shipment {
    margin: 20px 0 0;
    justify-content: space-between;
    flex-flow: row wrap;
    color: $black;
}

.checkout-product-name {
    text-decoration: none;
    text-transform: uppercase;
    font: 12px / 12px $Arial;
}

.checkout-model-name {
    font: 11px / 11px $Arial;
    margin: 0 0 15px;
    text-transform: uppercase;
}

// deleted classes (commented, just the classname for trace)
/*
.checkout-shipping-bottom-section
.gl_select .gl_input-wrapper:before
.confirmation-title
*/

// =================================
// VILEBREQUIN GRAPHICAL CHART
// =================================
.checkout-title {
    @include header-title();
}

.checkout-title-wrapper {
    padding: 25px 0 20px;
    border-bottom: 1px solid $light-grey;

    @include header-title-wave();
}

.multi-address-error {
    margin: 0 0 10px;
    color: $red;
    text-align: left;
    font: 12px / 14px $Arial;
    text-transform: none;
    letter-spacing: normal;
}

.checkout-main-content {
    @include media('>=desktop') {
        display: flex;
        justify-content: center;
        margin: 20px 0 30px;
    }
}

.checkout-primary-content {
    @include media('>=desktop') {
        width: 589px;
        max-width: 589px;
        margin: 0 10px;
    }

    @include media('<desktop') {
        padding: 0;
    }

    .promo {
        text-transform: uppercase;
        font-size: vw(24px);

        @include media('>=tablet') {
            font-size: 24px;
        }

        @include media('>=desktop') {
            font-size: 12px;
        }
    }
}

.checkout-bonus-item {
    font: bold 11px / 11px $Arial;
}

.checkout-product-list-item {
    font: 12px / 16px $Arial;
    color: $blue;

    // deviation from minicart
    .product-variation-attribute,
    .lineitem-data {
        text-transform: none;
        font: 12px / 16px $Arial;
    }
}

.checkout-product-name-link {
    font-weight: bold;
    text-decoration: none;
}

.checkout-product-quantity {
    text-transform: uppercase;
    font: 10px / 10px $Arial;
    color: $blue;
}

.checkout-gift-message-wrapper {
    margin: 15px 0 0;
    color: $blue;
}

.checkout-summary-gift-title {
    margin: 0 0 5px;
    font: bold 11px / 11px $Arial;
}

.checkout-summary-gift-message {
    font-size: 12px;
    line-height: 12px;
}

.checkout-instore-shipment-info {
    color: $blue;

    .checkout-instore-shipment-title {
        font: bold 13px / 16px $Arial;
        margin: 0 0 15px;
    }

    .mini-address {
        font: 12px / 16px $Arial;
    }
}

.checkout-monogram-detail {
    font: 12px / 16px $Arial;
    color: $blue;
    margin: 20px 0 0;

    @include media('>=tablet') {
        margin: 15px 0 0;
    }

    @include media('>=desktop') {
        margin: 10px 0 0;
    }
}

////////////////////////////
//CHECKOUT COMMON ELEMENTS END
////////////////////////////

////////////////////////////
//Checkout Login
////////////////////////////
.checkout-wave-separator {
    margin-top: 20px;
}

.checkoutlogin {
    @include media('>=desktop') {
        display: flex;
        max-width: 846px;
        margin: 0 auto;
    }
}

.checkoutlogin-column {
    @include media('>=desktop') {
        flex: 50%;
        flex-grow: 0;
    }
}

.checkoutlogin-guest {
    padding: 15px 30px 20px;
    border: 1px solid $light-grey;
    border-width: 0 0 1px;

    @include media('>=desktop') {
        padding: 47px 78px 50px 0;
        border-width: 0 1px 0 0;
    }
}

.checkoutlogin-login {
    padding: 18px 30px 20px;

    @include media('>=desktop') {
        padding: 47px 0 50px 78px;
    }
}

.checkoutlogin-box {
    max-width: 345px;
    margin: 0 auto;
}

////////////////////////////
//Checkout Login end
////////////////////////////

.payment-method {
    clear: both;
    display: none;
}

.payment-method-expanded {
    display: block;
}

////////////////////////////
// CHECKOUT SIDEBAR
////////////////////////////
.checkout-summary-wrapper {
    border-top: 1px solid $light-grey;

    @include media('>=desktop') {
        border: 0;
    }

    &.stickable {
        @include media('>=desktop') {
            position: sticky;
            top: 10px;
            padding-bottom: 10px;
        }
    }
}

// take into account top banner
.page-with-top-banner {
    .checkout-summary-wrapper {
        &.stickable {
            @include media('>=desktop') {
                top: 40px;
            }
        }
    }
}

.checkout-summary-basket-title-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin: 0 0 5px;
}

.checkout-summary-basket-link.active {
    .checkout-summary-basket-link-view {
        display: none;
    }

    .checkout-summary-basket-close {
        display: block;
    }
}

.checkout-summary-shipment-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 4px;
}

.minishipments-method-label {
    font-weight: bold;
}

.ordersummary-background-wrapper {
    margin: 0 0 20px;

    @include media('>=desktop') {
        background: $light-grey;
        margin: 0 0 10px;
    }
}

.checkout-summary {
    @include media('>=desktop') {
        width: 375px;
        max-width: 375px;
        margin: 0 10px;
    }
}

.checkout-summary-title-wrapper {
    padding: 15px 5px 10px 10px;
    justify-content: space-between;
    align-items: center;
}

.checkout-summary-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: vw(36px);

    @include media('>=tablet') {
        font-size: 36px;
    }

    @include media('>=desktop') {
        font-size: 18px;
        line-height: 22px;
    }
}

.checkout-summary-shipping-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: vw(24px);

    @include media('>=tablet') {
        font-size: 24px;
    }

    @include media('>=desktop') {
        font-size: 12px;
    }
}

.checkout-summary-edit {
    font: bold vw(22px) $Arial;
    text-transform: capitalize;
    text-align: right;
    margin: 0 10px 0 0;

    @include media('>=tablet') {
        font-size: 22px;
    }

    @include media('>=desktop') {
        font-size: 11px;
    }
}

.ordersummary-address-details {
    padding: 30px 15px;
    background: $white;
    border: 5px solid $light-grey;
}

.checkout-order-totals {
    padding: 15px 5px;
    color: $blue;
    background: $light-grey;

    .checkout-summary-wrapper & {
        padding: 15px;
    }
}

.checkout-summary-needhelp {
    width: 100%;

    .need-help {
        border-width: 1px 0 0;
    }
}

.ordersummary-address-details {
    text-transform: uppercase;
    font: vw(24px) $Arial;
    line-height: normal;
    color: $blue;

    @include media('>=tablet') {
        font-size: 22px;
        line-height: 22px;
    }

    @include media('>=desktop') {
        font-size: 12px;
        line-height: 12px;
    }
}

////////////////////////////
// CHECKOUT SIDEBAR END
////////////////////////////

/////////////////////////////////
// CHECKOUT SHIPPING EDIT ADDRESS popup
/////////////////////////////////
.address-popup-dialog-active {
    // no scroll on page body
    @include media('<=desktop') {
        overflow: hidden;
        position: fixed;
    }
}

.address-popup-title-wrapper {
    position: relative;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    border-bottom: 1px solid $light-grey;
    height: 50px;

    @include media('>=tablet') {
        height: 62px;
    }
}

.address-popup-title {
    flex-grow: 1;
    max-width: calc(100% - 50px);
    padding: 15px;
    font: bold 16px / 19px $Arial;
    letter-spacing: normal;
    text-transform: none;

    @include media('>=tablet') {
        max-width: calc(100% - 62px);
        padding: 20px;
        font: bold 18px / 22px $Arial;
    }
}

.address-popup-content-wrapper {
    @include media('>=desktop') {
        height: calc(768px - 62px);
        overflow: hidden auto;
    }
}

.address-popup-content {
    padding: 15px 15px 20px;

    @include media('>=tablet') {
        padding: 15px;
    }

    @include media('>=desktop') {
        max-width: 740px;
        margin: 50px auto 20px;
        padding: 0;
    }

    .address-form-row-button {
        @include media('>=tablet') {
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
        }
    }

    .apply-button {
        margin: 0 0 10px;

        @include media('>=tablet') {
            margin: 0 5px 0 0;
        }
    }

    .cancel-button {
        @include media('>=tablet') {
            margin: 0 0 0 5px;
        }
    }

    .form-error {
        display: block;
        margin: 0 0 10px;
        color: $red;
        font: 12px / 14px $Arial;
    }
}

/////////////////////////////////
// CHECKOUT SHIPPING EDIT ADDRESS END
/////////////////////////////////


/////////////////////////////////
// CHECKOUT CART PAGE
/////////////////////////////////
.product-availability-list {
    font: vw(24px) $Arial;
    text-align: center;

    @include media('>=tablet') {
        font-size: vw(20px);
    }

    @include media('>=desktop') {
        font-size: 12px;
    }
}

.notavailable {
    color: $red;
}

.item-quantity {
    > .gl_select {
        width: 100%;
    }

    .gl_select .gl_input-wrapper {
        text-align: right;
    }
}

.cart-empty {
    padding: 15px 38px 30px;

    @include media('>=tablet') {
        max-width: 500px;
        margin: 0 auto;
        padding: 20px 0 30px;
    }
}

.cart-action-continue-shopping {
    text-align: center;
}

.cart-continue-shopping-btn {
    max-width: 300px;
}

.cartpage-wrapper {
    .images-carousel-component {
        @include media('>=desktop') {
            padding: 25px 0 45px;
        }
    }
}

.cart-recommendations {
    .product-carousel-component.pdp-predictive-carousel {
        @include media('>=desktop') {
            margin: 30px auto 50px;
        }
    }
}

.cartpage-items-text {
    font: 12px / 14px $Arial;
    text-transform: uppercase;
    color: $blue;
}

.shopping-bag-message {
    text-align: center;
    font: 14px / 16px $Arial;
    color: $blue-hover;
}

.cart-shipping-info {
    padding: 10px 15px 10px;
    border: 1px solid $white-grey;
    border-width: 0 0 1px;

    @include media('>=tablet') {
        padding: 14px 15px 0;
    }

    @include media('>=desktop') {
        border-width: 1px;
        padding: 10px 15px;
        border: 1px solid $white-grey;
        margin: 0 0 5px;
    }

    .cart-shipping-title {
        font: 16px / 19px $Arial;
        font-weight: bold;
        color: $blue;
        margin: 0 0 10px;
        text-transform: none;
        letter-spacing: normal;
    }

    .cart-shipping-desc-list {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        margin: 0;
    }

    .cart-shipping-desc-label {
        flex: 0 0 70%;
        max-width: 70%;
        margin: 0;
        padding: 0 0 20px;

        @include media('>=tablet') {
            padding: 0 0 16px;
        }
    }

    .cart-shipping-desc-title {
        display: block;
        font: 12px / 14px $Arial;
        color: $blue;
    }

    .cart-shipping-desc-info {
        display: block;
        font: 10px / 14px $Arial;
        color: $medium-grey;
    }

    .cart-shipping-desc-value {
        flex: 0 0 30%;
        max-width: 30%;
        margin: 0;
        text-align: right;
        font: 12px / 14px $Arial;
        font-weight: bold;
        color: $blue;
        padding: 0 0 20px 10px;

        @include media('>=tablet') {
            padding: 0 0 16px 10px;
        }
    }
}

.cart-top-error {
    text-align: center;
    margin: 10px;

    @include media('>=desktop') {
        max-width: 984px;
        margin: 10px auto;
    }
}

.cart-grid {
    @include media('>=desktop') {
        display: flex;
        justify-content: center;
        margin: 0 auto 20px;
    }
}

.cart-table-content {
    @include media('>=desktop') {
        width: 589px;
        margin: 0 10px;
    }
}

.cart-sidebar-content {
    @include media('>=desktop') {
        width: 375px;
        margin: 0 10px;
    }
}

.monogram-detail-wrapper {
    display: block;

    @include media('>=tablet') {
        display: flex;
        flex-direction: row;
    }

    @include media('>=desktop') {
        display: block; // reason to switch on "display: block": nested flex in IE - well known issue with height if flex-direction: column;
    }
}

.cart-row-header-item.details-section-header {
    flex-grow: 1;
}

.cart-row-header-item.total-section-header {
    width: 95px;
}

.cart-product-group:first-of-type {
    .cart-row.cart-main-row:first-child {
        @include media('>=desktop') {
            margin: 0;
        }
    }
}

.cart-row.cart-shipment-row {
    border-width: 0;

    @include media('>=desktop') {
        border-width: 0 1px;
    }
}

.cart-row.cart-monogram-row {
    border-width: 0;

    @include media('>=desktop') {
        border-width: 0 1px;
    }
}

.item-shipment {
    position: relative;
}

// Cart pre-order
.cartpage-preorder {
    font: 12px/16px $Arial;
    color: $royal-blue;
    text-align: left;
    margin: 0 0 15px;

    .cartpage-preorder-from {
        text-transform: lowercase;
    }

    .cartpage-preorder-date {
        font-weight: bold;
    }
}

// Checkout pre-order: shipping methods step on multishipping flow
.shipment-method-preorder {
    font: 12px/16px $Arial;
    margin: 0 0 15px;

    .shipment-method-preorder-title {
        font-weight: bold;
    }

    .shipment-method-preorder-from {
        text-transform: lowercase;
    }

    .shipment-method-preorder-date {
        font-weight: bold;
    }
}

// Checkout pre-order: summary - shipping addresses
.checkout-preorder {
    .checkout-preorder-title {
        font-weight: bold;
    }

    .checkout-preorder-from {
        text-transform: lowercase;
    }

    .checkout-preorder-date {
        font-weight: bold;
    }
}

.item-monogram-characters {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include media('>=tablet') {
        align-items: flex-start;
        padding: 15px 15px 0;
    }

    @include media('>=desktop') {
        align-items: center;
        padding: 0 15px;
    }
}

.item-delivery-options {
    display: flex;
    flex-wrap: nowrap;
    margin: 10px 0 0;

    .btn-open-dialog {
        width: auto;
        padding: 0 15px;
    }

    .store-address {
        font: 12px / 14px $Arial;
        font-weight: bold;
        cursor: pointer;
    }
}

.item-delivery-options-values {
    position: relative; // for loader
    flex-grow: 1;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    font: 12px / 16px $Arial;

    .link-open-dialog {
        text-decoration: underline;
        cursor: pointer;
        text-align: left;
    }
}

.form-delivery-row {
    margin: 0 0 5px;

    &:last-child {
        margin: 0;
    }

    .form-delivery-label {
        font-size: 12px;
    }

    .instore-delivery-details {
        padding: 0 0 0 30px;
    }

    .link-reopen-dialog {
        text-align: left;
        margin: 5px 0 0;
    }

    .notavailable {
        font-weight: normal;
    }
}

.item-delivery-options-wrapper {
    position: relative;

    &.gl_loading {
        opacity: 0.5;
        pointer-events: none;
    }

    .gl_ajax-wrapper {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: calc(50% - 21px);
    }
}

.item-total-mobile {
    margin: vw(70px) 0 0;
}

.item-quantity-mobile {
    margin: vw(70px) 0 0 auto;
}

.cart-giftbox-item {
    position: relative; // for loader
}

.giftbox-forced-label-wrapper {
    .giftsvg {
        margin: 0 10px 0 0;
    }
}

.giftbox-message-wrapper {
    display: block;

    @include media('>=tablet') {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    @include media('>=desktop') {
        display: block; // reason to switch on "display: block": nested flex in IE - well known issue with height if flex-direction: column;
    }

    .giftbox-message-label {
        line-height: 20px;
    }
}

.giftbox-message-section {
    border-bottom: 1px solid $light-grey;
    margin: 0 0 15px;
    padding: 0 0 10px;

    @include media('>=tablet') {
        border: 0;
        margin: 0;
        padding: 0;
        flex: 50%;
        max-width: 50%;
    }

    @include media('>=desktop') {
        flex: 100%;
        max-width: 100%;
    }
}

.giftbox-message-section:last-child {
    border: 0;
    margin: 0;
    padding: 0;

    @include media('>=desktop') {
        padding: 0;
    }
}

.giftbox-message-section:nth-child(odd) {
    @include media('>=tablet') {
        padding: 0 25px 15px 0;
    }

    @include media('>=desktop') {
        padding: 0 0 20px;
    }
}

.giftbox-message-section:last-child:nth-child(odd) {
    @include media('>=tablet') {
        padding: 0;
        max-width: 100%;
    }
}

.giftbox-message-section:nth-child(even) {
    @include media('>=tablet') {
        padding: 0 0 15px 25px;
    }

    @include media('>=desktop') {
        padding: 0 0 20px;
    }
}

.giftbox-message-section:last-child:nth-child(even) {
    @include media('>=desktop') {
        padding: 0;
    }
}

.giftbox-amount-container {
    align-items: center;
    margin: 10px 0;

    @include media('>=tablet') {
        justify-content: flex-start;
        margin: 5px 0 10px;
    }

    @include media('>=desktop') {
        margin: 0 0 5px;
    }
}

.cart-promo {
    font-size: vw(24px);
    padding: 0 0 10px 45px;
    font-weight: bold;
    position: relative;

    @include media('>=tablet') {
        font-size: 24px;
    }

    @include media('>=desktop') {
        font-size: 12px;
    }

    .bonus-item-details,
    .bonus-item-actions {
        line-height: 16px;
    }

    .select-bonus {
        text-transform: uppercase;
    }

    .gl_ic_retina-container {
        position: absolute;
        bottom: 11px;
        left: 2px;
    }

    &.inside-list {
        padding-top: 20px;
        border-top: 1px solid $white-grey;
    }
}

.bonus-product-item {
    display: block;
    margin: 15px -15px 0;
    border-top: 1px solid $white-grey;
    padding: 15px 15px;

    @include media('>=tablet') {
        display: flex;
    }

    .product-variations,
    .product-attributes-list {
        height: auto;
    }

    .product-col-1 {
        @include media('>=tablet') {
            flex: 1 0 40%;
            align-items: center;
            align-content: center;
        }
    }

    .product-col-2 {
        @include media('>=tablet') {
            flex: 1 0 60%;
        }
    }

    .attribute {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        margin-top: 20px;
    }

    .variation-select-title {
        order: 1;
        flex: 0 1 40%;
        padding-right: 15px;
        margin: auto;
        text-align: left;
    }

    .swatch-list,
    .size-list {
        order: 2;
        flex: 0 1 60%;
    }

    .bonus-product-form {
        margin-top: 20px;
        margin-left: 60%;
    }

    .product-primary-image {
        display: block;
        padding: 0 30px 0 0;
        margin: 0;
    }

    .product-detail-name {
        text-align: left;
        font-size: vw(36px);

        @include media('>=tablet') {
            font-size: 28px;
        }

        @include media('>=desktop') {
            font-size: 18px;
        }
    }

    .size-selection-item {
        display: inline-block;
    }

    .swatchanchor {
        text-decoration: none;
    }
}

.product-detail-mobile {
    .product-primary-image {
        flex: 1 0 30%;
        padding-right: 20px;
    }

    .product-detail-name {
        flex: 1 0 70%;
    }
}

.bonus-product-list-footer {
    margin: 20px -15px 0;
    border-top: 1px solid $white-grey;
    padding: 20px 15px 10px;
}

.bonus-product-summary {
    margin: 0 15px 15px;

    .selected-bonus-items {
        justify-content: center;
        color: $blue;
        font-size: vw(24px);

        @include media('>=tablet') {
            font-size: 24px;
        }

        @include media('>=desktop') {
            font-size: 12px;
        }
    }

    .selected-bonus-item {
        padding: 6px;
        margin: 6px;
        border: 1px solid $white-grey;
        flex: 0 1 auto;
        min-width: 100px;
    }

    .remove-link-wrapper {
        text-align: right;
    }

    .remove-link {
        font-size: vw(22px);

        @include media('>=tablet') {
            font-size: 22px;
        }

        @include media('>=desktop') {
            font-size: 11px;
        }
    }

    .item-name {
        display: block;
        font-weight: bold;
    }
}

.gift-box-message-input {
    margin: 15px 0 0;
}

// dl order totals
.dl-order-totals {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    margin: 0;
}

.dt-order-totals {
    max-width: 65%; // standart case: dd value is price
    text-align: left;
}

.dd-order-totals {
    max-width: 35%; // standart case: dd value is price
    text-align: right;
    padding-left: 15px;
    margin: 0;
}

// specific case: dd value is text
.dt-order-totals.order-text-label,
.dd-order-totals.order-text-label {
    max-width: 50%;
}

// flex trick to break dt/dd pair onto new line
.dd-break-totals {
    flex: 0 0 100%;
    height: 0;
    margin: 0;
}

.order-subtotal {
    order: 1;
}

.order-shipping {
    order: 3;
}

.order-discount {
    order: 4;
}

.order-sales-tax {
    order: 5;
}

.order-total {
    order: 6;
}

// checkout summary footer
.order-sales-tax-eu {
    order: 7;
}

// cart summary footer
.order-sales-tax-cart {
    order: 7;
}

// Summary: cart & checkout pages
.cart-coupon-code-container {
    flex-basis: 100%;

    @include media('>=desktop') {
        flex-basis: 370px;
    }
}

.cart-coupon-accordion-title {
    position: relative;
    cursor: pointer;

    &.ui-state-active {
        .cart-coupon-arrow::before {
            @include triangle-up();
        }
    }
}

.cart-coupon-arrow {
    position: absolute;
    right: 5px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 4px;

    &::before {
        content: '';
        display: block;
        @include triangle-down();
    }
}

.cart-coupon-accordion {
    display: none;
}

.coupon-help {
    margin-top: 10px;
}

.cart-coupon-remove {
    text-decoration: underline;
}

.cart-coupon-section {
    .coupon-code-field {
        padding: 0 85px 0 10px;
        height: 44px;
        line-height: 16px;
    }

    .add-coupon-button {
        padding: 0 5px;
    }

    .gl_form-group {
        padding-bottom: 0;
    }

    .gl_has-error + .error-form {
        font-size: 11px;
        margin: 0;
    }

    .gl_has-error + .error-form {
        margin: 10px 0 0;
    }
}

.gl_input-wrapper.cart-coupon-input {
    .gl_input-submit {
        width: 86px;
        height: 44px;
        line-height: 40px;
    }
}

.cart-coupon-remove-item {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin: 0 0 15px;
}

.cart-coupon-remove-item:last-child {
    margin: 0;
}

////////////////////////////////////
// CHECKOUT SINGLE SHIPPING STEP
////////////////////////////////////
.checkout-step-title-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.shipping-type-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin: 18px 0 0;
}

.shipping-type-button {
    flex: 50%;
    flex-shrink: 0;

    .ui-checkboxradio-icon {
        visibility: hidden;
    }
}

.shipping-type-button-left {
    padding: 0 10px 0 0;

    @include media('>=tablet') {
        padding: 0 5px 0 0;
    }
}

.shipping-type-button-right {
    padding: 0 0 0 10px;

    @include media('>=tablet') {
        padding: 0 0 0 5px;
    }
}

.shippingmethods-header {
    margin: 0 0 15px;
}

.shipping-method-wrapper:last-child {
    margin: 0;
}

.shipping-method-options {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.shipping-method-option-wrapper {
    flex-grow: 1;
}

.shipping-method-option {
    display: flex;
}

.singleshipping-address-options {
    padding: 0 0 5px;

    .singleshipping-address-option {
        padding: 0 10px 20px;
    }
}

.shipping-pickup-in-store-button {
    margin: 0 0 20px;
}

.singleshipping-store-address-card {
    display: flex;
    flex-wrap: nowrap;
    margin: 0 0 12px;
    font: 12px / 16px $Arial;
    color: $blue;

    .store-address-info {
        padding: 0 10px 0 0;
        flex: 0 0 50%;

        @include media('>=tablet') {
            padding: 0 5px 0 0;
        }
    }

    .store-name,
    .store-phone-wrap {
        font-weight: bold;
    }

    .store-detail-hours {
        flex: 0 0 50%;
        padding: 0 0 0 10px;
        color: $medium-grey;

        @include media('>=tablet') {
            padding: 0 0 0 5px;
        }
    }

    .store-opening-hours-title {
        font: bold 12px / 16px $Arial;
        color: $medium-grey;
        text-transform: none;
        letter-spacing: normal;
    }
}

////////////////////////////////////
// CHECKOUT SINGLE SHIPPING END
////////////////////////////////////

////////////////////////////////////
// CHECKOUT MULTISHIPPING STEP
////////////////////////////////////
.checkout-multishipping-addresses-section {
    padding: 15px 10px 10px;

    @include media('>=tablet') {
        padding: 15px 10px;
    }

    @include media('>=desktop') {
        padding: 15px;
    }
}

.checkout-multishipping-title {
    margin: 0 0 15px;

    @include media('>=desktop') {
        margin: 0;
    }
}

.multishipping-cart-row {
    flex-flow: row wrap;
    padding: 0 0 15px;
    border-top: 1px solid $light-grey;
}

.multishipping-cart-row:first-child {
    @include media('>=desktop') {
        border: 0;
    }
}

.choose-billing-address-wrapper,
.multishipping-select-control {
    padding: 0 0 20px;
}

.edit-address-wrapper {
    display: flex;
    flex-wrap: nowrap;
    margin: 0 -8px;

    @include media('>=desktop') {
        margin: 0 -5px;
    }

    .edit-address-btn {
        flex: 1 0 calc(50% - 16px);
        margin: 0 8px;

        @include media('>=desktop') {
            flex: 1 0 calc(50% - 10px);
            margin: 0 5px;
        }
    }
}

.multishipping-address-formactions {
    margin: 15px 0 0;
}

.multishipping-cart-product {
    display: flex;
    flex-wrap: nowrap;
    padding: 15px 0 25px;

    @include media('>=desktop') {
        padding: 15px 0 20px;
    }
}

.multishipping-item-image {
    width: 70px;
    margin: 0 15px 0 0;

    @include media('>=desktop') {
        margin: 0 10px 0 0;
    }
}

.multishipping-item-details {
    flex-grow: 1;
}

//////////////////////////////////////////
// CHECKOUT MULTISHIPPING STEP END
//////////////////////////////////////////

///////////////////////////////////////////////
// CHECKOUT MULTISHIPPING SHIPPING METHODS STEP
///////////////////////////////////////////////
.checkout-multishipping-address-section {
    padding: 0 10px 10px;

    @include media('>=tablet') {
        padding: 0 10px 15px;
    }

    @include media('>=desktop') {
        padding: 0 15px 15px;
    }
}

.checkout-multiship-addresses-title {
    padding: 15px 0;
    border-bottom: 1px solid $light-grey;
}

.multishipping-method-item-list {
    max-width: 100%; //IE adds padding ignoring border-box for flex children
    color: $blue;
    border-bottom: 1px solid $light-grey;

    &:last-child {
        border: 0;
    }
}

.item-shipping-address {
    font: 12px / 16px $Arial;
    padding: 15px 0;

    .mini-address-location {
        margin: 0;
    }
}

.multishipping-method-address-label {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0 0 15px;
}

.multishipping-method-address-title {
    font: bold 14px / 16px $Arial;
}

.multishipping-method-promo {
    font: 12px / 14px $Arial;
    margin: 0 0 10px;

    .multishipping-method-promo-callout {
        color: $medium-grey;
    }
}

.multishipping-method-select-wrapper {
    font-size: 12px;
    font-weight: bold;

    .gl_form-group {
        padding: 0;
    }

    .gl_form-group.multishipping-method-select-group {
        padding: 0 0 15px;

        @include media('>=tablet') {
            padding: 0 0 30px;
        }
    }
}

////////////////////////////////////
// CHECKOUT BILLING STEP: address
////////////////////////////////////
.checkout-billing-address-section {
    padding: 15px 10px 0;
    border-bottom: 1px solid $light-grey;

    @include media('>=desktop') {
        padding: 15px 15px 0;
    }

    .select-address-section {
        padding: 0;

        .gl_form-group {
            padding: 0;
        }
    }

    .checkout-billing-add-address-section {
        padding: 0 0 15px;
    }
}

.checkout-billing-address-title {
    margin: 0 0 15px;

    @include media('>=desktop') {
        margin: 0 0 10px;
    }
}

.checkout-billing-mandatory {
    margin: 20px 0 10px;
}

.checkout-billing-or-title {
    text-align: center;
    text-transform: uppercase;
    padding: 10px 0;
}

.simple-submit {
    .checkout-billing & {
        display: none;
    }
}

////////////////////////////////////
// CHECKOUT BILLING STEP: payment
////////////////////////////////////
.checkout-billing-payment-title {
    margin: 0 0 15px;

    @include media('>=desktop') {
        margin: 0 0 18px;
    }
}

.billing-payment-row {
    @include media('>=tablet') {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    .gl_floating-label {
        width: calc(100% - 20px);
    }
}

.exp-date-wrapper {
    @include media('>=tablet') {
        flex-grow: 1;
        padding: 0 15px 0 0;
    }
}

.cvv-tooltip-wrapper {
    margin-top: 2px;
    padding-right: 2px;
    text-align: right;
}

.billing-accordion-header {
    &.ui-state-active {
        background-color: $light-grey;
    }

    .gl_plus-button {
        @include media('>=tablet') {
            padding-left: 2px;
        }
    }
}

.payment-method-options-section {
    padding: 0;

    @include media('>=tablet') {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 0 30px;
    }
}

.card-icons {
    @include media('>=tablet') {
        display: inline-block;
    }

    .card-icon {
        padding: 0 5px 0 0;
    }

    .card-icon:last-child {
        padding: 0;
    }
}

.card-icon {
    display: inline-block;

    .checkout-payment-icon {
        width: auto;
        height: 22px;
    }
}

.payment-methods-header {
    font: vw(28px) $MontserratBold;
    text-align: center;

    @include media('>=tablet') {
        font-size: 28px;
    }

    @include media('>=desktop') {
        font-size: 14px;
    }
}

.form-row-double {
    .gl_form-group {
        width: 49%;
        float: left;

        &:first-child {
            margin: 0 2% 0 0;
        }
    }
}

.form-row-expiration {
    position: relative;

    .gl_error-message {
        position: absolute;
        left: 0;
        margin: 0;
    }
}

.billing-bottom-assurance {
    .need-help-item {
        display: none;
        padding: 0;
    }

    .need-help-secure {
        display: block;
        width: 100%;
    }

    .need-help-content {
        margin: 12px 0 0;
        padding: 20px 0 15px;
    }
}

.paypal-button-widget {
    margin: vw(40px) auto;
    display: block;
    width: 146px;

    @include media('>=desktop') {
        margin: 20px auto;
    }
}

.PAFrame {
    min-height: 500px;
}

////////////////////////////////////
// CHECKOUT BILLING STEP END
////////////////////////////////////

///////////////////////////////////
//  ORDER CONFIRMATION
//////////////////////////////////

.pt_order-confirmation {
    .main-footer {
        margin-top: -16px;
    }
}

.confirmation-banner-container {
    width: 100%;
    background: $white;
    padding: 30px 20px;
    text-align: center;
    margin: 0 auto;

    @include media('>=desktop') {
        max-width: 70%;
    }
}

.confirmation-banner-message {
    @include media('>=tablet') {
        max-width: 430px;
        margin: 0 auto;
    }
}

.confirmation-container {
    margin: 70px 0;
    padding: 10px;
    background: $white;
}

.confirmation-product-info {
    margin: 0 0 0 14px;
    flex-grow: 1;
}

.confirmation-giftcert-name {
    @extend %h4;
}

.confirmation-product-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.confirmation-product-gift {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.confirmation-product-gift-text {
    padding: 0 10px;
}

.confirmation-product-image {
    width: 70px;
    align-items: stretch;
    justify-content: center;
}

.confirmation-product-lazy-image {
    max-width: 100%;
    background-color: $light-brown;
}

.confirmation-product-pricing {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin: 20px 0 0;
    font: 14px / 16px $Arial;

    .price-total,
    .bonus-item {
        font-weight: bold;
    }

    .price-unadjusted {
        margin: 0 5px 0 0;
        font-size: 10px;
    }
}

.confirmation-multishipment-info {
    padding: 15px 3.8%;
    font-size: 12px;
    line-height: 16px;
    border-bottom: 1px solid $white-grey;
    color: $black;

    @include media('>=desktop') {
        margin: 10px auto;
        max-width: 70%;
        border: 1px solid $white-grey;
        padding: 10px 15px;
    }
}

.confirmation-multishipment-title {
    margin: 0 0 25px;
}

.confirmation-multishipment-from {
    font-weight: bold;
    margin: 0 0 7px;
}

.confirmation-multishipment-method {
    margin: 0 0 20px;
}

.confirmation-create-account {
    padding: 0 25px 50px;
    border-bottom: 1px solid $white-grey;

    @include media('>=tablet') {
        padding: 0 0 50px;
    }

    @include media('>=desktop') {
        padding: 0;
        margin: 10px 0 40px;
        border: 0;
    }
}

.confirmation-create-account-title {
    font-family: $Arial;
    font-weight: bold;
    letter-spacing: normal;
    text-transform: none;

    @include media('>=tablet') {
        font-size: vw(24px);
    }

    @include media('>=desktop') {
        font-size: 16px;
    }
}

.confirmation-account-form {
    @include media('>=tablet') {
        max-width: 450px;
        margin: 0 auto;
    }

    @include media('>=desktop') {
        border: 1px solid $white-grey;
        padding: 20px 30px 30px;
        max-width: 70%;
    }
}

.confirmation-createaccount-link {
    width: 100%;
    margin: vw(50px) auto 0;

    @include media('>=desktop') {
        margin: 20px auto 0;
    }
}

.confirmation-content-item {
    display: block;

    &::before {
        content: '\2022'; // bullet
        margin: 0 10px 0 0;
    }
}

.confirmation-fail-actions {
    margin: 0 0 vw(60px);

    @include media('>=desktop') {
        margin: 0 0 30px;
    }
}

.confirmation-explore-universe {
    padding: 20px 0;
    text-align: center;

    @include media('>=desktop') {
        padding: 30px 0;
        border-top: 1px solid $white-grey;
    }

    .confirmation-order-section + & {
        margin-top: 70px;
    }

    .optinconsent,
    .shareconsent {
        text-align: left;
    }
}

.confirmation-explore-header {
    margin: 0 20px;
}

.confirmation-explore-list {
    margin: 0 auto;
}

///////////////////////////////////
//  ORDER CONFIRMATION END
//////////////////////////////////

.creditcardtypeicons-wrapper {
    position: absolute;
    top: 0;
    left: 6px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 100%;
    width: 40px;
    z-index: 2;
}

.cc-number-wrapper {
    .input-text:focus ~ .gl_floating-label,
    &.filled .gl_floating-label {
        left: 10px;
        width: auto;
    }

    .gl_floating-label {
        left: 50px;
    }
}

.checkout-payment-method-icons-wrapper {
    flex-flow: row wrap;
    justify-content: center;
    align-items: stretch;
    align-content: stretch;

    @include media('>=tablet') {
        flex-flow: row nowrap;
        width: 727px;
        margin: 0 auto 40px;
        justify-content: space-between;
    }
}

.checkout-payment-method-icon-wrapper {
    max-height: 70px;
    padding: 5px;
    flex: 0 1 25%; // 2 lines
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;

    @include media('>=desktop') {
        flex: 0 1 auto;
    }
}

.checkout-payment-method-icon {
    flex: 0 0 auto;
    max-width: 100%;
    width: auto;
}

.cart-recommendations {
    margin-top: 40px;
}

.instore-shipment-col {
    flex: 0 0 100%;

    @include media('>=tablet') {
        flex: 0 1 50%;
    }
}

.instore-shipment-title {
    font: bold 13px / 16px Arial,sans-serif;
    text-transform: none;
    letter-spacing: normal;
    margin: 0 0 15px;

    .instore-shipment-info & {
        margin: 0 0 10px;
    }
}

.instore-shipment-address {
    font: 12px / 16px $Arial;

    .mini-address-location {
        margin: 0;
    }
}

// CVV field
.gl_number .input-text {
    width: 100%;
}

.cart-actions.mobile-sticky-cart-panel {
    @include media('<desktop') {
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 25000;
        margin: 0;
    }
}

.cart-special-payments-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -7px;

    .dw-apple-pay-button,
    .cartpage-adyen-klarna-button,
    .cartpage-paypal-button {
        flex: 1 0 calc(50% - 14px);
        margin: 15px 7px 0;
    }

    // case: applepay + paypal + klarna
    .dw-apple-pay-button + .cartpage-adyen-klarna-button {
        order: 3;
    }
}

.checkout-result-donate {
    margin: 20px 0;
    padding: 10px;
    background-color: $light-grey;

    @include media('>=desktop') {
        margin: 30px 0;
    }
}

.checkout-result-donate-wrapper {
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-flow: row wrap;
}

.checkout-result-donate-text {
    order: 2;
    font: 12px $Arial;
    flex-basis: 100%;
    padding: 8px 0;

    @include media('>=tablet') {
        order: 1;
        flex: 1 0 66%;
        padding: 0 4px 0 0;
        text-align: right;
    }
}

.checkout-result-donate-logo {
    order: 1;
    flex-basis: 100%;

    @include media('>=tablet') {
        order: 2;
        flex: 1 0 33%;
    }
}

.checkout-result-donate-image {
    max-width: 125px;
}

.checkout-result-donate-action {
    order: 3;
    flex-basis: 100%;
    padding-top: 4px;
}

.checkout-result-donate-button.gl_button {
    width: auto;
    min-width: 200px;
    border-radius: 6px;
}

.cartpage-shippedfrom {
    font: 12px / 16px $Arial;
}

.monogram-option {
    margin: 12px 0;
    color: $blue;
    font-size: 12px;
    line-height: 12px;

    @include media('>=desktop') {
        margin: 12px 0 0;
    }

    .cart-row & {
        display: none;
        margin: 10px 0;

        .monogram-action {
            font: 12px / 16px $Arial;
            padding: 0;
        }
    }

    .cart-row .monogram-detail-wrapper & {
        display: block;
        font-style: normal;
    }
}

.price-option {
    .value {
        white-space: nowrap;
    }
}

.cart-monogram-disclaimer {
    font: 10px / 14px $Arial;
    color: $light-red;
    padding: 0 0 10px;

    @include media('>=tablet') {
        order: 1;
        flex: 50%;
        padding: 0 60px 0 0;
    }

    @include media('>=desktop') {
        order: 0;
        flex: 100%;
        padding: 0 0 10px;
    }
}

.monogram-detail {
    position: relative;  // for loader
    font: 12px / 16px $Arial;
    border: 0;
    color: $blue;

    @include media('>=tablet') {
        flex: 50%;
        padding: 0 60px 0 0;
    }

    @include media('>=desktop') {
        flex: 100%;
        padding: 0;
    }
}

.monogram-detail-characters {
    width: 70px;
    height: 35px;
    text-align: center;
    font: 13px / 35px $Arial;
    font-weight: bold;
    text-transform: uppercase;
    color: $blue;
    background-color: $grey-bg;
}

.monogram-detail-title {
    font: 12px / 16px $Arial;
    font-weight: bold;
}

//.monogram-detail-color {}

//.monogram-detail-font {}

//.monogram-detail-emplacement {}

.monogram-giftpackaged {
    margin-top: 12px;
    font-weight: bold;
    font-size: 12px;
}

.giftbox-label.visually-hidden {
    display: none !important;
}

// monogram on checkout: single/multi shipping
.checkout-monogram-info {
    font-size: 12px;
    color: $blue;
    line-height: 1.5;
    margin: 20px 0 0;
}

.singleshipping-monogram-info {
    margin: 20px 0 0;
}

.multishipping-monogram-info {
    margin: 0 0 20px;
}

.monogram-shipment {
    font: 12px / 14px $Arial;
    margin: 0 0 10px;
}

.cart-crisis {
    border: 2px solid $black;
    margin: 12px 0;
    padding: 16px;
    font: 14px $Arial;
    font-weight: bold;
    color: $blue;

    @include media('>=tablet') {
        font-size: 18px;
        margin: 0 auto 48px;
        width: 80%;
        padding: 16px 24px;
    }
}

.payment-methods-fields {
    max-width: 100%;
}

.billing-methods-bottom {
    margin-top: 20px;
}


.checkoutComponent .adyen-checkout__issuer-list {
    margin-top: 20px;
}

.checkoutComponent .adyen-checkout__dropdown__button {
    border: 1px solid $black;
    border-radius: 0;
    color: $black;
    font: 14px/14px Arial,sans-serif;
    height: 44px;
    padding: 0 30px 0 10px;
    text-decoration: none;
}

// Adyen issuer list (iDeal)
// will not work if put inside adyenCSS.css as the file is only loaded for the credit card form
.checkoutComponent .adyen-checkout__dropdown__button--active {
    border-radius: 0;
}

.checkoutComponent .adyen-checkout__dropdown__element__icon {
    max-width: 64px;
}

.checkoutComponent .adyen-checkout__dropdown__button__icon {
    max-width: 48px;
}

.checkoutComponent .adyen-checkout__dropdown__element {
    padding: 4px 10px;
    color: $black;
    font: 14px/14px Arial,sans-serif;
}

.checkoutComponent .adyen-checkout__dropdown__element + .adyen-checkout__dropdown__element {
    border-top: 1px solid $light-grey;
}

.checkoutComponent .adyen-checkout__dropdown__list {
    max-height: 280px;
    border: 1px solid $light-grey;
    border-top-width: 0;
    overflow-y: scroll;
}

.gift-box-message::placeholder {
    color: $grey;
}
